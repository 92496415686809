import { Component, ElementRef } from '@angular/core';

// Ionic Native Plugins
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { Observable, filter, finalize, forkJoin, merge } from 'rxjs';
import { environment } from '../environments/environment';
import { AppSettings } from './app.settings';
import { GetAlunoApiResponse } from './core/http/aluno/aluno-api.interface';
import { AppNotification } from './core/http/notification/notification.interface';
import { AlunoService } from './core/services/aluno/aluno.service';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { DialogService } from './core/services/dialog/dialog.service';
import { LocalNotificationsService } from './core/services/local-notifications/local-notifications.service';
import { MetadataQuery } from './core/services/metadata/metadata.query';
import { PushNotificationsService } from './core/services/push-notifications/push-notifications.service';
import { SettingsQuery } from './core/services/settings/settings.query';
import { StorageService } from './core/services/storage/storage.service';
import { AlunoQuery } from './modules/aluno/state/aluno.query';
import { AlunoStore } from './modules/aluno/state/aluno.store';
import { AuthQuery } from './modules/auth/state/auth.query';
import { AuthState, AuthStore } from './modules/auth/state/auth.store';
import { CardapiosStore } from './modules/cardapio/state/cardapio.store';
import { EventoService } from './modules/eventos/state/evento.service';
import { EventosStore } from './modules/eventos/state/eventos.store';
import { DisciplinaStore } from './modules/historicos/state/disciplina.store';
import { GradeStore } from './modules/historicos/state/grade.store';
import { MatrizStore } from './modules/historicos/state/matriz.store';
import { BasicSelectOption } from './shared/components/basic-select/basic-select.interface';
import { MetadataService } from './core/services/metadata/metadata.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  appPages = [{ title: 'Dashboard', url: '/app/dashboard', icon: 'today', ionicon: true }];

  opcoesCursos: BasicSelectOption[] = [];
  idMatrizSelecionada$: Observable<string | null> | undefined = undefined;
  aluno: GetAlunoApiResponse | undefined = undefined;

  networkListener: PluginListenerHandle | undefined = undefined;
  isConnected: boolean = false;

  private usuarioLogado = false;

  private allNotifications$: Observable<AppNotification> | undefined = undefined;

  constructor(
    private navController: NavController,
    private platform: Platform,
    private authQuery: AuthQuery,
    private menuController: MenuController,
    private router: Router,
    private storageService: StorageService,
    private authStore: AuthStore,
    private cardapioStore: CardapiosStore,
    private disciplinaStore: DisciplinaStore,
    private gradeStore: GradeStore,
    private eventoStore: EventosStore,
    private eventoService: EventoService,
    private metadataQuery: MetadataQuery,
    private alunoService: AlunoService,
    private alunoQuery: AlunoQuery,
    private alunoStore: AlunoStore,
    private analyticsService: AnalyticsService,
    private localNotificationService: LocalNotificationsService,
    private pushNotificationService: PushNotificationsService,
    private settingsQuery: SettingsQuery,
    private dialogService: DialogService,
    private matrizStore: MatrizStore,
    private el: ElementRef,
    private metadataService: MetadataService
  ) {
    this.initializeApp();

    if (environment.production && window) {
      window.console.log = () => {};
      window.console.info = () => {};
    }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      SplashScreen.hide();

      this.authQuery.auth$.subscribe({
        next: (authentication: AuthState) => {
          this.usuarioLogado = authentication.usuarioLogado;

          if (!this.usuarioLogado || this.hasOldAPIToken(authentication)) {
            if (authentication.auth) {
              this.menuController.enable(false);
              this.logoutUser();
              this.dialogService.mostrarErro(
                'Não conseguimos autenticar sua conta 😞 Tente fazer login novamente!'
              );
            }

            return;
          }

          this.localNotificationService.init();
          this.pushNotificationService.init();
          this.menuController.enable(true);

          this.alunoService.carregarDadosAluno().subscribe();
        },
      });

      this.alunoQuery.aluno$.pipe(filter((aluno) => !!aluno)).subscribe((aluno) => {
        this.aluno = aluno!;
      });

      this.alunoQuery.cursosAtivos$.subscribe((cursos) => {
        if (cursos) {
          this.opcoesCursos = [];
          this.opcoesCursos = cursos.map((curso) => {
            return { value: curso.matriz_id, text: `${curso.curso.nome} (${curso.codigo})` };
          });
        }
      });

      this.updateCampusData();

      this.idMatrizSelecionada$ = this.alunoQuery.idMatrizSelecionada$;

      this.metadataQuery.funcionalidadesCampus$.subscribe((func) => this.setFuncionalidades(func));

      this.settingsQuery.notificacoesAulas$.subscribe(() => {
        if (this.usuarioLogado) {
          this.localNotificationService.criarNotificacoes();
        }
      });

      this.settingsQuery.notificacoesEventos$.subscribe(() => {
        if (this.usuarioLogado) {
          this.localNotificationService.criarNotificacoes();
        }
      });

      this.analyticsService.init();

      App.addListener('appUrlOpen', (data) => {
        const slug = data.url.split('://').pop() ?? '';
        const handler = this.handlerForSlug(slug);
        handler(slug);
      });

      if (this.usuarioLogado) {
        this.allNotifications$ = merge(
          this.pushNotificationService.notificacoes$,
          this.localNotificationService.notificacoes$
        );

        this.allNotifications$.subscribe((notification) => {
          switch (notification.source) {
            case 'evento':
              if (notification.sourceIds) {
                this.eventoService.setarEventosComoNovos(notification.sourceIds);
              }

              if (notification.redirectRoute) {
                if (notification.trigger === 'foreground') {
                  // Se app estava aberto, mostra toast
                  this.dialogService.mostrarToastRedirect(notification.redirectRoute, notification.title);
                  break;
                }
                // Caso contrário, redireciona
                this.router.navigate(notification.redirectRoute);
              }
              break;
            default:
              break;
          }
        });
      }

      this.platform.backButton.subscribeWithPriority(-1, () => {
        this.navController.back();
      });

      this.isConnected = (await Network.getStatus()).connected;

      Network.addListener('networkStatusChange', (status) => {
        if (this.isConnected === status.connected) {
          return;
        }

        this.isConnected = status.connected;

        if (!this.isConnected) {
          this.dialogService.mostrarErro('Uma nuvem encobriu o arco-íris que trazia sua conexão 🌧️');
        } else {
          this.dialogService.mostrarSucesso('A nuvem passou e a sua conexão foi restaurada 🌈');
        }
      });
    });
  }

  toggleMenuClass() {
    const menu = this.el.nativeElement.querySelector('.menu-content');
    if (menu.classList.contains('menu-content-open')) {
      menu.classList.remove('menu-content-open');
    } else {
      menu.classList.add('menu-content-open');
    }
  }

  abrirConfiguracoes() {
    this.analyticsService.trackEvent('abrir-settings', 'app-component');
    this.menuController.close();
    this.router.navigate(['/app/configuracoes']);
  }

  selecionaCurso({ value }: { value: string }) {
    this.alunoService.isLoading.next(true);

    this.disciplinaStore.set([]);
    this.gradeStore.set([]);
    this.eventoStore.set([]);
    this.matrizStore.set([]);
    this.alunoStore.update({ idMatrizSelecionada: value });

    const eventos = this.eventoService.carregarEventosCurso();
    const historico = this.alunoService.loadHistoricoAndGrade();

    forkJoin([eventos, historico])
      .pipe(finalize(() => this.alunoService.isLoading.next(false)))
      .subscribe({
        error: () => {
          this.dialogService.mostrarErro('Algo deu errado');
        },
      });
  }

  logoutUser() {
    this.usuarioLogado = false;
    // TODO: Extract to service
    this.storageService.clearAll();
    this.authStore.reset();
    this.router.navigate(['/login']);

    this.disciplinaStore.reset();
    this.gradeStore.reset();
    this.eventoStore.reset();
    this.alunoStore.reset();
    this.cardapioStore.reset();
    this.matrizStore.reset();

    this.localNotificationService.removeNotificacoes();
  }

  setFuncionalidades(funcionalidades: any[]): void {
    const arr = AppSettings.FUNCIONALIDADES_LIBERADAS.filter((func) => {
      return funcionalidades.some((f) => f.handle === func.handle);
    });

    arr.unshift({
      handle: 'dashboard',
      title: 'Dashboard',
      url: '/app/dashboard',
      icon: 'today',
      ionicon: true,
    });
    this.appPages = arr;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlerForSlug(slug: string) {
    // if (slug.startsWith('instagram')) {
    //   return this.handleInstagramConnection;
    // }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (arg: string) => {};
  }

  // handleInstagramConnection = (slug: string) => {
  //   const authCode = slug.split('?code=').pop().split('#')[0];

  //   this.alunoService
  //     .conectarInstagram(authCode)
  //     .pipe(
  //       tap(() => {
  //         this.alunoService.carregarDadosAluno().subscribe(() => {
  //           this.analyticsService.trackEvent('integracao-instagram', 'eventos');
  //           this.dialogService.mostrarSucesso('Integração com o Instagram completa!');
  //         });
  //       }),
  //       catchError((err) => {
  //         this.analyticsService.trackEvent(`API Error: ${err.error}`, 'Eventos');
  //         const errMsg = err.mensagem ? err.mensagem : 'Falha ao realizar integração. Verifique a sua conexão';
  //         this.dialogService.mostrarErro(errMsg);
  //         throw err;
  //       })
  //     )
  //     .subscribe();
  // };

  /**
   * If user has a token from the old API (https://api.aluno.app)
   * Check https://app.asana.com/0/1202382239980324/1203905482244767 for more context
   *
   * It may be a good idea to encapsualte this in a service if more compatibility functions
   * will be needed.
   *
   * @param authentication
   * @returns
   */
  private hasOldAPIToken(authentication: AuthState): boolean {
    const deprecatedAuthStructure = <any>authentication.auth;
    const userHasDeprecatedAuthStore = deprecatedAuthStructure?.token_type !== undefined;

    return userHasDeprecatedAuthStore;
  }

  // Ensure funcionalidades will be updated on app launch
  updateCampusData() {
    const campusId = this.aluno?.campus.campus_id;
    if (!campusId) return;
    this.metadataService.carregarCampus(campusId).subscribe();
  }
}
