import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PostAuthInstituicaoBody, PostAuthInstituicaoResponse } from '../../../core/http/auth/auth-api.interface';
import { AuthApiService } from '../../../core/http/auth/auth-api.service';
import { StorageService } from '../../../core/services/storage/storage.service';
import { AuthStore } from '../../../modules/auth/state/auth.store';
import { AuthQuery } from './auth.query';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private credentials: PostAuthInstituicaoResponse | undefined = undefined;
  constructor(
    private authApi: AuthApiService,
    private authStore: AuthStore,
    private storage: StorageService,
    private authQuery: AuthQuery
  ) {}

  authenticate(credentials: PostAuthInstituicaoBody) {
    return this.authApi.postLogin(credentials).pipe(
      tap((auth) => {
        this.credentials = auth;
        this.authQuery.setTemporaryAuth(this.credentials);
      })
    );
  }

  removeAuthentication() {
    this.credentials = undefined;
    this.saveAuthentication();
    this.storage.clearItem('auth');
  }

  saveAuthentication() {
    if (!this.credentials) return;
    this.authStore.update({ auth: this.credentials, usuarioLogado: !!this.credentials });
    this.authQuery.setTemporaryAuth(this.credentials);
  }
}
