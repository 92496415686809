import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isAfter, isToday, parseISO, startOfDay } from 'date-fns';
import { GradeHorariaAula } from '../../../core/services/aluno/aluno.interface';
import { ExtendedEvento } from '../../../core/http/evento/evento-api.interface';
import { Professore } from '../../../core/http/aluno/aluno-api.interface';

@Component({
  selector: 'app-disciplina-card',
  templateUrl: './disciplina-card.component.html',
  styleUrls: ['./disciplina-card.component.scss'],
})
export class DisciplinaCardComponent implements OnInit {
  @Input() disciplina!: GradeHorariaAula;
  @Input() noBorder = false;
  @Input() noPadding = false;
  @Input() showProfessor = true;
  @Input() variant: 'grade-horaria-grid' | 'dashboard' = 'dashboard';
  @Input() hora_inicio!: string;
  @Input() hora_termino!: string;
  @Input() dia_da_semana!: number;

  @Output() open = new EventEmitter();
  @Output() openEvent = new EventEmitter<ExtendedEvento>();

  readonly eventos_threshold = 5;
  faltas = 0;
  maximo_faltas = 0;
  progress = 0;
  days: string[] = [];
  months: string[] = [];
  eventos: ExtendedEvento[] = [];
  professores: Professore[] = [];

  weekDays = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
  get remainingMessage() {
    if (!this.eventos || this.eventos.length <= this.eventos_threshold) {
      return '';
    }

    const remainigEventos = this.eventos.length - this.eventos_threshold;
    if (remainigEventos > 1) {
      return `${remainigEventos} outros eventos nessa disciplina`;
    }

    return 'Outro evento nessa disciplina';
  }

  private readonly monthsAbr = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ',
  ];

  constructor(private router: Router) {}

  ngOnInit() {
    this.eventos = this.parseEventos();
    this.professores = this.disciplina.turma.professores ?? [];
    this.setFaltas();
  }

  emitOpen() {
    this.open.emit(this.disciplina);
  }

  emitOpenEvent(ev: ExtendedEvento) {
    this.openEvent.emit(ev);
  }

  setFaltas() {
    if ((this.disciplina.faltas ?? 0) > (this.disciplina.faltas_manuais ?? 0)) {
      this.faltas = this.disciplina.faltas ?? 0;
    } else {
      this.faltas = this.disciplina.faltas_manuais || 0;
    }

    this.maximo_faltas = this.disciplina.maximo_faltas_manuais || 0;
    if (this.maximo_faltas) {
      this.progress = this.maximo_faltas > 0 ? this.faltas / this.maximo_faltas : 0;
    }
  }

  getDay(eventoDate: string) {
    const date = new Date(eventoDate);
    return date?.getDate();
  }

  getMonth(eventoDate: string) {
    const date = new Date(eventoDate).getMonth();
    return date ? this.monthsAbr[date] : '';
  }

  redirectToEvents() {
    this.router.navigate(['/app/eventos']);
  }

  /**
   * Parse the events that will be displayed in the card, according to the variant.
   * This functions uses an auxiliary arrow function that decides if the event will
   * be included in the list or not
   * @returns ExtendedEvento[]: a list of eventos
   */
  private parseEventos(): ExtendedEvento[] {
    if (!this.disciplina?.turma?.eventos) {
      return [];
    }

    const dayStart = startOfDay(new Date());
    let filterFn: (evento: ExtendedEvento) => boolean;
    if (this.variant == 'dashboard') {
      filterFn = (evento) => isToday(parseISO(evento.inicio)) && isAfter(new Date(evento.inicio), dayStart);
    } else {
      filterFn = (evento) => isAfter(new Date(evento.inicio), dayStart);
    }

    const eventos = this.disciplina.turma.eventos.filter(filterFn);
    eventos.sort((a, b) => {
      const dataA = new Date(a.inicio);
      const dataB = new Date(b.inicio);

      return dataA.getTime() - dataB.getTime();
    });

    return eventos;
  }
}
