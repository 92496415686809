import { AppSettings } from './../../../app.settings';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExtendedEvento } from '../../../core/http/evento/evento-api.interface';

@Component({
  selector: 'app-list-eventos',
  templateUrl: './list-eventos.component.html',
  styleUrls: ['./list-eventos.component.scss'],
})
export class ListEventosComponent implements OnInit {
  @Input() eventos: ExtendedEvento[] = [];
  @Input() collapsible = false;
  @Input() label!: string;
  @Input() mostrarConfirmacoes: boolean = false;

  @Output() action = new EventEmitter<ExtendedEvento>();

  public collapsed = true;

  ngOnInit() {}

  public getEventoColor(evento: ExtendedEvento) {
    return AppSettings.CORES_EVENTOS[evento.categoria.grupo.nome];
  }

  public emitAction(ev: ExtendedEvento) {
    this.action.emit(ev);
  }

  public toggleCollapse() {
    if (this.collapsible) {
      this.collapsed = !this.collapsed;
    }
  }
}
