import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { SettingsQuery } from '../../../core/services/settings/settings.query';
import { AlunoState, AlunoStore } from './aluno.store';

@Injectable({ providedIn: 'root' })
export class AlunoQuery extends QueryEntity<AlunoState, {}> {
  aluno$ = this.select((state) => state.aluno);
  cursos$ = this.select((state) => state.cursos);
  cursosAtivos$ = this.select((state) => state.cursos.filter((c) => c.app_ativo));

  idMatrizSelecionada$ = this.select((state) => state.idMatrizSelecionada);

  cursoAtual$ = this.select((state) => {
    const idAtual = this.getValue().idMatrizSelecionada;

    let cursoSelecionado = state.cursos.find((curso) => {
      return curso.matriz_id === idAtual;
    });

    if (cursoSelecionado === undefined && state.cursos.length > 0) {
      cursoSelecionado = state.cursos[0];
    }

    return cursoSelecionado;
  });

  constructor(protected store: AlunoStore, private settingsQuery: SettingsQuery) {
    super(store);
  }

  aluno() {
    return this.getValue().aluno;
  }

  idMatrizSelecionada() {
    return this.getValue().idMatrizSelecionada;
  }

  userHasReviewed() {
    return this.getValue().userActivity.userHasReviewed;
  }

  checkReachedLoginPeriodForReview() {
    return this.getValue().userActivity.reachedLoginPeriodForReview;
  }

  firstAccessTime() {
    return this.getValue().userActivity.firstAccessTime;
  }
}
