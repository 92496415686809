import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AvatarPipe } from '../../../app/shared/pipes/avatar.pipe';
import { SharedModule } from '../../shared/shared.module';
import { CarteirinhaRoutingModule } from './carteirinha-routing.module';

import { CarteirinhaPage } from './pages/carteirinha/carteirinha.page';
import { ValidadePage } from './pages/validade/validade.page';

@NgModule({
  imports: [CommonModule, CarteirinhaRoutingModule, SharedModule, IonicModule],
  providers: [AvatarPipe],
  declarations: [CarteirinhaPage, ValidadePage],
  exports: [],
})
export class CarteirinhaModule {}
