<div class="c-field" [class.c-field--disabled]="disabled">
  <h4 *ngIf="label" class="c-field__label">
    <span>{{ label }}</span>
    <span *ngIf="opcional" class="u-margin-left--xs u-gray--400 u-font-weight--regular u-font-size--xs"
      >(opcional)</span
    >
  </h4>
  <div class="c-field__border u-display--flex u-flex-align--center" [class.c-field__border--focused]="focused">
    <ion-icon *ngIf="icon" [name]="icon" class="c-field__left-icon"></ion-icon>

    <ion-input
      #ionInput
      position="fixed"
      class="c-field__input"
      clearOnEdit="false"
      [inputmode]="type"
      [placeholder]="placeholder"
      [type]="internalType"
      [disabled]="disabled"
      [value]="inputValue"
      (ionChange)="emitInput($event)"
      (ionFocus)="onFocus()"
      (ionBlur)="onBlur()"
      (keyup.enter)="emitSubmit()"></ion-input>

    <ion-icon
      class="c-field__right-icon"
      *ngIf="type === 'password'"
      [name]="internalType === 'password' ? 'eye' : 'eye-off'"
      (click)="togglePassword()">
    </ion-icon>
  </div>
</div>
