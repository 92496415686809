<ion-header class="ion-no-border">
  <ion-toolbar mode="ios" class="c-toolbar c-toolbar--shadowless c-toolbar--transparent">
    <ion-buttons slot="start">
      <ion-menu-button mode="ios"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content
  fullscreen="true"
  (touchstart)="mouseDown($event)"
  (touchend)="mouseUp($event)"
  [class.e-dashboard-page--open]="headerOpen">
  <!-- header  -->
  <section
    class="u-display--flex u-flex-align--center u-flex-direction--column e-dashboard-page__header"
    [class.e-dashboard-page__header--open]="headerOpen">
    <div
      class="u-display--flex u-flex-align--center u-flex-direction--column u-margin-top--xs"
      (click)=" toggleHeader()">
      <div class="u-display--flex u-flex-align--center u-flex-justify--center">
        <img src="assets/img/logo-white.svg" class="u-margin-right--xs" />
        <h2 class="u-font-size--xl u-font-weight--regular u-white">{{nomeAluno}}</h2>
      </div>
      <ion-icon
        name="chevron-down"
        class="e-dashboard-page__header-icon u-margin-top--xxs"
        [class.e-dashboard-page__header-icon--rotated]="headerOpen"></ion-icon>
    </div>

    <div #menu class="e-dashboard-page__menu" [class.e-dashboard-page__menu--expanded]="headerOpen">
      <h5 class="u-text-align--center u-font-weight--regular u-white">Matrícula <b>{{matriculaAluno}}</b></h5>
      <h5
        *ngIf="(cursoAluno$ | async) as cursoAluno"
        class="u-text-align--center u-font-weight--regular u-white u-margin-top--sm u-margin-bottom">
        Curso <b>{{cursoAluno?.curso?.nome}}</b>
      </h5>
      <ul class="c-list c-list--reverse c-list--border-xs e-dashboard-page__menu-list">
        <li class="c-list__item u-display--flex u-flex-align--center" (click)="abrirPerfil()">
          <ion-icon name="person" class="c-icon c-icon--large u-white u-margin-right--sm"> </ion-icon>
          <div class="u-display--flex u-flex-align--center u-flex-justify--between u-flex-grow">
            <h4 class="u-font-size--regular u-white">Perfil</h4>
            <ion-icon name="chevron-forward" class="c-icon c-icon--arrow u-white"></ion-icon>
          </div>
        </li>
        <li class="c-list__item u-display--flex u-flex-align--center" (click)="abrirCarteirinha()">
          <ion-icon
            src="assets/img/card_icon.svg"
            class="c-icon c-icon--large u-white u-margin-right--sm"></ion-icon>
          <div class="u-display--flex u-flex-align--center u-flex-justify--between u-flex-grow">
            <h4 class="u-font-size--regular u-white">Carteira de Estudante</h4>
            <ion-icon name="chevron-forward" class="c-icon c-icon--arrow u-white"></ion-icon>
          </div>
        </li>
        <li class="c-list__item u-display--flex u-flex-align--center" (click)="abrirConfiguracoes()">
          <ion-icon name="cog" class="c-icon c-icon--large u-white u-margin-right--sm"> </ion-icon>
          <div class="u-display--flex u-flex-align--center u-flex-justify--between u-flex-grow">
            <h4 class="u-font-size--regular u-white">Configurações</h4>
            <ion-icon name="chevron-forward" class="c-icon c-icon--arrow u-white"></ion-icon>
          </div>
        </li>
        <li
          data-testid="dashboard-logout-button"
          class="c-list__item u-display--flex u-flex-align--center u-padding-bottom"
          (click)="logout()">
          <ion-icon name="log-out-outline" class="c-icon c-icon--large u-white u-margin-right--sm"> </ion-icon>
          <div class="u-display--flex u-flex-align--center u-flex-justify--between u-flex-grow">
            <h4 class="u-font-size--regular u-white">Sair</h4>
            <ion-icon name="chevron-forward" class="c-icon c-icon--arrow u-white"></ion-icon>
          </div>
        </li>
      </ul>
    </div>
  </section>

  <div
    class="l-container e-dashboard-page"
    #container
    [class.e-dashboard-page--open]="headerOpen"
    (click)="collapseHeader()">
    <div
      class="e-dashboard-page__mask"
      [class.e-dashboard-page__mask--open]="headerOpen"
      (click)="toggleHeader($event)"></div>
    <div class="e-dashboard-page__content">
      <!-- today -->
      <section
        class="e-dashboard-page__today"
        [class.e-dashboard-page__section]="(aulasHoje$ | async)!.length === 0">
        <div class="c-card">
          <h3 class="c-card__title" [class.e-dashboard-page__empty-border]="(aulasHoje$ | async)!.length === 0">
            Hoje, {{diaHoje}}
          </h3>

          <!-- if no classes -->
          <div *ngIf="(aulasHoje$ | async)!.length === 0" class="u-text-align--center">
            <div *ngIf="!userHasReviewed else empty">
              <img src="assets/img/illustrations/store-rating.svg" />
              <div>
                <h4 class="u-font-size--lg u-margin-top--xxs">Curtindo o Uniapp?</h4>
                <h4 class="u-font-weight--regular u-gray--600 u-margin-top u-margin-bottom--md">
                  Nos dê um 10 na prova deixando sua avaliação na Playstore! É rapidinho!
                </h4>
                <app-button label="AVALIAR" type="reverse" [main]="true" (click)="abrirAvaliacaoLoja()">
                </app-button>
              </div>
            </div>

            <ng-template #empty>
              <div class="u-display--flex u-flex-align--center u-flex-direction--column u-margin-top--md">
                <img src="assets/img/illustrations/sleeping.svg" class="e-dashboard-page__chilling" />
                <h4 class="u-font-weight--regular u-text-align--center u-gray--600 u-width--66 u-margin-top">
                  Zzzz.z.z.z….
                </h4>
              </div>
            </ng-template>
          </div>
        </div>
      </section>

      <!-- classes  -->
      <section
        class="e-dashboard-page__section u-padding-right u-padding-left"
        *ngIf="(aulasHoje$ | async)!.length > 0">
        <div *ngFor="let aula of (aulasHoje$ | async)" class="u-margin-top--xs">
          <app-disciplina-card
            variant="dashboard"
            [showProfessor]="false"
            [disciplina]="aula"
            (open)="abrirDisciplina($event)"
            (openEvent)="abrirEvento($event)">
          </app-disciplina-card>
        </div>

        <div class="u-margin-top--xs">
          <app-review-card *ngIf="shouldDisplayReviewCard" variation="dashboard"></app-review-card>
        </div>
      </section>

      <!-- meals -->
      <section
        class="e-dashboard-page__section"
        *ngIf="(cardapiosHoje$ | async)!.length > 0 && hasRestaurantesFuncionalidade">
        <swiper
          [config]="slideOptions"
          (swiper)="setSwiperInstance($event)"
          (slideChangeTransitionStart)="changeCardapio()">
          <ng-template swiperSlide *ngFor="let cardapio of (cardapiosHoje$ | async)">
            <div class="e-dashboard-page__slide">
              <app-card-cardapio-dash
                [cardapio]="cardapio"
                [nomeRestaurante]="nomeRestaurante"
                [aluno]="aluno"
                [mostrarVeggies]="(mostrarVeggies$ | async)!"
                (cardClick)="abrirCardapio(cardapio)">
              </app-card-cardapio-dash>
            </div>
          </ng-template>
        </swiper>
      </section>

      <section
        class="e-dashboard-page__section"
        *ngIf="(cardapiosHoje$ | async)?.length === 0 && hasRestaurantesFuncionalidade">
        <div class="e-dashboard-page__slide u-margin-left">
          <app-card-cardapio-empty-dash
            [weekEmpty]="(cardapiosSemana$ | async)?.length === 0"></app-card-cardapio-empty-dash>
        </div>
      </section>

      <!-- events -->
      <section class="e-dashboard-page__section u-padding-right u-padding-left" *ngIf="eventosHoje.length > 0">
        <app-list-eventos
          label="Eventos"
          [eventos]="eventosHoje"
          (action)="abrirEvento($event)"></app-list-eventos>
      </section>
    </div>
  </div>
</ion-content>
