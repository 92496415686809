import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { PlatformService } from '../platform/platform.service';

@Injectable({
  providedIn: 'root',
})
export class StatusBarService {
  constructor(private platformService: PlatformService) {}

  public setPink() {
    if (this.platformService.isWeb()) {
      return;
    }

    StatusBar.setStyle({ style: Style.Dark });
    this.setBackgroundColor('#ff226d');
  }

  public setGray() {
    if (this.platformService.isWeb()) {
      return;
    }

    StatusBar.setStyle({ style: Style.Light });
    this.setBackgroundColor('#f5f5f5');
  }

  public setWhite() {
    if (this.platformService.isWeb()) {
      return;
    }

    StatusBar.setStyle({ style: Style.Light });
    this.setBackgroundColor('#ffffff');
  }

  /**
   * Sets the background color for the status bar. Currently, only android
   * platforms implement this.
   * @param color the hex code background color for the status bar
   */
  private setBackgroundColor(color: string): void {
    if (!this.platformService.isAndroid()) {
      return;
    }

    StatusBar.setBackgroundColor({ color });
  }
}
