<div *ngIf="visible" [@slideInOut]="visible ? 'in' : 'void'" class="e-review-card">
  <div class="u-display--flex u-flex-align--center u-flex-justify--between">
    <div class="u-display--flex u-flex-align--start u-flex-direction--column e-review-card__text">
      <h4 class="c-card__title">{{ title }}</h4>
      <h4
        class="u-font-weight--regular u-font-size--sm u-text-align--left u-gray--500 u-margin-top--xs u-margin-bottom">{{ description }}
      </h4>
      <div class="e-review-card__button-container">
        <app-button label="AVALIAR" type="reverse" [main]="true" (click)="abrirAvaliacaoLoja()"> </app-button>
      </div>
    </div>
    <img src="assets/img/illustrations/store-rating.svg" />
  </div>
</div>