import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-send-cardapio-modal',
  templateUrl: './send-cardapio-modal.component.html',
  styleUrls: ['./send-cardapio-modal.component.scss'],
})
export class SendCardapioModalComponent implements OnInit {
  public cardapioEmail = 'cardapio@aluno.app';
  public emailCopied = false;

  constructor(private modalController: ModalController, private analyticsService: AnalyticsService) {}

  ngOnInit() {}

  enviarEmail() {
    const link = document.createElement('a');
    link.href = `mailto:${this.cardapioEmail}?subject=Uniapp - Cardápio do RU&body=Não esqueça de dizer qual a universidade e o campus!`;
    link.click();
    link.remove();
    this.analyticsService.trackEvent('enviar-cardapio-email', 'cardapio');
    this.modalController.dismiss();
  }

  copyEmail() {
    Clipboard.write({
      string: this.cardapioEmail,
    }).then(() => {
      this.emailCopied = true;
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
