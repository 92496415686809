import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { CampusDetailed } from '../../http/metadata/metadata-api.interface';
import { MetadataState, MetadataStore } from './metadata.store';

@Injectable({ providedIn: 'root' })
export class MetadataQuery extends Query<MetadataState> {
  funcionalidadesCampus$ = this.select((state) => state.campus?.funcionalidades ?? []);

  constructor(protected store: MetadataStore) {
    super(store);
  }

  campus(): CampusDetailed | null {
    return this.getValue().campus;
  }

  funcionalidadesCampus(): CampusDetailed['funcionalidades'] {
    return this.getValue().campus?.funcionalidades ?? [];
  }

  isFuncionalidadeActive$(handle: string): Observable<boolean> {
    return this.select((state) => this.searchFuncionalidade(handle, state.campus?.funcionalidades ?? []));
  }

  isFuncionalidadeActive(handle: string): boolean {
    const funcionalidades = this.funcionalidadesCampus();
    return this.searchFuncionalidade(handle, funcionalidades);
  }

  private searchFuncionalidade(handle: string, funcionalidades: CampusDetailed['funcionalidades']) {
    const funcionalidade = funcionalidades?.find((f) => f.handle === handle);
    return !!funcionalidade;
  }
}
