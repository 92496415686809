import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EventoReportCategoria, BasicEvento } from '../../../core/http/evento/evento-api.interface';
import { EventoCategoria } from '../../../core/http/metadata/metadata-api.interface';

export interface EventosState extends EntityState<BasicEvento> {
  categorias: EventoCategoria[];
  idCategoriasExcluidas: EventoCategoria['categoria_id'][];
  idEventosNaoNotificaveis: BasicEvento['evento_id'][];
  mostrarDesconfirmados: boolean;
  mostrarOcultados: boolean;
  reportCategorias: EventoReportCategoria[];
  mostrarOnboarding: boolean;
}
export function createInitialState(): EventosState {
  return {
    categorias: [],
    idCategoriasExcluidas: [],
    idEventosNaoNotificaveis: [],
    mostrarDesconfirmados: false,
    mostrarOcultados: false,
    reportCategorias: [],
    mostrarOnboarding: true,
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'eventos', idKey: 'evento_id', resettable: true })
export class EventosStore extends EntityStore<EventosState, BasicEvento> {
  constructor() {
    super(createInitialState());
  }
}
