<ion-button
  class="c-button"
  fill="clear"
  [disabled]="disabled || loading"
  [class.c-button--link]="type == 'link'"
  [class.c-button--clear]="type == 'clear'"
  [class.c-button--main]="main"
  [class.c-button--reverse]="type == 'reverse'"
  [class.c-button--faded]="faded"
  [class.c-button--reverse]="type == 'reverse'"
  [class.c-button--white]="type === 'white'"
  (click)="emitOnClick($event)"
  #ionButton>
  <ion-icon
    *ngIf="icon"
    class="c-icon c-icon--large u-margin-right--xs"
    [name]="icon"
    [class.u-white]="type == 'reverse'"></ion-icon>
  <span *ngIf="!loading">{{ label }}</span>
  <ion-spinner
    *ngIf="loading"
    name="dots"
    class="c-button__spinner"
    [class.c-button__spinner--white]="type === 'reverse'"></ion-spinner>
</ion-button>
