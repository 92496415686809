<div
  class="c-modal u-padding-top--none u-padding-bottom--xs u-padding-right--none u-padding-left--none"
  [class.c-modal--square]="style === 'page'">
  <ion-icon class="c-modal__close" name="close" (click)="dismiss()"></ion-icon>
  <div class="c-slides" [class.c-slides--page]="style === 'page'" [class.c-slides--modal]="style === 'modal'">
    <swiper
      #contentSlides
      [config]="slideOptions"
      [pagination]="true"
      class="c-slides__wrapper"
      (slideChangeTransitionStart)="slideWillChange()">
      <ng-template *ngFor="let slide of slides" swiperSlide>
        <div class="c-slides__slide">
          <div class="c-slides__image"><img [src]="slide.image" /></div>
          <div class="c-slides__title">{{ slide.title }}</div>
          <div class="c-slides__description">{{ slide.description }}</div>
        </div>
      </ng-template>
    </swiper>
  </div>
  <div class="c-slides__footer">
    <app-button type="reverse" [main]="true" [label]="button" (action)="emitNext()"> </app-button>
    <app-button
      data-testid="onboarding-skip-button"
      type="clear"
      label="PULAR"
      [class.c-slides__btn-hide]="!showSkip"
      (action)="dismiss()"></app-button>
  </div>
</div>
