<div class="c-card e-card-cardapio-empty-dash">
  <div class="e-card-cardapio-empty-dash__circle-wrapper e-card-cardapio-empty-dash__circle-wrapper--top">
    <ng-container *ngFor="let i of borderArray">
      <div class="e-card-cardapio-empty-dash__circle-border e-card-cardapio-empty-dash__circle-border--top"></div>
    </ng-container>
  </div>

  <div class="e-card-cardapio-empty-dash__top">
    <h3 class="c-card__title e-card-cardapio-empty-dash__title">{{ title }}</h3>
    <h4 class="e-card-cardapio-empty-dash__subtitle">Você sabe qual é? Manda pra gente? Você será nosso herói!</h4>
  </div>
  <div class="e-card-cardapio-empty-dash__bottom">
    <ng-container>
      <app-button
        type="clear"
        label="Enviar Cardápio"
        [main]="true"
        (action)="openSendCardapioModal()"></app-button>
    </ng-container>
  </div>

  <div class="e-card-cardapio-empty-dash__circle-wrapper e-card-cardapio-empty-dash__circle-wrapper--bottom">
    <ng-container *ngFor="let i of borderArray">
      <div
        class="e-card-cardapio-empty-dash__circle-border e-card-cardapio-empty-dash__circle-border--bottom"></div>
    </ng-container>
  </div>
</div>
