import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PutAlunoDeviceBody } from '../../services/aluno/aluno.interface';
import {
  AuthCodeInstagram,
  GetAlunoApiResponse,
  GetAlunoMatrizApiResponse,
  HistoricoItem,
  InstagramAccessToken,
  PerfilInstagram,
  SituacaoDisciplinaMatriz,
  Turma,
  GradeHorariaTurno,
} from './aluno-api.interface';

@Injectable({
  providedIn: 'root',
})
export class AlunoApiService {
  constructor(private http: HttpClient) {}

  getAluno() {
    return this.http.get<GetAlunoApiResponse>(`${environment.api}/aluno`);
  }

  getAlunoCursos() {
    return this.http.get<GetAlunoMatrizApiResponse[]>(`${environment.api}/aluno/matrizes`);
  }

  getAlunoCursoHistoricoCompleto(matrizId: GetAlunoMatrizApiResponse['matriz_id']) {
    return this.http.get<HistoricoItem[]>(`${environment.api}/aluno/matrizes/${matrizId}/historicos`);
  }

  getAlunoCursoHistoricoAtual(matrizId: GetAlunoMatrizApiResponse['matriz_id']) {
    return this.http.get<HistoricoItem[]>(`${environment.api}/aluno/matrizes/${matrizId}/historicos/atuais`);
  }

  getAlunoCursoMatrizSituacoes(matrizId: GetAlunoMatrizApiResponse['matriz_id']) {
    return this.http.get<SituacaoDisciplinaMatriz[]>(`${environment.api}/aluno/matrizes/${matrizId}/progresso`);
  }

  getAlunosTurma(turmaId: Turma['turma_id']) {
    return this.http.get<GetAlunoApiResponse[]>(`${environment.api}/aluno/turmas/${turmaId}/alunos`);
  }

  putAlunoDevice(device_id: string, device: PutAlunoDeviceBody) {
    return this.http.put<GetAlunoApiResponse>(`${environment.api}/aluno/devices/${device_id}`, device);
  }

  postAlunoFoto(imagem: File) {
    const headers = new HttpHeaders({
      SKIP_TYPE: '',
    });

    const formData = new FormData();
    formData.set('foto_perfil', imagem);
    formData.set('usar_foto_instagram', 'false');

    return this.http.post(`${environment.api}/aluno/foto-perfil`, formData, { headers });
  }

  postAlunoAuthCodeInstagram(body: AuthCodeInstagram) {
    return this.http.post<InstagramAccessToken>(`${environment.api}/aluno/perfil/instagram/access_token`, body);
  }

  postAlunoPerfilInstagram(body: PerfilInstagram) {
    return this.http.post<{}>(`${environment.api}/aluno/perfil/instagram`, body);
  }

  deleteAlunoPerfilInstagram() {
    return this.http.delete<{}>(`${environment.api}/aluno/perfil/instagram`);
  }

  patchHistorico(historico: HistoricoItem) {
    return this.http.patch<[]>(`${environment.api}/aluno/historicos/${historico.historico_id}`, historico);
  }

  getAlunoGradeHoraria(matrizId: GetAlunoMatrizApiResponse['matriz_id']): Observable<GradeHorariaTurno[]> {
    return this.http.get<GradeHorariaTurno[]>(`${environment.api}/aluno/matrizes/${matrizId}/grade-horaria`);
  }
}
