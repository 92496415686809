import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { AuthQuery } from '../../modules/auth/state/auth.query';

import { AuthStore } from '../../modules/auth/state/auth.store';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private timeoutTime = 45000;

  constructor(
    @Inject(AuthQuery) private authQuery: AuthQuery,
    @Inject(AuthStore) private authStore: AuthStore,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // https://github.com/angular/angular/issues/18224
    if (request.headers.has('SKIP_API_INTERCEPTOR')) {
      return next.handle(request.clone({ headers: request.headers.delete('SKIP_API_INTERCEPTOR') }));
    }

    let headers = request.headers;
    if (request.headers.has('SKIP_TYPE')) {
      headers = headers.delete('SKIP_TYPE');
    } else {
      headers = headers.set('Content-Type', 'application/json');
    }

    const auth =
      this.authQuery && this.authQuery.getValue().auth
        ? this.authQuery.getValue().auth
        : this.authQuery.temporaryAuth;

    if (auth) {
      headers = headers.set('Authorization', `Bearer ${auth.token}`);
    }

    const intercepted = request.clone({ headers });

    return <any>next.handle(intercepted).pipe(
      timeout(this.timeoutTime),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Returns the error message for an HTTP Error
   * In case the error is an Authentication Error we reset the auth store.
   *
   * @param {HttpErrorResponse} err
   */
  private handleError(error: HttpErrorResponse) {
    let errorMessage: string;

    if (error.error?.message) {
      errorMessage = error.error.message;
    }

    // Credenciais invalidas
    if (error.status === 401) {
      this.authStore.reset();
      this.router.navigateByUrl('/');
    }

    if (error?.status === 0 && !window.navigator.onLine) {
      errorMessage = 'Ainda estamos procurando os duendes rebeldes que levaram sua rede';
    }

    return throwError(() => ({ mensagem: errorMessage, original: error }));
  }
}
