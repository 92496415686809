<div class="c-card e-list-eventos">
  <div class="u-display--flex u-flex-align--center" (click)="toggleCollapse()">
    <ion-icon
      *ngIf="collapsible"
      name="chevron-forward-outline"
      class="c-icon c-icon--large u-black e-list-eventos__arrow"
      [class.e-list-eventos__arrow--rotated]="!collapsed"></ion-icon>
    <h2 class="c-card__title">{{ label }}</h2>
  </div>
  <ul
    class="c-list c-list--border-sm e-list-eventos__list"
    [class.u-margin-top]="label.length > 0 && (!collapsible || !collapsed)"
    [class.e-list-eventos__list--collapsed]="collapsible && collapsed">
    <li class="c-list__item" *ngFor="let evento of eventos">
      <app-evento-preview
        [evento]="evento"
        [color]="getEventoColor(evento)"
        [mostrarConfirmacoes]="mostrarConfirmacoes"
        (click)="emitAction(evento)"></app-evento-preview>
    </li>
  </ul>
</div>
