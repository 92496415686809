import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() label: string | undefined = undefined;
  @Input() icon: string | undefined = undefined;
  @Input() type: 'primary' | 'link' | 'clear' | 'reverse' | 'white' = 'primary';
  @Input() disabled = false;
  @Input() faded = false;
  @Input() main = false;
  @Input() width: string | undefined = undefined;

  @Input() loading = false;

  @Output() action = new EventEmitter();

  ngOnInit() {}

  emitOnClick(ev: Event) {
    this.action.emit(ev);
  }
}
