import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { GetAlunoApiResponse, GetAlunoMatrizApiResponse } from '../../../core/http/aluno/aluno-api.interface';
import { UserActivityState } from '../../../core/services/aluno/aluno.interface';
import { startOfDay } from 'date-fns';

export interface AlunoState extends EntityState<{}> {
  aluno: GetAlunoApiResponse | null;
  cursos: GetAlunoMatrizApiResponse[];
  idMatrizSelecionada: GetAlunoMatrizApiResponse['matriz_id'] | null;
  userActivity: UserActivityState;
}

export function createInitialState(): AlunoState {
  return {
    aluno: null,
    cursos: [],
    idMatrizSelecionada: null,
    userActivity: {
      userHasReviewed: false,
      firstAccessTime: startOfDay(new Date()),
      reachedLoginPeriodForReview: false,
    },
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'aluno', resettable: true })
export class AlunoStore extends EntityStore<AlunoState, {}> {
  constructor() {
    super(createInitialState());
  }
}
