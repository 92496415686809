export const environment = {
  production: true,
  api: 'https://api.aluno.app',
  rollbar: {
    accessToken: '232510c5a30b4d16b4de327d7b9d6bd2',
    environment: 'production',
    enabled: true,
  },
  instagram: {
    base_url: 'https://www.instagram.com',
    graph_url: 'https://graph.instagram.com/v14.0',
    oauth_url:
      // tslint:disable-next-line
      'https://api.instagram.com/oauth/authorize?client_id=511167119794182&redirect_uri=https%3A%2F%2Fapi.aluno.app%2Fauth%2Finstagram&scope=user_profile&response_type=code',
  },
  loginPeriodForReview: 5,
};
