<div class="c-card e-card-disciplina" *ngIf="disciplina" [ngClass]="'e-card-disciplina--' + disciplina.cor">
  <!-- top row -->
  <section class="u-display--flex u-flex-align--center u-flex-justify--between u-margin-bottom--xxs">
    <h5 *ngIf=" variant !== 'grade-horaria-grid' " class="e-card-disciplina__horario">
      {{ disciplina.horario.hora_inicio }} - {{ disciplina.horario.hora_termino }}
    </h5>
    <h5 *ngIf=" variant === 'grade-horaria-grid'" class="e-card-disciplina__horario--grade-horaria-grid">
      {{ weekDays[dia_da_semana - 1] }}, {{ hora_inicio }} - {{ hora_termino }}
    </h5>
    <div class="e-card-disciplina__sala-block" *ngIf="disciplina.horario.sala">
      <h5 class="e-card-disciplina__sala">{{ disciplina.horario.sala }}</h5>
    </div>
  </section>

  <!-- main section of card -->
  <section (click)="emitOpen()">
    <div class="u-display--flex u-flex-align--center u-margin-bottom--xs">
      <h2>{{ disciplina.turma.disciplina.nome }}</h2>
      <ion-icon *ngIf=" variant !== 'grade-horaria-grid' " class="c-icon c-icon--arrow u-margin-left--xs" icon="chevron-forward"></ion-icon>
    </div>

    <!-- professors -->
    <div *ngIf="showProfessor" [class.e-card-disciplina__professor-block]="professores.length > 1">
      <h5
        *ngFor="let professor of professores"
        class="u-font-size--regular u-font-weight--regular u-gray--500 u-margin-top--xxs u-margin-bottom--xs">
        Prof. {{ professor.nome }}
      </h5>
      <h5
        *ngIf="!professores.length"
        class="u-font-size--regular u-font-weight--regular u-gray--500 u-margin-top--xxs u-margin-bottom--xs">
        Sem Professor
      </h5>
    </div>

    <!-- absences  -->
    <div class="u-display--flex u-flex-align--center u-margin-top--sm u-padding-bottom">
      <app-radial-progress-tracker [progress]="progress"></app-radial-progress-tracker>
      <h6 class="e-card-disciplina__faltas u-margin-left--sm" [class.u-red]="progress > 1">
        {{ faltas }}
        <span>/ {{ maximo_faltas > 0 ? maximo_faltas : '--' }}</span>
        FALTAS
      </h6>
    </div>
  </section>

  <!-- class events -->

  <section *ngIf="eventos.length > 0 && variant !== 'grade-horaria-grid'">
    <div *ngFor="let evento of eventos | slice : 0 : eventos_threshold" (click)="emitOpenEvent(evento)">
      <div class="e-card-disciplina__evento">
        <ion-icon
          class="c-icon c-icon--large c-icon--bg c-icon--bg--evento e-card-disciplina__color e-card-disciplina__background u-margin-right--sm"
          name="clipboard"></ion-icon>
        <h5 class="u-gray--700 u-text-ellipsis u-flex-grow e-card-disciplina__evento-titulo u-font-size--regular">
          {{ evento.nome }}
        </h5>
        <div class="c-date c-date--column">
          <p class="c-date__day e-card-disciplina__date e-card-disciplina__color">{{ getDay(evento.inicio) }}</p>
          <p class="c-date__month">{{ getMonth(evento.inicio) }}</p>
        </div>
        <ion-icon class="c-icon c-icon--arrow u-margin-left--xxs" name="chevron-forward"></ion-icon>
      </div>
    </div>
    <div
      *ngIf="eventos.length > eventos_threshold"
      class="e-card-disciplina__remaining-events"
      (click)="redirectToEvents()">
      {{ remainingMessage }}
    </div>
  </section>
</div>
