import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { HistoricoItem } from '../../../core/http/aluno/aluno-api.interface';

export interface GradeState extends EntityState<HistoricoItem> {
  updatedAt: string | null;
}
export function createInitialState(): GradeState {
  return { updatedAt: null };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'grade', idKey: 'historico_id', resettable: true })
export class GradeStore extends EntityStore<GradeState, HistoricoItem> {
  constructor() {
    super(createInitialState());
  }
}
