<div class="e-photo-list">
  <ng-container *ngIf="!loading; else spinner">
    <div *ngIf="alunos.length > 0; else avatarsVazios" class="u-display--flex u-flex-align--center">
      <div
        class="e-photo-list__avatar"
        [class.e-photo-list__avatar--large]="type === 'large'"
        *ngFor="let aluno of alunos | slice : 0 : length">
        <img [src]="aluno | avatar : 'small'" />
      </div>

      <h6
        class="u-font-weight--regular u-font-size--xs u-gray--600 u-margin-left--xs"
        *ngIf="counter || alunos.length > length">
        +{{ counter || alunos.length - length }}
      </h6>
    </div>
  </ng-container>

  <ng-template #avatarsVazios>
    <img [class.e-photo-list__empty--large]="type === 'large'" src="assets/img/icons/empty-avatar.svg" />
  </ng-template>

  <ng-template #spinner>
    <ion-spinner class="c-spinner u-margin-right--md u-margin-top--xxs" name="bubbles"></ion-spinner>
  </ng-template>
</div>
