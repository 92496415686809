import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { IonicSlides, ModalController } from '@ionic/angular';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { AnalyticsService } from '../../../../../src/app/core/services/analytics/analytics.service';

SwiperCore.use([Pagination, Navigation, IonicSlides]);

export interface OnboardingSlidesContentProp {
  title: string;
  description: string;
  image: string;
  backgroundColor?: string;
  button: string;
  showSkip?: boolean;
}

@Component({
  selector: 'app-onboarding-slides',
  templateUrl: './onboarding-slides.component.html',
  styleUrls: ['./onboarding-slides.component.scss'],
})
export class OnboardingSlidesComponent implements OnInit {
  @ViewChild('contentSlides', { static: true }) contentSlides: SwiperComponent | undefined = undefined;

  @Input() slides: OnboardingSlidesContentProp[] = [];
  @Input() style: 'page' | 'modal' = 'page';

  backgroundColor: string | undefined = undefined;
  button: string | undefined = undefined;
  showSkip? = true;

  slideOptions: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  constructor(
    private modalController: ModalController,
    private ngZone: NgZone,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.backgroundColor = this.slides && this.slides.length > 0 ? this.slides[0].backgroundColor : undefined;
    this.getButtonText();
  }

  emitNext() {
    this.analyticsService.trackEvent('clicar-proximo', 'onboarding-slides');
    if (!this.contentSlides) return;
    const currentSlideIndex = this.contentSlides.swiperRef.activeIndex;
    if (this.slides.length === 0) {
      return;
    }
    if (currentSlideIndex === this.slides.length - 1) {
      this.dismiss();
    } else {
      this.contentSlides.swiperRef.slideTo(currentSlideIndex + 1);
    }
  }

  async slideWillChange() {
    // Swiper Angular component emits all events outside of NgZone
    // https://swiperjs.com/angular#swiper-component-events
    this.ngZone.run(() => {
      this.getButtonText();
      this.backgroundColor = this.getNewBackgroundColor();
      this.showSkip = this.getSkip();
    });
  }

  async dismiss() {
    this.modalController.dismiss();
  }

  private getSkip() {
    if (this.slides.length === 0 || !this.contentSlides) {
      return;
    }
    const currentSlideIndex = this.contentSlides.swiperRef.activeIndex;
    if (this.slides[currentSlideIndex].showSkip === undefined) {
      return true;
    }

    return this.slides[currentSlideIndex].showSkip;
  }

  private getButtonText() {
    let currentSlideIndex;
    if (!this.contentSlides) return;
    if (!this.contentSlides.swiperRef) {
      currentSlideIndex = 0;
    } else {
      currentSlideIndex = this.contentSlides.swiperRef.activeIndex;
    }
    if (this.slides.length === 0) {
      return;
    }
    if (currentSlideIndex === this.slides.length - 1) {
      this.button = this.slides[currentSlideIndex].button;
    } else {
      this.button = 'PRÓXIMO';
    }
  }

  private getNewBackgroundColor() {
    if (!this.contentSlides || !this.slides) {
      return undefined;
    }
    const currentSlideIndex = this.contentSlides.swiperRef.activeIndex;

    const background =
      currentSlideIndex < this.slides.length ? this.slides[currentSlideIndex].backgroundColor : undefined;
    return background;
  }
}
