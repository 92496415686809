import { Component, Input, OnInit } from '@angular/core';
import { format, getMonth } from 'date-fns';
import { EventosQuery } from '../../../modules/eventos/state/eventos.query';
import { ExtendedEvento } from '../../../core/http/evento/evento-api.interface';
import DateUtilities from '../../utilities/date.utilities';

@Component({
  selector: 'app-evento-preview',
  templateUrl: './evento-preview.component.html',
  styleUrls: ['./evento-preview.component.scss'],
})
export class EventoPreviewComponent implements OnInit {
  @Input() evento!: ExtendedEvento;
  @Input() border = false;
  @Input() mostrarConfirmacoes: boolean | undefined = undefined;
  @Input() color!: string;

  label: string | undefined = undefined;
  diaInicio = '01';
  mesInicio = 'JAN';
  confirmado = false;
  desconfirmado = false;

  constructor(private eventoQuery: EventosQuery) {}

  ngOnInit() {
    if (!this.evento) {
      return;
    }
    const date = new Date(this.evento.inicio);
    this.diaInicio = format(date, 'dd');
    const mes = getMonth(date).toString();
    // tslint:disable-next-line:radix
    this.mesInicio = DateUtilities.mesesAbreviados[parseInt(mes)];

    this.setLabel();
    this.setConfirmado();
  }

  /**
   * Decides if the event is confirmed or not
   */
  private setConfirmado() {
    if (!this.evento) {
      return;
    }
    this.confirmado = this.eventoQuery.eventoConfirmado(this.evento);
    this.desconfirmado = this.eventoQuery.eventoDesconfirmado(this.evento);
  }

  private setLabel() {
    if (!this.evento) {
      return;
    }
    if (this.mostrarConfirmacoes && this.evento.permite_interacao && this.evento.permite_ratificacao) {
      this.label =
        this.evento.ratificar_positivos === 1
          ? '1 pessoa confirmou'
          : `${this.evento.ratificar_positivos ?? 0} pessoas confirmaram`;
    } else {
      this.label =
        this.evento.categoria.grupo.nome === 'Curriculares' && this.evento.turmas && this.evento.turmas.length > 0
          ? this.evento.turmas[0].disciplina.nome
          : this.evento.categoria.tipo.nome;
    }
  }
}
