import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GetAlunoMatrizApiResponse, Turma } from '../aluno/aluno-api.interface';
import {
  ApiPostEventoBody,
  ApiPutEventoInteracaoBody,
  BasicEvento,
  EventoInteracao,
  EventoReportCategoria,
  ExtendedEvento,
} from './evento-api.interface';

@Injectable({
  providedIn: 'root',
})
export class EventoApiService {
  constructor(private http: HttpClient) {}

  deleteEvento(eventoId: BasicEvento['evento_id']) {
    return this.http.delete(`${environment.api}/eventos/${eventoId}`);
  }

  getCursoEventos(matrizId: GetAlunoMatrizApiResponse['matriz_id']) {
    return this.http.get<BasicEvento[]>(`${environment.api}/aluno/matrizes/${matrizId}/eventos`);
  }

  putEvento(eventoId: BasicEvento['evento_id'], body: ApiPostEventoBody) {
    return this.http.put<ExtendedEvento>(`${environment.api}/eventos/${eventoId}`, body);
  }

  postEvento(turmaId: Turma['turma_id'], body: ApiPostEventoBody) {
    return this.http.post<ExtendedEvento>(`${environment.api}/aluno/turmas/${turmaId}/eventos`, body);
  }

  putEventoInteracao(eventoId: BasicEvento['evento_id'], body: ApiPutEventoInteracaoBody) {
    return this.http.put<EventoInteracao>(`${environment.api}/eventos/${eventoId}/interacoes`, body);
  }

  getEvent(eventoId: BasicEvento['evento_id']) {
    return this.http.get<ExtendedEvento>(`${environment.api}/eventos/${eventoId}`);
  }

  getEventoVotos(eventoId: BasicEvento['evento_id']) {
    return this.http.get<EventoInteracao[]>(`${environment.api}/eventos/${eventoId}/interacoes/ratificar`);
  }

  postReportEvent(
    evento_id: BasicEvento['evento_id'],
    report_categoria_id: EventoReportCategoria['report_categoria_id'],
    detalhes?: string
  ) {
    const report = { report_categoria_id, detalhes };

    return this.http.post<EventoReportCategoria>(`${environment.api}/eventos/${evento_id}/report`, report);
  }
}
