import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasicSelectOption } from './basic-select.interface';

@Component({
  selector: 'app-basic-select',
  templateUrl: './basic-select.component.html',
  styleUrls: ['./basic-select.component.scss'],
})
export class BasicSelectComponent implements OnInit {
  @Input() options: BasicSelectOption[] = [];
  @Input() label: string | undefined = undefined;
  @Input() placeholder: string | undefined = undefined;
  @Input() disabled = false;
  @Input() styled: 'normal' | 'clear' = 'normal';
  @Input() opcional = false;

  @Output() change = new EventEmitter();

  @Input() set selectedValue(value: string | undefined) {
    this._selectedValue = value;

    const foundOption = this.options.find((option) => option.value === value);
    this.selectedText = foundOption ? foundOption.text : '';
  }

  selectedText: string | undefined = undefined;
  _selectedValue: string | undefined = undefined;

  ngOnInit() {}

  unselectAll() {
    this.options = this.options.map((el) => {
      el.selected = false;
      return el;
    });
  }

  valueChange(newValue: Event) {
    if (newValue) {
      this.change.emit((newValue as CustomEvent).detail);
    }
  }
}
