import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-radial-progress-tracker',
  templateUrl: './radial-progress-tracker.component.html',
  styleUrls: ['./radial-progress-tracker.component.scss'],
})
export class RadialProgressTrackerComponent implements OnInit {
  @Input() radius = 12;
  @Input() progress = 0;

  ngOnInit() {}
}
