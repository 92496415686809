import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthQuery } from '../../../modules/auth/state/auth.query';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(private router: Router, private authQuery: AuthQuery) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuthentication(this.router.url);
  }

  /**
   * Checks if the user is Authenticated and redirect to login if it is not
   *
   * @param {string} url
   * @returns {Promise<boolean>}
   * @memberof AuthGuard
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkAuthentication(url: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.authQuery.getValue().auth) {
        this.router.navigate(['/app/dashboard']);
        reject(false);
      } else {
        resolve(true);
      }
    });
  }
}
