import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CampusDetailed, InstituicaoDetailed } from '../../http/metadata/metadata-api.interface';

export interface MetadataState {
  instituicao: InstituicaoDetailed | null;
  campus: CampusDetailed | null;
}

export function createInitialState(): MetadataState {
  return {
    instituicao: null,
    campus: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'metadata', resettable: true })
export class MetadataStore extends Store<MetadataState> {
  constructor() {
    super(createInitialState());
  }
}
