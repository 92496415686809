import { Injectable } from '@angular/core';
import { GradeQuery } from '../../../modules/historicos/state/grade.query';
import { LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import {
  addDays,
  format,
  getHours,
  getMinutes,
  isPast,
  parse,
  setDay,
  setHours,
  setMinutes,
  setSeconds,
  subDays,
  subMinutes,
} from 'date-fns';
import { isAfter, parseISO } from 'date-fns/esm';
import { Subject } from 'rxjs';
import { EventosQuery } from '../../../modules/eventos/state/eventos.query';
import { ExtendedEvento } from '../../http/evento/evento-api.interface';
import { AppNotification } from '../../http/notification/notification.interface';
import { GradeHorariaDia } from '../aluno/aluno.interface';
import { PlatformService } from '../platform/platform.service';
import { SettingsQuery } from '../settings/settings.query';
import { AnalyticsService } from '../analytics/analytics.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalNotificationsService {
  public notificacoes$ = new Subject<AppNotification>();
  private notificacoes: LocalNotificationSchema[] = [];
  private initialized = false;

  constructor(
    private eventosQuery: EventosQuery,
    private settingsQuery: SettingsQuery,
    private gradeQuery: GradeQuery,
    private platformService: PlatformService,
    private analyticsService: AnalyticsService
  ) {}

  init() {
    if (this.initialized || !this.platformService.isNative()) {
      return;
    }

    this.initialized = true;
    if (this.platformService.isAndroid()) {
      LocalNotifications.createChannel({
        id: 'default',
        name: 'Default Notifications',
        importance: 3,
        visibility: 1,
        lights: true,
        lightColor: '#f88b33',
        vibration: true,
      });
    }

    LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction) => {
      const notification = notificationAction?.notification;
      const appNotification: AppNotification = {
        source: notification?.extra?.source,
        type: 'local',
        trigger: 'clicked',
      };

      if (notification?.extra?.source === 'evento') {
        appNotification.redirectRoute =
          notification?.extra?.id === 0 ? ['/eventos'] : [`/evento/${notification?.extra?.id}`];
      }

      this.notificacoes$.next(appNotification);
    });
  }

  // notificacoesAbertas(): Observable<AppNotification> {

  //   return this.localNotifications.on('click').pipe(
  //     map((next) => {
  //       const notification: AppNotification = {
  //         source: next.data.source,
  //         type: 'local',
  //         trigger: 'clicked',
  //       };

  //       if (next.data.source === 'evento') {
  //         notification.redirectRoute =
  //           next.data.id === 0 ? ['/eventos'] : [`/evento/${next.data.id}`];
  //       }

  //       return notification;
  //     }),
  //   );
  // }

  getNotificacoesEventos(eventos: ExtendedEvento[]) {
    const eventosPorData = eventos.reduce<{ [key: string]: ExtendedEvento[] }>((grupos, evento) => {
      const dataEvento = format(parseISO(evento.inicio), 'yyyy-MM-dd');

      if (!grupos[dataEvento]) {
        grupos[dataEvento] = [evento];
      } else {
        grupos[dataEvento].push(evento);
      }
      return grupos;
    }, {});

    // eslint-disable-next-line no-restricted-syntax
    for (const dataEvento in eventosPorData) {
      const ev: ExtendedEvento[] = eventosPorData[dataEvento];

      let title = '';
      let text = '';
      const data = { source: 'evento', id: '' };

      // Mandar notifications um dia antes as 6 da tarde
      let dateTrigger = subDays(parse(dataEvento, 'yyyy-MM-dd', new Date()), 1);
      dateTrigger = setHours(dateTrigger, 18);
      const now = new Date();

      if (isAfter(dateTrigger, now)) {
        if (ev.length === 1) {
          data.id = ev[0].evento_id;
        }

        if (ev.length <= 3) {
          title = 'Seus eventos para amanhã';
          for (let i = 0; i < ev.length; i = i + 1) {
            text += ev[i].nome;
            if (i !== ev.length - 1) {
              text += ', ';
            }
          }
        } else {
          title = 'Amanhã você tem um dia cheio!';
          text = 'Visite o Uniapp para ver os seus eventos';
        }

        const notificacaoEvento: LocalNotificationSchema = {
          title,
          body: text,
          extra: data,
          id: this.notificacoes.length + 1,
          schedule: {
            at: dateTrigger,
          },
        };

        this.notificacoes.push(notificacaoEvento);
      }
    }
  }

  async criarNotificacoes() {
    if (!this.platformService.isNative()) {
      return;
    }

    this.notificacoes = [];

    this.criarNotificacoesAulas();
    this.criarNotificacoesEventos();
    this.setNotificacoes(this.notificacoes);
  }

  async criarNotificacoesAulas(): Promise<void> {
    if (this.settingsQuery.getValue().notificacoesAulas) {
      const gradeHoraria = this.gradeQuery.gradeSemana();
      return this.getNotificacoesAulas(gradeHoraria);
    }
  }

  async criarNotificacoesEventos(): Promise<void> {
    if (this.settingsQuery.getValue().notificacoesEventos) {
      const eventos = this.eventosQuery.eventosNotificaveis();
      return this.getNotificacoesEventos(eventos);
    }
  }

  async removeNotificacoes() {
    // TODO: remover somente as notificoes das aulas
    return this.criarNotificacoes();
  }

  private getNotificacoesAulas(gradeHoraria: GradeHorariaDia[]) {
    for (const dia of gradeHoraria) {
      const aulas = dia.aulas;

      for (const aula of aulas) {
        const data = { source: 'aula', id: aula.turma.turma_id };
        const horario = aula.horario;

        // Hora e minuto do começo da aula
        let [hour, minute] = horario.hora_inicio.split(':').map((value) => +value);

        // Calcula a hora e minuto da notificacao
        const horaProximaAula = setHours(setMinutes(setSeconds(new Date(), 0), minute), hour);
        const horaMenosTempoNotificacao = subMinutes(horaProximaAula, 15);
        let diaDaSemana = setDay(horaMenosTempoNotificacao, dia.dia_da_semana);
        if (isPast(diaDaSemana)) {
          diaDaSemana = addDays(diaDaSemana, 7);
        }

        // Atualiza o horario
        hour = getHours(horaMenosTempoNotificacao);
        minute = getMinutes(horaMenosTempoNotificacao);

        // Caso tenha informações da sala, mostrar na notificação
        const textoSala = aula.horario.sala ? `Sala ${aula.horario.sala}. ` : '';

        const notificacaoAula: LocalNotificationSchema = {
          id: this.notificacoes.length + 1,
          title: `${aula.turma.disciplina.nome}`,
          body: `${textoSala}Das ${aula.horario.hora_inicio} até ${aula.horario.hora_termino}`,
          extra: data,
          schedule: {
            at: diaDaSemana,
            every: 'week',
            count: 500,
          },
        };

        this.notificacoes.push(notificacaoAula);
      }
    }
  }

  // eslint-disable-next-line
  private async setNotificacoes(notificacoes: LocalNotificationSchema[]) {
    // Tire os comentarios abaixo pra colocar uma notificao 30 segundos depois do app abrir
    // const inAMoment = addSeconds(new Date(), 30);
    // const debugNotification: LocalNotificationSchema = {
    //   id: 0,
    //   title: 'Teste',
    //   body: 'teste',
    //   schedule: { at: inAMoment },
    //   smallIcon: 'res://small',
    //   extra: {
    //     source: 'teste',
    //   },
    // };

    // notificacoes.push(debugNotification);
    // console.log('local notifications', notificacoes);

    try {
      const pendingNotifications = await LocalNotifications.getPending();
      if (pendingNotifications?.notifications?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const cancelNotifications = LocalNotifications.cancel({
          notifications: pendingNotifications.notifications,
        });
      }

      await LocalNotifications.schedule({ notifications: notificacoes });
    } catch (err) {
      if (!environment.production) {
        // eslint-disable-next-line no-console
        console.info(err);
      }
      this.analyticsService.trackEvent('notificacoes-sem-permissoes', 'notificacoes');
    }
  }
}
