import { Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../../../../environments/environment';
import { AppSettings } from '../../../app.settings';
import { AlunoQuery } from '../../../modules/aluno/state/aluno.query';
import { RollbarService } from '../rollbar/rollbar.service';
import packageJson from '../../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private client = {
    javascript: {
      code_version: AppSettings.codeVersion, // Git SHA of your deployed code
      source_map_enabled: true,
      guess_uncaught_frames: true,
    },
  };
  constructor(@Inject(RollbarService) private rollbar: Rollbar, private alunoQuery: AlunoQuery) {
    this.alunoQuery.aluno$.subscribe((aluno) => {
      if (aluno) {
        this.rollbar.configure({
          payload: {
            client: this.client,
            person: {
              id: aluno.aluno_id,
              username: aluno.codigo,
            },
          },
        });
      } else {
        this.rollbar.configure({ payload: { client: this.client } });
      }
    });
  }

  public debug(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.debug(payload);
    }
  }

  public info(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.info(payload);
    }
  }

  public warning(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.warning(payload);
    }
  }

  public error(payload: any): void {
    if (this.shouldDiscardPayload(payload)) {
      return;
    }

    if (this.isEnabled()) {
      const appVersion = {
        appVersion: packageJson.version,
      };
      this.rollbar.error(payload, appVersion);
    }
  }

  public critical(payload: any): void {
    if (this.shouldDiscardPayload(payload)) {
      return;
    }

    if (this.isEnabled()) {
      const appVersion = {
        appVersion: packageJson.version,
      };
      this.rollbar.error(payload, appVersion);
    }
  }

  private shouldDiscardPayload(payload: any) {
    // ignore auth and offline errors
    if ([0, 400, 401].indexOf(payload?.original?.status) > -1) {
      return true;
    }

    return false;
  }

  private isEnabled() {
    return environment.rollbar.enabled;
  }
}
