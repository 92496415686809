import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

interface Me {
  id: string;
  username: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Profile {
  graphql: {
    user: {
      full_name: string;
      username: string;
      profile_pic_url_hd: string;
      profile_pic_url: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class InstagramAPIService {
  constructor(private http: HttpClient) {}

  getMe(accessToken: string) {
    return this.http.get<Me>(
      `${environment.instagram.graph_url}/me?fields=id,username&access_token=${accessToken}`
    );
  }
}
