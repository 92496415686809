import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { isBefore, parseISO } from 'date-fns';
import { map } from 'rxjs/operators';
import { CardapioQuery } from '../../cardapio/state/cardapio.query';
import { EventosQuery } from '../../eventos/state/eventos.query';
import { GradeQuery } from '../../historicos/state/grade.query';
import { DashboardState, DashboardStore } from './dashboard.store';

@Injectable({ providedIn: 'root' })
export class DashboardQuery extends Query<DashboardState> {
  showOnboarding$ = this.select((state) => state.showOnboarding);

  cardapiosDisponiveisHoje$ = this.cardapioQuery.cardapiosHoje$.pipe(
    map((cardapios) => {
      const menuToday = cardapios.filter((c) => isBefore(Date.now(), parseISO(c.termino)));
      return menuToday;
    })
  );

  constructor(
    protected store: DashboardStore,
    private cardapioQuery: CardapioQuery,
    private eventoQuery: EventosQuery,
    private gradeQuery: GradeQuery
  ) {
    super(store);
  }

  gradeHoje() {
    return this.gradeQuery.gradeHoje$;
  }

  eventosHoje() {
    return this.eventoQuery.eventosHoje$;
  }

  cardapiosHoje() {
    return this.cardapioQuery.cardapiosHoje$;
  }

  cardapiosSemana() {
    return this.cardapioQuery.cardapios$;
  }

  showOnboarding() {
    return this.getValue().showOnboarding;
  }
}
