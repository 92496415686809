<div class="c-modal c-modal--short c-modal--center u-flex-justify--between e-validade-page">
  <section class="u-display--flex u-flex-align--center u-flex-direction--column">
    <ion-icon class="c-modal__close" name="close" (click)="dismiss()"></ion-icon>
    <ion-icon class="c-modal__icon" name="checkmark-done"></ion-icon>
    <h1 class="c-modal__title">Validado Digitalmente</h1>
    <p class="c-modal__description">
      Este perfil foi validado digitalmente pelo aplicativo Uniapp. As informações contidas aqui são obtidas
      diretamente do sistema acadêmico da universidade e representam a situação atual do usuário.
    </p>
    <p class="c-modal__description">
      É de responsabilidade do usuário deste serviço a integridade e não adulteração dos dados presentes no
      aplicativo.
    </p>
    <h4 class="u-margin-top--lg u-margin-bottom--none u-gray--600">Validado em: {{date}} às {{hour}}</h4>
  </section>
</div>
