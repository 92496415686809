import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SwiperModule } from 'swiper/angular';
import { AlertComponent } from './components/alert/alert.component';
import { BackgroundShapesComponent } from './components/background-shapes/background-shapes.component';
import { BasicCheckboxComponent } from './components/basic-checkbox/basic-checkbox.component';
import { BasicInputComponent } from './components/basic-input/basic-input.component';
import { BasicSelectComponent } from './components/basic-select/basic-select.component';
import { BasicTextareaComponent } from './components/basic-textarea/basic-textarea.component';
import { ButtonComponent } from './components/button/button.component';
import { DisciplinaCardComponent } from './components/disciplina-card/disciplina-card.component';
import { EmptyScreenComponent } from './components/empty-screen/empty-screen.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { EventoPreviewComponent } from './components/evento-preview/evento-preview.component';
import { HeaderDescriptionItemComponent } from './components/header-description-item/header-description-item.component';
import { InputDatetimeComponent } from './components/input-datetime/input-datetime.component';
import { InputImageCropperComponent } from './components/input-image-cropper/input-image-cropper.component';
import { LinkRowComponent } from './components/link-row/link-row.component';
import { ListEventosComponent } from './components/list-eventos/list-eventos.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { LoadingStateComponent } from './components/loading-state/loading-state.component';
import { PhotoListComponent } from './components/photo-list/photo-list.component';
import { RadialProgressTrackerComponent } from './components/radial-progress-tracker/radial-progress-tracker.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { StudentListComponent } from './components/student-list/student-list.component';
import { StudentRowComponent } from './components/student-row/student-row.component';
import { TextTipComponent } from './components/text-tip/text-tip.component';
import { WeekdaySelectorComponent } from './components/weekday-selector/weekday-selector.component';
import { TransformCloseButtonDirective } from './directives/transform-close-button/transform-close-button.directive';
import { EventosAdicionarPage } from './modals/eventos-adicionar/eventos-adicionar.page';
import { OnboardingSlidesComponent } from './modals/onboarding-slides/onboarding-slides.component';
import { AvatarPipe } from './pipes/avatar.pipe';
import { UserAvatarPipe } from './pipes/user-avatar.pipe';
import { ReviewCardComponent } from './components/review-card/review-card.component';

registerLocaleData(localePt);

@NgModule({
  imports: [CommonModule, IonicModule, SwiperModule, ImageCropperModule],
  declarations: [
    TextTipComponent,
    BasicSelectComponent,
    BasicInputComponent,
    ButtonComponent,
    HeaderDescriptionItemComponent,
    EmptyScreenComponent,
    LoadingBarComponent,
    BackgroundShapesComponent,
    WeekdaySelectorComponent,
    LoadingStateComponent,
    EmptyStateComponent,
    OnboardingSlidesComponent,
    ListItemComponent,
    RadioGroupComponent,
    RadialProgressTrackerComponent,
    StudentRowComponent,
    StudentListComponent,
    BasicTextareaComponent,
    LinkRowComponent,
    AlertComponent,
    EventoPreviewComponent,
    PhotoListComponent,
    BasicCheckboxComponent,
    DisciplinaCardComponent,
    EventosAdicionarPage,
    TransformCloseButtonDirective,
    InputDatetimeComponent,
    InputImageCropperComponent,
    ListEventosComponent,
    AvatarPipe,
    UserAvatarPipe,
    ReviewCardComponent,
  ],
  exports: [
    TextTipComponent,
    BasicSelectComponent,
    BasicInputComponent,
    ButtonComponent,
    CommonModule,
    FormsModule,
    HeaderDescriptionItemComponent,
    EmptyScreenComponent,
    LoadingBarComponent,
    BackgroundShapesComponent,
    WeekdaySelectorComponent,
    LoadingStateComponent,
    EmptyStateComponent,
    OnboardingSlidesComponent,
    ListItemComponent,
    RadioGroupComponent,
    RadialProgressTrackerComponent,
    StudentRowComponent,
    StudentListComponent,
    BasicTextareaComponent,
    LinkRowComponent,
    AlertComponent,
    EventoPreviewComponent,
    PhotoListComponent,
    BasicCheckboxComponent,
    DisciplinaCardComponent,
    EventosAdicionarPage,
    TransformCloseButtonDirective,
    InputDatetimeComponent,
    InputImageCropperComponent,
    ListEventosComponent,
    AvatarPipe,
    UserAvatarPipe,
    ReviewCardComponent,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
})
export class SharedModule {}
