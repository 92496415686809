import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { SharedModule } from '../../shared/shared.module';
import { CardActivitiesDashComponent } from './components/card-activities-dash/card-activities-dash.component';
import { CardCardapioDashComponent } from './components/card-cardapio-dash/card-cardapio-dash.component';
import { CardDisciplinaDashComponent } from './components/card-disciplina-dash/card-disciplina-dash.component';
import { CardDisciplinaLoadingDashComponent } from './components/card-disciplina-loading-dash/card-disciplina-loading-dash.component';
import { CardEventoDashComponent } from './components/card-evento-dash/card-evento-dash.component';
import { CardEventosDashComponent } from './components/card-eventos-dash/card-eventos-dash.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardPage } from './page/dashboard/dashboard.page';
import { CardCardapioEmptyDashComponent } from './components/card-cardapio-empty-dash/card-cardapio-empty-dash.component';

@NgModule({
  declarations: [
    DashboardPage,
    CardEventosDashComponent,
    CardDisciplinaDashComponent,
    CardDisciplinaLoadingDashComponent,
    CardCardapioDashComponent,
    CardEventoDashComponent,
    CardActivitiesDashComponent,
    CardCardapioEmptyDashComponent,
  ],
  imports: [SharedModule, CommonModule, IonicModule, DashboardRoutingModule, SwiperModule],
})
export class DashboardModule {}
