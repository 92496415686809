import { Component } from '@angular/core';
import { MetadataQuery } from '../../../core/services/metadata/metadata.query';
import { CampusDetailed } from '../../../core/http/metadata/metadata-api.interface';
import { TabsService } from '../../../core/services/tabs/tabs.service';

interface AppTab {
  name: string;
  icon: string;
}

@Component({
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage {
  showTab = true;
  tabs: AppTab[] = [];

  // Some tabs have different names from their funcionalidade handle
  private tabFuncionalidadeMap: { [name: string]: string } = {
    disciplinas: 'disciplinas',
    eventos: 'calendario',
    biblioteca: 'biblioteca',
    cardapios: 'restaurantes',
  };

  private allTabs: AppTab[] = [
    {
      name: 'dashboard',
      icon: 'today',
    },
    {
      name: 'disciplinas',
      icon: 'create',
    },
    {
      name: 'eventos',
      icon: 'calendar',
    },
    {
      name: 'biblioteca',
      icon: 'bookmarks',
    },
    {
      name: 'cardapios',
      icon: 'restaurant',
    },
  ];

  constructor(private tabsService: TabsService, private metadataQuery: MetadataQuery) {
    this.tabsService.showTabs$.subscribe((show) => (this.showTab = show));
    this.tabs = this.getAvailableTabs(this.metadataQuery.funcionalidadesCampus());

    this.metadataQuery.funcionalidadesCampus$.subscribe({
      next: (funcionalidades) => (this.tabs = this.getAvailableTabs(funcionalidades)),
    });
  }

  /**
   * A function that returns a list of available tabs based on the available features
   * @param funcionalidades
   * @returns a list of tabs that should be displayed
   */
  private getAvailableTabs(funcionalidades: CampusDetailed['funcionalidades']): AppTab[] {
    const availableTabs = this.allTabs.filter((tab) => {
      if (tab.name === 'dashboard') {
        return true;
      }

      if (!funcionalidades) {
        return true;
      }

      return (
        funcionalidades.findIndex((funcionalidade) => {
          return funcionalidade.handle === this.tabFuncionalidadeMap[tab.name];
        }) > -1
      );
    });

    return availableTabs;
  }
}
