import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Periodo, SituacaoDisciplinaMatriz } from '../../../core/http/aluno/aluno-api.interface';

export interface MatrizState extends EntityState<SituacaoDisciplinaMatriz> {
  periodos: { [key: string]: Periodo };
}

export function createInitialState() {
  return {
    periodos: {},
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'matriz', idKey: 'disciplina_id', resettable: true })
export class MatrizStore extends EntityStore<MatrizState, SituacaoDisciplinaMatriz> {
  constructor() {
    super(createInitialState());
  }

  set(situacoes: SituacaoDisciplinaMatriz[]) {
    super.set(situacoes);
    super.update({ periodos: this.calculaPeriodosDas(situacoes) });
  }

  private calculaPeriodosDas(situacoes: SituacaoDisciplinaMatriz[]) {
    return situacoes.reduce<MatrizState['periodos']>((periodos, situacao) => {
      const numDoPeriodo = situacao.pivot.periodo;

      if (!periodos[numDoPeriodo]) {
        periodos[numDoPeriodo] = this.novoPeriodo(numDoPeriodo);
      }

      periodos[numDoPeriodo].total += 1;

      if (situacao.situacao === 'aprovado') {
        periodos[numDoPeriodo].total_aprovado += 1;
      }
      if (situacao.situacao === 'reprovado') {
        periodos[numDoPeriodo].total_reprovado += 1;
      }
      if (situacao.situacao === 'cursando') {
        periodos[numDoPeriodo].total_cursando += 1;
      }

      return periodos;
    }, {});
  }

  private novoPeriodo(numDoPeriodo: number): Periodo {
    return {
      periodo: numDoPeriodo,
      total: 0,
      total_cursando: 0,
      total_aprovado: 0,
      total_reprovado: 0,
    };
  }
}
