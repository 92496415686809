<div class="c-field" [class.c-field--clear]="styled === 'clear'">
  <h4 *ngIf="label" class="c-field__label">
    <span>{{ label }}</span>
    <span *ngIf="opcional" class="u-margin-left--xs u-gray--400 u-font-weight--regular u-font-size--xs"
      >(opcional)</span
    >
  </h4>
  <!-- [selected]="option.selected || selectedValue == option.value" -->
  <ion-select
    class="c-field__border c-field__select"
    interface="action-sheet"
    mode="md"
    [disabled]="disabled"
    [selectedText]="selectedText"
    [placeholder]="placeholder"
    (ionChange)="valueChange($event)">
    <ion-select-option *ngFor="let option of options" [value]="option.value">
      {{ option.text }}
    </ion-select-option>
  </ion-select>
</div>
