import { Pipe, PipeTransform } from '@angular/core';
import { GetAlunoApiResponse } from '../../core/http/aluno/aluno-api.interface';

@Pipe({
  name: 'avatar',
})
export class AvatarPipe implements PipeTransform {
  /**
   * Returns the right avatar for the user.
   * As we've disabled the instagram feature, the source will always be 'camera'
   * TODO: revert this once instagram integration is working
   * @param size
   * @returns the avatar url
   */
  transform(aluno: GetAlunoApiResponse | null | undefined, size: 'small' | 'medium' | 'large' = 'small'): string {
    const defaultURL = 'assets/img/icons/default-avatar.svg';
    if (!aluno) {
      return defaultURL;
    }
    let photoUrl;
    switch (size) {
      case 'large':
        photoUrl = aluno.perfil_foto_large_url;
        break;
      case 'medium':
        photoUrl = aluno.perfil_foto_medium_url;
        break;
      case 'small':
      default:
        photoUrl = aluno.perfil_foto_small_url;
    }

    return photoUrl ?? defaultURL;
  }
}
