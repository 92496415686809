import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { HistoricoItem } from '../../../core/http/aluno/aluno-api.interface';

export interface DisciplinaState extends EntityState<HistoricoItem> {
  updatedAt: string | null;
}
export function createInitialState(): DisciplinaState {
  return { updatedAt: null };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'disciplinas', idKey: 'historico_id', resettable: true })
export class DisciplinaStore extends EntityStore<DisciplinaState, HistoricoItem> {
  constructor() {
    super(createInitialState());
  }
}
