import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GetAlunoApiResponse } from '../aluno/aluno-api.interface';
import { Campus } from '../metadata/metadata-api.interface';
import { Cardapio, GetConfirmadoResponse, Restaurante } from './cardapio-api.interface';

@Injectable({
  providedIn: 'root',
})
export class CardapioApiService {
  constructor(private http: HttpClient) {}

  getCardapios(restaurante_id: Restaurante['restaurante_id']): Observable<Cardapio[]> {
    return this.http.get<Cardapio[]>(`${environment.api}/restaurantes/${restaurante_id}/cardapios/weekly`);
  }

  getRestaurantes(campus_id: Campus['campus_id']): Observable<Restaurante[]> {
    return this.http.get<Restaurante[]>(`${environment.api}/resources/campi/${campus_id}/restaurantes`);
  }

  getConfirmados(cardapio_id: Cardapio['cardapio_id']) {
    return this.http.get<GetConfirmadoResponse>(`${environment.api}/cardapios/${cardapio_id}/alunos-confirmados`);
  }

  putConfirma(cardapioId: Cardapio['cardapio_id']) {
    return this.http.put<GetAlunoApiResponse>(
      `${environment.api}/cardapios/${cardapioId}/alunos-confirmados`,
      null
    );
  }

  deleteDesconfirma(cardapioId: Cardapio['cardapio_id']) {
    return this.http.delete<GetAlunoApiResponse>(`${environment.api}/cardapios/${cardapioId}/alunos-confirmados`);
  }
}
