import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { Disciplina, Turma } from '../../../core/http/aluno/aluno-api.interface';
import {
  ApiPostEventoBody,
  ApiPutEventoInteracaoBody,
  BasicEvento,
  EventoInteracao,
  EventoReportCategoria,
} from '../../../core/http/evento/evento-api.interface';
import { EventoApiService } from '../../../core/http/evento/evento-api.service';
import { EventoCategoria } from '../../../core/http/metadata/metadata-api.interface';
import { LocalNotificationsService } from '../../../core/services/local-notifications/local-notifications.service';
import { MetadataService } from '../../../core/services/metadata/metadata.service';
import { AlunoQuery } from '../../../modules/aluno/state/aluno.query';
import { EventosQuery } from '../../../modules/eventos/state/eventos.query';
import { EventosStore } from '../../../modules/eventos/state/eventos.store';

@Injectable({
  providedIn: 'root',
})
export class EventoService {
  constructor(
    private alunoQuery: AlunoQuery,
    private eventoApi: EventoApiService,
    private localNotificationService: LocalNotificationsService,
    private metadataService: MetadataService,
    private eventoStoreAkita: EventosStore,
    private eventoQuery: EventosQuery
  ) {
    this.metadataService.carregarMetadataEventos().subscribe();
  }

  setarEventosComoNovos(ids: string[]) {
    const novosEventosNaStore = this.eventoQuery.getValue().novosEventosIds;

    const novosEventosIds = novosEventosNaStore ? novosEventosNaStore.concat(ids) : ids;
    this.eventoStoreAkita.update({ novosEventosIds });
  }

  setarEventoComoNaoNovo(id: BasicEvento['evento_id']) {
    const novosEventosNaStore = this.eventoQuery.getValue().novosEventosIds;

    const novosEventosIds = novosEventosNaStore
      ? novosEventosNaStore.filter((eventoId: BasicEvento['evento_id']) => eventoId !== id)
      : [];

    this.eventoStoreAkita.update({ novosEventosIds });
  }

  resetarEventosNovos() {
    this.eventoStoreAkita.update({ novosEventosIds: [] });
  }

  carregarEventosCurso() {
    const idMatrizSelecionada = this.alunoQuery.idMatrizSelecionada();
    if (!idMatrizSelecionada) {
      return of([]);
    }
    return this.eventoApi.getCursoEventos(idMatrizSelecionada).pipe(
      tap((eventos) => {
        this.eventoStoreAkita.set(eventos);
        this.localNotificationService.criarNotificacoes();
      })
    );
  }

  carregarEvento(eventoId: BasicEvento['evento_id']) {
    return this.eventoApi.getEvent(eventoId).pipe(
      tap((evento) => {
        this.eventoStoreAkita.upsert(evento.evento_id, evento);
        this.localNotificationService.criarNotificacoes();
      })
    );
  }

  adicionarNovoEvento(turmaId: Turma['turma_id'], detalhes: ApiPostEventoBody) {
    return this.eventoApi.postEvento(turmaId, detalhes).pipe(
      tap((evento) => {
        this.eventoStoreAkita.add(evento);
        this.localNotificationService.criarNotificacoes();
      })
    );
  }

  editarEvento(eventoId: BasicEvento['evento_id'], detalhes: ApiPostEventoBody) {
    return this.eventoApi.putEvento(eventoId, detalhes).pipe(
      tap((evento) => {
        this.eventoStoreAkita.update((e: BasicEvento) => e.evento_id === eventoId, evento);
        this.localNotificationService.criarNotificacoes();
      })
    );
  }

  removerEvento(eventoId: BasicEvento['evento_id']) {
    return this.eventoApi.deleteEvento(eventoId).pipe(
      tap(() => {
        this.eventoStoreAkita.remove(eventoId);
        this.localNotificationService.criarNotificacoes();
      })
    );
  }

  carregarEventoVotos(eventoId: BasicEvento['evento_id']) {
    return this.eventoApi.getEventoVotos(eventoId);
  }

  reportarEvento(
    evento_id: BasicEvento['evento_id'],
    report_categoria_id: EventoReportCategoria['report_categoria_id'],
    detalhes?: string
  ) {
    return this.eventoApi.postReportEvent(evento_id, report_categoria_id, detalhes);
  }

  getEventosDisciplina(disciplina_id: Disciplina['disciplina_id']) {
    const eventosDisciplina = this.eventoQuery
      .getAll()
      .filter((ev) => (ev.turmas ? ev.turmas[0].disciplina.disciplina_id === disciplina_id : false));

    return eventosDisciplina;
  }

  /**
   * This function will send the request to the API set the aluno's ratification of the event
   * and update the Evento interacoes in the Store, so we can show the ratified eventos in the Eventos page
   *
   * @param {BasicEvento} eventoId - Evento Id
   * @param {ApiPutEventoInteracaoBody} body - Body
   * @returns {Observable<EventoInteracao>} - Observable with the Evento Interacao
   */
  enviarVotoEvento(
    eventoId: BasicEvento['evento_id'],
    body: ApiPutEventoInteracaoBody
  ): Observable<EventoInteracao> {
    return this.eventoApi.putEventoInteracao(eventoId, body).pipe(
      tap((interacao) => {
        const evento = this.eventoQuery.getEntity(eventoId);

        if (!evento) return;

        const ratificarPositivos = evento.ratificar_positivos ?? 0;
        const ratificarNegativos = evento.ratificar_negativos ?? 0;

        const ratificacao = evento.interacoes.find((alunoInteracao) => alunoInteracao.acao == 'ratificar');

        let interacoesUpdated = [];

        let ratificarPositivosUpdated = 0;
        let ratificarNegativosUpdated = 0;

        if (!interacao) {
          interacoesUpdated = evento.interacoes.filter((alunoInteracao) => alunoInteracao.acao !== 'ratificar');
          ratificarPositivosUpdated = ratificacao?.confirmado ? ratificarPositivos - 1 : ratificarPositivos;
          ratificarNegativosUpdated = ratificacao?.confirmado ? ratificarNegativos : ratificarNegativos - 1;
        } else if (ratificacao) {
          interacoesUpdated = evento.interacoes.filter((alunoInteracao) => alunoInteracao.acao !== 'ratificar');
          interacoesUpdated = [...interacoesUpdated, interacao];
          ratificarPositivosUpdated = interacao.confirmado ? ratificarPositivos + 1 : ratificarPositivos - 1;
          ratificarNegativosUpdated = interacao.confirmado ? ratificarNegativos - 1 : ratificarNegativos + 1;
        } else {
          interacoesUpdated = [...evento.interacoes, interacao];
          ratificarPositivosUpdated = interacao.confirmado ? ratificarPositivos + 1 : ratificarPositivos;
          ratificarNegativosUpdated = interacao.confirmado ? ratificarNegativos : ratificarNegativos + 1;
        }

        const eventoUpdated = {
          ...evento,
          interacoes: interacoesUpdated,
          ratificar_positivos: ratificarPositivosUpdated,
          ratificar_negativos: ratificarNegativosUpdated,
        };

        this.eventoStoreAkita.update(evento.evento_id, eventoUpdated);
        this.localNotificationService.criarNotificacoes();
      })
    );
  }

  /**
   * This function will send the request to the API set the will of the aluno to receive notifications
   * and update the Evento interacoes in the Store, so we can track which eventos the aluno wants to receive notifications
   *
   * @param {BasicEvento} eventoId - Evento Id
   * @param {ApiPutEventoInteracaoBody} body - Body
   * @returns {Observable<EventoInteracao>} - Observable with the Evento Interacao
   */
  enviarNotificacao(
    eventoId: BasicEvento['evento_id'],
    body: ApiPutEventoInteracaoBody
  ): Observable<EventoInteracao> {
    return this.eventoApi.putEventoInteracao(eventoId, body).pipe(
      tap((interacao) => {
        const evento = this.eventoQuery.getEntity(eventoId);

        if (!evento) return;

        const interacoesUpdated = interacao
          ? [...evento.interacoes, interacao]
          : evento.interacoes.filter((alunoInteracao) => alunoInteracao.acao !== 'notificar');

        const eventoUpdated = {
          ...evento,
          interacoes: interacoesUpdated,
        };

        this.eventoStoreAkita.update(evento.evento_id, eventoUpdated);
        this.localNotificationService.criarNotificacoes();
      })
    );
  }

  /**
   * This function will send the request to the API to hide or unhide the Evento and
   * update the Evento interacoes in the Store, so we can track which Eventos are hidden
   *
   * @param {BasicEvento} eventoId - Evento Id
   * @param {ApiPutEventoInteracaoBody} body - Body
   * @returns {Observable<EventoInteracao>} - Observable with the Evento Interacao
   */
  enviarOcultacao(
    eventoId: BasicEvento['evento_id'],
    body: ApiPutEventoInteracaoBody
  ): Observable<EventoInteracao> {
    return this.eventoApi.putEventoInteracao(eventoId, body).pipe(
      tap((interacao) => {
        const evento = this.eventoQuery.getEntity(eventoId);
        if (!evento) return;

        this.setNaoNotificarEvento(eventoId, !!interacao);

        const interacoesUpdated = interacao
          ? [...evento.interacoes, interacao]
          : evento.interacoes.filter((alunoInteracao) => alunoInteracao.acao !== 'ocultar');

        const eventoUpdated = {
          ...evento,
          interacoes: interacoesUpdated,
        };

        this.eventoStoreAkita.update(evento.evento_id, eventoUpdated);
        this.localNotificationService.criarNotificacoes();
      })
    );
  }

  /**
   * This function will send the request to the API to subscribe or unsubscribe to the Evento and
   * update the Evento interacoes in the Store, so we can track which Eventos the user is subscribed to
   *
   * @param {BasicEvento} eventoId - Evento Id
   * @param {ApiPutEventoInteracaoBody} body - Body
   * @returns {Observable<EventoInteracao>} - Observable with the Evento Interacao
   */
  enviarInscricao(
    eventoId: BasicEvento['evento_id'],
    body: ApiPutEventoInteracaoBody
  ): Observable<EventoInteracao> {
    return this.eventoApi.putEventoInteracao(eventoId, body).pipe(
      tap((interacao) => {
        const evento = this.eventoQuery.getEntity(eventoId);
        if (!evento) return;

        const interacoesUpdated = interacao
          ? [...evento.interacoes, interacao]
          : evento.interacoes.filter((alunoInteracao) => alunoInteracao.acao !== 'inscrever');

        const eventoUpdated = {
          ...evento,
          interacoes: interacoesUpdated,
        };

        this.eventoStoreAkita.update(evento.evento_id, eventoUpdated);
        this.localNotificationService.criarNotificacoes();
      })
    );
  }

  /**
   * This function will send the request to the API to show that the user is interested in the Evento and
   * update the Evento interacoes in the Store, so we can track which Eventos the user is interested in
   *
   * @param {BasicEvento} eventoId - Evento Id
   * @param {ApiPutEventoInteracaoBody} body - Body
   * @returns {Observable<EventoInteracao>} - Observable with the Evento Interacao
   */
  enviarInteresse(
    eventoId: BasicEvento['evento_id'],
    body: ApiPutEventoInteracaoBody
  ): Observable<EventoInteracao> {
    return this.eventoApi.putEventoInteracao(eventoId, body).pipe(
      tap((interacao) => {
        const evento = this.eventoQuery.getEntity(eventoId);
        if (!evento) return;

        const interesse = evento.interacoes.find((alunoInteracao) => alunoInteracao.acao === 'interessar');

        if (interesse) {
          return;
        }

        const eventoUpdated = {
          ...evento,
          interacoes: [...evento.interacoes, interacao],
        };

        this.eventoStoreAkita.update(evento.evento_id, eventoUpdated);
      })
    );
  }

  setGrupoVisibility(grupoId: EventoCategoria['grupo']['grupo_id'], show: boolean) {
    const categorias = this.eventoQuery.getValue().categorias;
    categorias
      .filter((categoria) => categoria.grupo.grupo_id === grupoId)
      .forEach((categoria) => {
        this.setCategoriaVisibility(categoria.categoria_id, show);
      });
  }

  setCategoriaVisibility(categoriaId: EventoCategoria['categoria_id'], show: boolean) {
    const categoriasExcluidas = this.eventoQuery.categoriasExcluidas();
    const categoriasFiltradas = categoriasExcluidas.filter((id) => id !== categoriaId);
    const updatedCategorias = show ? categoriasFiltradas : [...categoriasFiltradas, categoriaId];
    this.eventoStoreAkita.update({ idCategoriasExcluidas: updatedCategorias });
  }

  setMostrarDesconfirmados(mostrar: boolean) {
    this.eventoStoreAkita.update({ mostrarDesconfirmados: mostrar });
  }

  setMostrarOcultados(mostrar: boolean) {
    this.eventoStoreAkita.update({ mostrarOcultados: mostrar });
  }

  setNaoNotificarEvento(eventoId: BasicEvento['evento_id'], naoNotificar: boolean) {
    const eventosNaoNotificaveis = this.eventoQuery.eventosNaoNotificaveis();
    const naoNotificavel = eventosNaoNotificaveis.includes(eventoId);

    if (naoNotificar && !naoNotificavel) {
      this.eventoStoreAkita.update({
        idEventosNaoNotificaveis: [...eventosNaoNotificaveis, eventoId],
      });
    } else if (!naoNotificar && naoNotificavel) {
      this.eventoStoreAkita.update({
        idEventosNaoNotificaveis: eventosNaoNotificaveis.filter((id) => id !== eventoId),
      });
    }
  }
}
