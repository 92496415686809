<ion-item class="c-field__item-wrapper" no-margin no-padding [class.c-field__item-wrapper--reverse]="reverse">
  <ion-checkbox
    class="c-field__checkbox"
    name="basic-checkbox"
    [class.c-field__checkbox--disabled]="disabled"
    [checked]="checked"
    [indeterminate]="indeterminate"
    [disabled]="disabled"
    [class.c-field__checkbox--inverse]="reverse"
    mode="md"
    (ionChange)="emitInput($event)"></ion-checkbox>
  <ion-icon *ngIf="icon" [name]="icon" class="u-font-size--xl u-margin-left" [class.u-white]="reverse"></ion-icon>
  <ion-label
    class="c-field__inline-label u-margin-left"
    [class.c-field__inline-label--light]="styled === 'light'"
    *ngIf="label"
    [class.c-field__inline-label--inverse]="reverse">
    {{ label }}
  </ion-label>
</ion-item>
