import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../../../../../app/core/services/analytics/analytics.service';

@Component({
  selector: 'app-termos',
  templateUrl: './termos.page.html',
  styleUrls: ['./termos.page.scss'],
})
export class TermosPage implements OnInit {
  constructor(private modalController: ModalController, private analyticsService: AnalyticsService) {}

  ngOnInit() {}

  public async dismiss() {
    await this.modalController.dismiss();
  }
}
