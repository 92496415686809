<ion-content>
  <div class="l-container l-container--center l-container--white">
    <app-background-shapes></app-background-shapes>
    <form [formGroup]="loginForm" class="e-login-page__form">
      <div class="u-display--flex u-flex-align--center u-flex-direction--column u-margin-bottom--md u-width--100">
        <img src="assets/img/logo.png" class="e-login-page__logo" />
        <h4 class="u-text-align--center u-margin-bottom--md text-md u-font-weight--regular u-gray--600">
          Use o mesmo login e senha do portal do aluno da sua universidade.
        </h4>
        <div class="u-width--100">
          <app-basic-select
            #selectInstuticoes
            data-testid="login-instituicao-select"
            placeholder="Selecione a sua instituicao"
            [options]="opcoesInstituicoes"
            [disabled]="!opcoesInstituicoes?.length || buscandoInstituicao || buscandoCampi"
            (change)="buscaInstituicao($event)">
          </app-basic-select>

          <div class="u-margin-top--xs">
            <app-basic-select
              #selectCampus
              data-testid="login-campus-select"
              *ngIf="campoRequerido"
              placeholder="Selecione seu campus"
              [options]="opcoesCampi"
              [disabled]="buscandoInstituicao || buscandoCampi"
              (change)="setCampus($event)">
            </app-basic-select>
          </div>
          <div class="u-margin-top--md">
            <app-basic-input
              icon="person"
              data-testid="login-codigo-input"
              [control]="loginFormControls.codigo"
              [disabled]="loginFormValue.campus_id == null || !instituicaoSelecionada"
              [placeholder]="instituicaoSelecionada?.placeholder_codigo || 'Código'"
              (inputChange)="setUsername($event)"
              (submit)="focusOn(passwordInput)"></app-basic-input>
          </div>
          <div class="u-margin-top--xs">
            <app-basic-input
              #passwordInput
              data-testid="login-senha-input"
              type="password"
              icon="key"
              [disabled]="loginFormValue.campus_id == null || !instituicaoSelecionada"
              [placeholder]=" instituicaoSelecionada?.placeholder_senha || 'Senha'"
              (inputChange)="setPassword($event)"
              (submit)="fazerLogin()"></app-basic-input>
          </div>

          <div class="u-display--flex u-flex-justify--center u-flex-align--center u-margin-top--md">
            <app-basic-checkbox
              data-testid="login-checkbox"
              [checked]="loginFormValue.terms"
              (change)="setTerms($event)">
            </app-basic-checkbox>
            <span
              tappable
              class="u-font-size--md u-gray--500 u-margin-left--xs u-text-align--center"
              (click)="setTerms()">
              Eu li e aceito os
            </span>
            <span
              class="u-margin-left--xxs u-font-size--md u-pink u-font-weight--normal u-text-decoration--underline"
              (click)="abrirTermos()">
              Termos de Uso
            </span>
          </div>
        </div>
      </div>

      <app-button
        data-testid="login-button"
        fullWidth
        type="reverse"
        label="ENTRAR"
        [main]="true"
        [disabled]="loginForm.invalid"
        [loading]="submetendoForm"
        (action)="fazerLogin()">
      </app-button>
    </form>
  </div>
</ion-content>
