import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

import { AlunoStore } from '../../../modules/aluno/state/aluno.store';
import { AuthStore } from '../../../modules/auth/state/auth.store';
import { CardapiosStore } from '../../../modules/cardapio/state/cardapio.store';
import { EventosStore } from '../../../modules/eventos/state/eventos.store';
import { DisciplinaStore } from '../../../modules/historicos/state/disciplina.store';
import { GradeStore } from '../../../modules/historicos/state/grade.store';
import { MatrizStore } from '../../../modules/historicos/state/matriz.store';
import { GradeGridStore } from './../../../modules/historicos/state/grade-grid.store';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private authStore: AuthStore,
    private alunoStore: AlunoStore,
    private cardapioStore: CardapiosStore,
    private eventoStore: EventosStore,
    private disciplinaStore: DisciplinaStore,
    private gradeStore: GradeStore,
    private matrizStore: MatrizStore,
    private gradeGridStore: GradeGridStore,
    private notificationService: LocalNotificationsService,
    private router: Router,
    private storageService: StorageService,
    private alertController: AlertController
  ) {}

  async logout() {
    const alert = await this.alertController.create({
      header: 'Você está saindo do Uniapp!',
      message: 'Você tem certeza de que deseja sair?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },

        {
          text: 'OK',
          role: 'confirm',
          handler: () => this.performLogout(),
        },
      ],
    });

    alert.present();
  }

  /**
   * Perform the logout for the app.
   * - Clear stores
   * - Remove scheduled notifications
   * - Navigate to the login page
   */
  private performLogout() {
    this.resetStores();
    this.notificationService.removeNotificacoes();

    // Logging out
    this.router.navigate(['/login'], { replaceUrl: true });
    this.storageService.clearAll();
    this.authStore.reset();
  }

  /**
   * Clear all 'resettable' stores
   */
  public resetStores() {
    // Clearing stores
    this.alunoStore.reset();
    this.cardapioStore.reset();
    this.eventoStore.reset();
    this.disciplinaStore.reset();
    this.gradeStore.reset();
    this.matrizStore.reset();
    this.gradeGridStore.reset();
  }
}
