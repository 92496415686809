import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Disciplina, HistoricoItem } from '../../../core/http/aluno/aluno-api.interface';
import { AnoLetivo } from '../../../core/services/aluno/aluno.interface';
import { DisciplinaState, DisciplinaStore } from './disciplina.store';
import { GradeQuery } from './grade.query';

@Injectable({ providedIn: 'root' })
export class DisciplinaQuery extends QueryEntity<DisciplinaState, HistoricoItem> {
  historicoCompleto$ = this.selectAll();

  historicoCompletoPorSemestre$: Observable<AnoLetivo[]> = this.selectAll().pipe(
    map((historicos) => {
      const anosLetivos: AnoLetivo[] = [];

      for (const historico of historicos) {
        const ano = anosLetivos.find((a) => a.nome === historico.turma.ano);
        let semestre = null;

        if (ano) {
          semestre = ano.semestres.find((s) => +s.nome === historico.turma.semestre);
          if (semestre) {
            semestre.disciplinas.push(historico);
          } else {
            ano.semestres.push({
              nome: historico.turma.semestre,
              disciplinas: [historico],
            });
          }
        } else {
          semestre = { nome: historico.turma.semestre, disciplinas: [historico] };
          anosLetivos.push({ nome: historico.turma.ano, semestres: [semestre] });
        }
      }

      return anosLetivos;
    })
  );

  constructor(protected store: DisciplinaStore, private gradeQuery: GradeQuery) {
    super(store);
  }

  isDisciplinaLoaded(id: Disciplina['disciplina_id']) {
    const disciplina = this.gradeQuery.getValue().entities?.[id] || this.getValue().entities?.[id];
    return !!disciplina;
  }

  getDisciplina(id: HistoricoItem['historico_id']) {
    return this.gradeQuery
      .selectEntity(id)
      .pipe(switchMap((disciplina) => (!!disciplina ? of(disciplina) : this.selectEntity(id))));
  }

  historicoDisciplina(codigo: string) {
    return this.getAll().filter((entity) => entity.turma.disciplina.codigo === codigo);
  }

  historicoSemestre(semestre: number, ano: number): Observable<HistoricoItem[]> {
    return this.selectAll({
      filterBy: (entity) => entity.turma.semestre === semestre && entity.turma.ano === ano,
    });
  }
}
