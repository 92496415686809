import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth/auth.guard';
import { CarteirinhaPage } from './pages/carteirinha/carteirinha.page';

const routes: Routes = [
  {
    path: 'carteirinha',
    component: CarteirinhaPage,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarteirinhaRoutingModule {}
