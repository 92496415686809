import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { environment } from '../../../../environments/environment';
import { AlunoQuery } from '../../../modules/aluno/state/aluno.query';
import { filter } from 'rxjs';
import { AnalyticsCustomDimensions } from './analytics.interface';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private customDimensions: AnalyticsCustomDimensions = {
    user_properties: {
      campus_id: undefined,
      instituicao_id: undefined,
      aluno_id: undefined,
      curso_id: undefined,
    },
  };
  private shouldTrack = false;

  constructor(
    private googleAnalytics: Angulartics2GoogleGlobalSiteTag,
    private alunoQuery: AlunoQuery,
    private titleService: Title
  ) {}

  init() {
    if (!environment.production) {
      return;
    }

    // Quando carregar o aluno, coloca a ID no GA
    this.alunoQuery.aluno$.subscribe((aluno) => {
      if (aluno?.campus && aluno.campus.instituicao.nome !== 'Universidade do Unicórnio') {
        this.shouldTrack = true;
        this.customDimensions.user_properties.campus_id = aluno.campus.campus_id;
        this.customDimensions.user_properties.instituicao_id = aluno.campus.instituicao.instituicao_id;
        this.customDimensions.user_properties.aluno_id = aluno.aluno_id;

        this.googleAnalytics.setUsername(aluno.aluno_id.toString());
        this.googleAnalytics.setUserProperties(this.customDimensions);
      } else {
        this.shouldTrack = false;
        this.googleAnalytics.setUsername('');
        this.googleAnalytics.setUserProperties({});
      }
    });

    // Envia a ID do curso atual do usuário
    this.alunoQuery.cursoAtual$.pipe(filter((cursoAtual) => !!cursoAtual)).subscribe((cursoAtual) => {
      if (this.shouldTrack) {
        this.customDimensions.user_properties.curso_id = cursoAtual!.curso.curso_id;
        this.googleAnalytics.setUserProperties(this.customDimensions);
      }
    });

    this.googleAnalytics.startTracking();
  }

  trackEvent(action: string, category: string, label?: string) {
    if (this.shouldTrack) {
      this.googleAnalytics.eventTrack(action, { category, label });
    }
  }

  trackPageView(pageName: string, path: string) {
    if (this.shouldTrack) {
      this.titleService.setTitle(`${pageName} | Uniapp`);
      // Envia o PATH da rota atual
      this.googleAnalytics.pageTrack(path);
    }
  }
}
