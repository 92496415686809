export class AppSettings {
  public static codeVersion = '0.2.5';

  public static APP_ROOT = location.href.replace(location.hash, '').replace('index.html', '').replace('?', '');

  public static ROLLBAR_SOURCEMAP_URL_PREFIX = 'app://';

  public static FUNCIONALIDADES_LIBERADAS: {
    handle: string;
    title: string;
    url: string;
    icon: string;
    ionicon: boolean;
  }[] = [
    {
      handle: 'disciplinas',
      title: 'Disciplinas',
      url: 'app/disciplinas',
      icon: 'create',
      ionicon: true,
    },
    {
      handle: 'calendario',
      title: 'Eventos',
      url: 'app/eventos',
      icon: 'calendar',
      ionicon: true,
    },
    {
      handle: 'biblioteca',
      title: 'Biblioteca',
      url: 'app/biblioteca',
      icon: 'bookmarks',
      ionicon: true,
    },
    {
      handle: 'restaurantes',
      title: 'Restaurante',
      url: 'app/cardapios',
      icon: 'restaurant',
      ionicon: true,
    },
    {
      handle: 'curso',
      title: 'Perfil',
      url: 'app/perfil',
      icon: 'person',
      ionicon: true,
    },
    {
      handle: 'disciplinas',
      title: 'Histórico',
      url: 'app/historicos/historico',
      icon: 'assets/img/icons/history-24px.svg',
      ionicon: false,
    },
  ];

  public static CORES_DISCIPLINAS: string[] = [
    'orange',
    'green',
    'accent-1',
    'blue',
    'accent-2',
    'accent-3',
    'accent-4',
    'accent-5',
    'accent-6',
    'accent-7',
    'accent-8',
    'accent-9',
    'accent-10',
    'accent-11',
    'accent-12',
  ];

  public static CORES_EVENTOS: { [key: string]: string } = {
    Curriculares: 'purple',
    Culturais: 'green',
    'Calendário oficial': 'yellow',
    'Eventos externos': 'light-blue',
  };
}
