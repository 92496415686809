import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '../../core/guards/authenticated/authenticated.guard';
import { LoginPage } from './pages/login/login.page';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPage,
    canActivate: [AuthenticatedGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class AuthRoutingModule {}
