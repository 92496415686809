import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { FotoSource, SettingsState, SettingsStore } from './settings.store';

@Injectable({ providedIn: 'root' })
export class SettingsQuery extends QueryEntity<SettingsState, {}> {
  notificacoesAulas$ = this.select((state) => state.notificacoesAulas);
  notificacoesEventos$ = this.select((state) => state.notificacoesEventos);

  interesseDocumentos$ = this.select((state) => state.interesseDocumentos);
  interessePreRequisitos$ = this.select((state) => state.interessePreRequisitos);
  interesseInstagram$ = this.select((state) => state.interesseInstagram);

  fotoSrc$ = this.select((state) => state.fotoSrc);

  constructor(protected store: SettingsStore) {
    super(store);
  }

  fotoSrc(): FotoSource {
    return this.getValue().fotoSrc;
  }
}
