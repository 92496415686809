import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { MetadataService } from './core/services/metadata/metadata.service';

import { IonicStorageModule } from '@ionic/storage';
import { TabsModule } from './modules/tabs/tabs.module';
import { SharedModule } from './shared/shared.module';

import { LocalNotificationsService } from './core/services/local-notifications/local-notifications.service';

import { StorageService } from './core/services/storage/storage.service';

import { Angulartics2Module } from 'angulartics2';

import { AuthModule } from './modules/auth/auth.module';
import { AuthService } from './modules/auth/state/auth.service';

import { GlobalErrorHandlerService } from './core/services/global-error-handler/global-error-handler.service';
import { LoggerService } from './core/services/logger-service/logger-service.service';
import { PushNotificationsService } from './core/services/push-notifications/push-notifications.service';
import { rollbarFactory, RollbarService } from './core/services/rollbar/rollbar.service';
import { CarteirinhaModule } from './modules/carteirinha/carteirinha.module';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({ backButtonText: '' }),
    HttpClientModule,
    SharedModule,
    AuthModule,
    CarteirinhaModule,
    TabsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot({
      gst: { trackingIds: ['G-X1XR2J0DWV'] },
      pageTracking: { autoTrackVirtualPages: false },
    }),
    MarkdownModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    { provide: RollbarService, useFactory: rollbarFactory },
    MetadataService,
    AuthService,
    StorageService,
    PushNotificationsService,
    LocalNotificationsService,
    LoggerService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
