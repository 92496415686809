import { Injectable } from '@angular/core';
import { DashboardStore } from './dashboard.store';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private dashboardStore: DashboardStore) {}

  setMostrarOnboarding(showOnboarding: boolean) {
    this.dashboardStore.update({ showOnboarding });
  }
}
