<div class="e-background-shapes">
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 320 769"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            opacity="0.08">
            <g
                fill-rule="nonzero"
                class="e-background-shapes__stroke-fallback"
                stroke-width="5">
                <g id="bg-formas">
                    <circle
                        class="e-background-shapes__circle-bottom-right"
                        cx="0"
                        cy="0"
                        r="30"></circle>
                    <rect
                        class="e-background-shapes__square-bottom-center"
                        x="0"
                        y="0"
                        width="38"
                        height="38"></rect>
                    <rect
                        class="e-background-shapes__square-top-right"
                        x="0"
                        y="0"
                        width="38"
                        height="38"></rect>
                    <polygon
                        class="e-background-shapes__triangle-center"
                        points="175 288 230 303 192 341"></polygon>
                    <circle
                        class="e-background-shapes__circle-top-left"
                        cx="0"
                        cy="152.5"
                        r="25.5">
                    </circle>
                </g>
            </g>
        </g>
    </svg>

</div>
