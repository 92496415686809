import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isBefore, parseISO } from 'date-fns';
import { finalize } from 'rxjs/operators';
import { ApiError } from '../../../../core/http/api.interface';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { GetAlunoApiResponse } from '../../../../core/http/aluno/aluno-api.interface';
import { Cardapio } from '../../../../core/http/cardapio/cardapio-api.interface';
import { DialogService } from '../../../../core/services/dialog/dialog.service';
import { CardapioService } from '../../../cardapio/state/cardapio.service';

@Component({
  selector: 'app-card-cardapio-dash',
  templateUrl: './card-cardapio-dash.component.html',
  styleUrls: ['./card-cardapio-dash.component.scss'],
})
export class CardCardapioDashComponent implements OnInit {
  @Input() cardapio!: Cardapio;
  @Input() nomeRestaurante: string | undefined = undefined;
  @Input() aluno: GetAlunoApiResponse | undefined = undefined;
  @Input() mostrarVeggies = false;

  @Output() cardClick = new EventEmitter();

  @Input() set totalConfirmados(input: number) {
    this._totalConfirmados = input;
  }

  @Input() set confirmados(input: GetAlunoApiResponse[]) {
    this._confirmados = input;
  }

  get confirmados() {
    return this._confirmados;
  }

  get totalConfirmados() {
    return this._totalConfirmados;
  }

  image: string | undefined = undefined;
  name: string | undefined = undefined;
  veggieIcon = false;

  canConfirm = false;
  jaConfirmado = false;
  carregando = false;
  cardapioCarregando = false;

  private _confirmados: GetAlunoApiResponse[] = [];
  private _totalConfirmados = 0;

  constructor(
    private cardapioService: CardapioService,
    private dialogService: DialogService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.setName();
    this.setImage();
    this.setVeggieIcon();
    this.carregarConfirmados();

    if (isBefore(Date.now(), parseISO(this.cardapio.termino))) {
      this.canConfirm = true;
    }
  }

  async carregarConfirmados() {
    if (!this.cardapio) {
      return;
    }

    this.cardapioCarregando = true;
    this.cardapioService
      .carregarConfirmados(this.cardapio.cardapio_id)
      .pipe(finalize(() => (this.cardapioCarregando = false)))
      .subscribe({
        next: (res) => {
          this._confirmados = res.data;
          this._totalConfirmados = res.data.length;
          this.jaConfirmado = this._confirmados.some((aluno) => aluno.aluno_id === this.aluno?.aluno_id);
        },
        error: (err) => this.dialogService.mostrarErro(err.mensagem),
      });
  }

  public emitCardClick() {
    this.cardClick.emit();
  }

  public confirma() {
    this.carregando = true;
    this.cardapioService
      .confirmarPresenca(this.cardapio.cardapio_id)
      .pipe(finalize(() => (this.carregando = false)))
      .subscribe({
        next: () => {
          this._confirmados = this.aluno ? [...this._confirmados, this.aluno] : this._confirmados;
          this.totalConfirmados += 1;
          this.jaConfirmado = true;
          this.analyticsService.trackEvent(
            'Confirmar Presença Cardápio',
            'Dashboard',
            `Tipo: ${this.cardapio.tipo}`
          );
        },
        error: (err: ApiError) => this.dialogService.mostrarErro(err.mensagem),
      });
  }

  private setName() {
    let main = this.cardapio.pratos.find((prato) => {
      if (this.mostrarVeggies) {
        return prato.tipo === 'principal_vegetariano' || (prato.tipo === 'principal' && prato.vegetariano);
      }

      return prato.tipo === 'principal';
    });

    if (!main) {
      main = this.cardapio.pratos.find((prato) => prato.tipo === 'principal_vegetariano');
    }

    this.name = main?.nome;
  }

  private setImage() {
    switch (this.cardapio.tipo) {
      case 'cafe_da_manha':
        this.image = 'assets/img/illustrations/breakfast.svg';
        break;
      case 'jantar':
        this.image = 'assets/img/illustrations/dinner.svg';
        break;
      case 'almoco':
      default:
        this.image = 'assets/img/illustrations/lunch.svg';
        break;
    }
  }

  private setVeggieIcon() {
    this.veggieIcon = this.cardapio.pratos.filter((prato) => prato.vegetariano).length > 0;
  }
}
