<div
  class="e-evento-preview"
  [class.e-evento-preview--purple]="color === 'purple'"
  [class.e-evento-preview--green]="color === 'green'"
  [class.e-evento-preview--yellow]="color === 'yellow'"
  [class.e-evento-preview--light-blue]="color === 'light-blue'"
  [class.e-evento-preview--dark-blue]="!color"
  [class.e-evento-preview--gray]="desconfirmado">
  <div class="u-relative">
    <ion-icon
      [name]="evento?.categoria?.tipo?.icone"
      class="c-icon c-icon--huge c-icon--bg c-icon--bg--evento u-margin-right e-evento-preview__icon"></ion-icon>
    <ion-icon *ngIf="confirmado" name="thumbs-up-sharp" class="e-evento-preview__like"></ion-icon>
    <ion-icon *ngIf="desconfirmado" name="thumbs-down-sharp" class="e-evento-preview__like"></ion-icon>
  </div>

  <div
    class="u-display--flex u-flex-direction--column u-flex-justify--between u-flex-grow u-padding-right--sm u-margin-top--xxs">
    <h4
      class="e-evento-preview__title u-font-size--md u-font-weight--regular u-text-ellipsis"
      [class.u-gray--400]="desconfirmado">
      {{ evento?.nome }}
    </h4>
    <h5 class="u-font-size--md u-font-weight--regular u-text-ellipsis--short" [class.u-gray--400]="desconfirmado">
      {{ label }}
    </h5>
  </div>

  <div class="u-display--flex u-flex-align--center u-margin-top--xxs">
    <div class="c-date c-date--column">
      <p class="c-date__day e-evento-preview__date">{{ diaInicio }}</p>
      <p class="c-date__month" [class.u-gray--400]="desconfirmado">{{ mesInicio }}</p>
    </div>
    <ion-icon
      name="chevron-forward"
      class="u-margin-left--xs c-icon c-icon--arrow"
      [class.u-gray--400]="desconfirmado"
      >,
    </ion-icon>
  </div>
</div>
