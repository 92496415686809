export class DashboardSettings {
  /* tslint:disable:max-line-length*/
  public static onboardingSlides = [
    {
      image: 'assets/img/illustrations/exams.svg',
      title: 'Nunca mais perca aula',
      description:
        'Deixa que a gente te lembra das suas aulas do dia, sala de aula e faltas. Te ajudamos até a saber o quanto você já progrediu no curso.',
    },
    {
      image: 'assets/img/illustrations/schedule.svg',
      title: 'Festas, provas, trabalhos',
      description:
        'Um calendário colaborativo pra você saber tudo o que acontece na sua universidade. Ah, e o melhor, de quebra você ainda vê quem vai na festa com você!',
    },
    {
      image: 'assets/img/illustrations/celebration.svg',
      title: 'Juntos o semestre todo',
      description:
        'Cardápio, biblioteca, carteira de estudante, histórico e muito mais. O Uniapp te ajuda do começo ao fim do seu curso.',
      button: 'BORA PRO UNIAPP',
      showSkip: false,
    },
  ];
  /* tslint:enable:max-line-length*/
}
