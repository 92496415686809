import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Cardapio, Restaurante } from '../../../core/http/cardapio/cardapio-api.interface';

export interface CardapiosState extends EntityState<{}> {
  cardapios: Cardapio[];
  restaurantes: Restaurante[];
  idRestauranteSelecionado: Restaurante['restaurante_id'] | null;
  mostrarOnboarding: boolean;
  mostrarVeggie: boolean;
}

export function createInitialState(): CardapiosState {
  return {
    cardapios: [],
    restaurantes: [],
    idRestauranteSelecionado: null,
    mostrarOnboarding: true,
    mostrarVeggie: false,
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'cardapios', idKey: 'cardapio_id', resettable: true })
export class CardapiosStore extends EntityStore<CardapiosState, Cardapio> {
  constructor() {
    super(createInitialState());
  }
}
