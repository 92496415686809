import { InjectionToken } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment as env } from '../../../../environments/environment';

/*
  Documentation: https://docs.rollbar.com/docs/angular
 */

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  const { accessToken, environment } = env.rollbar;
  return new Rollbar({
    accessToken,
    environment,
    captureIp: 'anonymize',
  });
}
