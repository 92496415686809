import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private toastController: ToastController, private router: Router) {}

  async mostrarAlerta(mensagem: string): Promise<HTMLIonToastElement> {
    return this.mostrarToast(mensagem, 'c-toast--alert');
  }

  async mostrarInfo(mensagem: string): Promise<HTMLIonToastElement> {
    return this.mostrarToast(mensagem, 'c-toast--info');
  }

  async mostrarSucesso(mensagem: string): Promise<HTMLIonToastElement> {
    return this.mostrarToast(`✅ ${mensagem}`, 'c-toast--success');
  }

  async mostrarErro(mensagem: string): Promise<HTMLIonToastElement> {
    return this.mostrarToast(`❌ ${mensagem}`, 'c-toast--error');
  }

  async mostrarToast(mensagem: string, cssClass: string): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      cssClass,
      message: `${mensagem}     `,
      position: 'top',
      duration: 4000,
      buttons: [
        {
          icon: 'close-outline',
          role: 'cancel',
        },
      ],
    });

    toast.present();

    return toast;
  }

  async mostrarToastRedirect(redirectRoute: string[], message?: string): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      message,
      cssClass: 'c-toast--redirect',
      position: 'top',
      duration: 4000,
      buttons: [{ text: 'Ver', handler: () => this.router.navigate(redirectRoute) }],
    });

    toast.present();

    return toast;
  }
}
