import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface DashboardState {
  isWeekDay: boolean;
  showOnboarding: boolean;
}

export function createInitialState() {
  return { isWeekDay: true, showOnboarding: true };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'dashboard', resettable: true })
export class DashboardStore extends Store<DashboardState> {
  constructor() {
    super(createInitialState());
  }
}
