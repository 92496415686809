import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { isToday, parseISO } from 'date-fns/esm';
import { map } from 'rxjs/operators';
import { Cardapio } from '../../../core/http/cardapio/cardapio-api.interface';
import { CardapiosState, CardapiosStore } from './cardapio.store';

@Injectable({ providedIn: 'root' })
export class CardapioQuery extends QueryEntity<CardapiosState, Cardapio> {
  cardapios$ = this.select((state) => state.cardapios);
  cardapiosHoje$ = this.select((state) => state.cardapios).pipe(
    map((cardapios) => cardapios.filter((c) => isToday(parseISO(c.inicio))))
  );

  restaurantes$ = this.select((state) => state.restaurantes);

  restauranteSelecionado$ = this.select((state) => {
    return state.restaurantes.find((restaurante) => {
      return restaurante.restaurante_id === state.idRestauranteSelecionado;
    });
  });

  idRestauranteSelecionado$ = this.select((state) => {
    return state.idRestauranteSelecionado;
  });

  mostrarVeggie$ = this.select((state) => state.mostrarVeggie);

  constructor(protected store: CardapiosStore) {
    super(store);
  }

  idRestauranteSelecionado() {
    return this.getValue().idRestauranteSelecionado;
  }

  mostrarOnboarding(): boolean {
    return this.getValue().mostrarOnboarding;
  }
}
