import { Injectable } from '@angular/core';
import { RateApp } from 'capacitor-rate-app';

@Injectable({
  providedIn: 'root',
})
export class AppRatingService {
  pedirAvaliacao() {
    RateApp.requestReview();
  }
}
