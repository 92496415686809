import { Injectable } from '@angular/core';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import { Storage } from '@ionic/storage';

import { environment } from '../../../../environments/environment';

/**
 * Serviço intermediário para salvar os dados em localstorage
 *
 * A principio poderia usar o serviço de storage direto do Ionic,
 * mas existe uma grande chance de no futuro precisar fazer otimizações na leitura
 * e escrita dos dados. Então criar um middleware pra isso já facilita um refactor futuro
 *
 * @export
 * @class StorageService
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  akitaStorage;
  constructor(private storage: Storage) {
    if (environment.production) {
      enableAkitaProdMode();
    }
    this.akitaStorage = persistState();
  }

  get(key: string) {
    return this.storage.get(key).then((value) => {
      if (value) {
        return Promise.resolve(JSON.parse(value));
      }
      return Promise.resolve(null);
    });
  }

  save(key: string, value: any) {
    return this.storage.set(key, value);
  }

  clearItem(key: string) {
    return this.storage.remove(key);
  }

  clearAll() {
    this.akitaStorage.clear();
    return this.storage.clear();
  }
}
