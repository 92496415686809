<div
  class="c-card e-card-cardapio-dash"
  [class.e-card-cardapio-dash--breakfast]="cardapio.tipo === 'cafe_da_manha'"
  [class.e-card-cardapio-dash--dinner]="cardapio.tipo === 'jantar'">
  <div class="e-card-cardapio-dash__circle-wrapper e-card-cardapio-dash__circle-wrapper--top">
    <ng-container *ngFor="let i of [].constructor(16)">
      <div class="e-card-cardapio-dash__circle-border e-card-cardapio-dash__circle-border--top"></div>
    </ng-container>
  </div>

  <div class="e-card-cardapio-dash__top" (click)="emitCardClick()">
    <h5 class="c-card__subtitle u-margin-bottom--xs u-text-transform--uppercase">{{ cardapio.tipo_label }}</h5>
    <h3 class="c-card__title e-card-cardapio-dash__text">{{ name }}</h3>
    <h4
      class="e-card-cardapio-dash__text u-font-weight--regular u-text-align--left u-gray--500 u-text-ellipsis u-margin-top--xxs">
      {{ cardapio?.subtitulo }}
    </h4>
    <h5 class="e-card-cardapio-dash__restaurant u-margin-top--xs u-margin-bottom--md" *ngIf="nomeRestaurante">
      RU {{ nomeRestaurante }}
    </h5>

    <div class="e-card-cardapio-dash__img-wrapper">
      <img [src]="image" class="e-card-cardapio-dash__img" />
    </div>
  </div>
  <div class="e-card-cardapio-dash__bottom">
    <ng-container *ngIf="canConfirm">
      <div
        class="u-display--flex u-flex-align--center u-margin-left--xxs"
        *ngIf="!cardapioCarregando; else carregandoConfirma">
        <app-photo-list
          [alunos]="confirmados"
          [counter]="
            confirmados && totalConfirmados > confirmados.length ? totalConfirmados - confirmados.length : 0
          "></app-photo-list>
        <h5 *ngIf="!jaConfirmado" class="e-card-cardapio-dash__link u-margin-left--xs" (click)="confirma()">
          Bora lá?
        </h5>
      </div>
    </ng-container>

    <ng-template #carregandoConfirma>
      <ion-spinner name="dots" class="e-card-cardapio-dash__spinner"></ion-spinner>
    </ng-template>

    <div *ngIf="veggieIcon" class="u-display--flex u-flex-align--center">
      <img class="u-margin-right--xxs" src="assets/img/icons/veggie.svg" />
      <h5 class="u-font-weight--regular u-font-size--xs u-green u-line-height--label">Disponível</h5>
    </div>
  </div>

  <div class="e-card-cardapio-dash__circle-wrapper e-card-cardapio-dash__circle-wrapper--bottom">
    <ng-container *ngFor="let i of [].constructor(16)">
      <div class="e-card-cardapio-dash__circle-border e-card-cardapio-dash__circle-border--bottom"></div>
    </ng-container>
  </div>
</div>
