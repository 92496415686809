<ion-header no-border>
  <ion-toolbar mode="ios" class="c-toolbar c-toolbar--clear">
    <ion-buttons slot="start">
      <ion-back-button mode="ios" defaultHref="/app/perfil" icon="arrow-back"></ion-back-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-icon
        name="checkmark-done"
        class="c-toolbar__icon c-toolbar__icon--pink"
        (click)="abrirValidade()"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="l-container l-container--white e-carteirinha-page u-padding-top">
    <app-background-shapes></app-background-shapes>
    <section class="u-flex-direction--column u-display--flex u-flex-align--center u-text-align--center">
      <div class="e-carteirinha-page__photo u-margin-bottom">
        <img [src]="fotoUrl" />
      </div>

      <h1 class="u-width--66 u-font-size--xl">{{aluno?.nome}}</h1>
      <h2 class="u-width--66 u-margin-top--xs">{{curso?.curso?.nome}}</h2>
      <h4 class="u-gray--600 u-margin-top--sm">{{aluno?.campus?.instituicao?.nome}}</h4>
    </section>

    <section class="c-card u-bg-gray--100 u-margin-top--md u-zindex">
      <div class="c-table">
        <div class="c-table__row">
          <ion-icon name="person" class="c-icon u-gray--700 u-margin-right"></ion-icon>
          <h5 class="c-table__label">Matrícula</h5>
          <p class="c-table__value">{{aluno?.matricula}}</p>
        </div>
        <ng-container>
          <div class="c-table__row" *ngIf="aluno?.cpf">
            <ion-icon name="finger-print" class="c-icon u-gray--700 u-margin-right"></ion-icon>
            <h5 class="c-table__label">CPF</h5>
            <p class="c-table__value">{{ aluno?.cpf }}</p>
          </div>
        </ng-container>
        <ng-container *ngIf="aluno?.rg">
          <div class="c-table__row">
            <ion-icon name="id-card" class="c-icon u-gray--700 u-margin-right"></ion-icon>
            <h5 class="c-table__label">RG</h5>
            <p class="c-table__value">{{ aluno?.rg }}</p>
          </div>
          <div *ngIf="aluno?.rg_estado" class="c-table__row">
            <ion-icon name="compass" class="c-icon u-gray--700 u-margin-right"></ion-icon>
            <h5 class="c-table__label">Estado</h5>
            <p class="c-table__value">{{ aluno?.rg_estado }}</p>
          </div>
          <div *ngIf="aluno?.rg_expedido_em" class="c-table__row">
            <ion-icon name="today" class="c-icon u-gray--700 u-margin-right"></ion-icon>
            <h5 class="c-table__label">Expedido em</h5>
            <p class="c-table__value">{{ expedidoEm }}</p>
          </div>
          <div *ngIf="aluno?.rg_orgao_expedidor" class="c-table__row">
            <ion-icon name="shield-checkmark" class="c-icon u-gray--700 u-margin-right"></ion-icon>
            <h5 class="c-table__label">Órgão expedidor</h5>
            <p class="c-table__value">{{ aluno?.rg_orgao_expedidor }}</p>
          </div>
        </ng-container>
        <div class="c-table__row">
          <ion-icon name="calendar" class="c-icon u-gray--700 u-margin-right"></ion-icon>
          <h5 class="c-table__label">Válido até</h5>
          <p class="c-table__value">{{validade}}</p>
        </div>
      </div>
    </section>

    <section
      class="u-display--flex u-flex-align--center u-flex-direction--column u-flex-justify--end u-flex-grow u-margin-top--lg u-zindex">
      <img [src]="aluno?.campus?.instituicao?.brasao_url" class="e-carteirinha-page__brasao" />
      <img class="e-carteirinha-page__barcode u-margin-top--md" [src]="aluno?.cracha_barcode_url" />
      <small class="u-margin-top--xxs">{{aluno?.matricula}}</small>
    </section>
  </div>
</ion-content>
