/* eslint-disable @typescript-eslint/no-unused-vars */
export default class DateUtilities {
  static mesesCompletos = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  static mesesAbreviados = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

  static diasSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  static diasSemanaAbr = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  static diasSemanaCom = [
    'domingo',
    'segunda-feira',
    'terça-feira',
    'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado',
  ];

  static getTimeWithoutISO(date: string) {
    if (!date) {
      return null;
    }

    const fullTime = date.split('T')[1].split('.')[0];
    const timeWihtoutZ = fullTime.split('Z')[0];
    const [hour, minutes, ...seconds] = timeWihtoutZ.split(':');

    return `${hour}:${minutes}`;
  }

  static getDateWithoutISO(date: string) {
    if (!date) {
      return null;
    }

    const fulldate = date.split('T')[0];
    const [year, month, day] = fulldate.split('-');

    return `${day}/${month}`;
  }

  static getDayWithoutISO(date: string) {
    const fulldate = date.split('T')[0];
    const [year, month, day] = fulldate.split('-');

    return `${day}`;
  }

  static getMonthWithoutISO(date: string) {
    const fulldate = date.split('T')[0];
    const [year, month, day] = fulldate.split('-');

    return `${month}`;
  }

  static getDiaSemana() {
    return this.diasSemanaCom[new Date().getDay()];
  }

  /**
   * Retorna uma string de "bom dia" dependendo do horario do dia
   *
   * @static
   * @memberof DateUtilities
   */
  static getMensagemBomPeriodoDia() {
    let mensagem = 'Bom dia';

    const horaAtual = new Date().getHours();
    if (horaAtual >= 12) {
      mensagem = horaAtual >= 19 ? 'Boa noite' : 'Boa tarde';
    }

    return mensagem;
  }
}
