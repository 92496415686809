import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonicSlides, ModalController } from '@ionic/angular';
import { Observable, Subscription, filter, switchMap } from 'rxjs';
import SwiperCore, { Pagination, Swiper, SwiperOptions } from 'swiper';
import differenceInDays from 'date-fns/differenceInDays';
import { startOfDay } from 'date-fns';

import { environment } from '../../../../../environments/environment';
import { GetAlunoApiResponse, GetAlunoMatrizApiResponse } from '../../../../core/http/aluno/aluno-api.interface';
import { Cardapio } from '../../../../core/http/cardapio/cardapio-api.interface';
import { ExtendedEvento } from '../../../../core/http/evento/evento-api.interface';
import { GradeHorariaAula } from '../../../../core/services/aluno/aluno.interface';
import { AlunoService } from '../../../../core/services/aluno/aluno.service';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { AppRatingService } from '../../../../core/services/app-rating/app-rating.service';
import { DialogService } from '../../../../core/services/dialog/dialog.service';
import { LogoutService } from '../../../../core/services/logout/logout-service.service';
import { MetadataQuery } from '../../../../core/services/metadata/metadata.query';
import { MetadataService } from '../../../../core/services/metadata/metadata.service';
import { StatusBarService } from '../../../../core/services/status-bar/status-bar.service';
import { OnboardingSlidesComponent } from '../../../../shared/modals/onboarding-slides/onboarding-slides.component';
import DateUtilities from '../../../../shared/utilities/date.utilities';
import { AlunoQuery } from '../../../aluno/state/aluno.query';
import { CardapioQuery } from '../../../cardapio/state/cardapio.query';
import { CardapioService } from '../../../cardapio/state/cardapio.service';
import { EventoService } from '../../../eventos/state/evento.service';
import { DashboardQuery } from '../../state/dashboard.query';
import { DashboardService } from '../../state/dashboard.service';
import { DashboardStore } from '../../state/dashboard.store';
import { DashboardSettings } from './dashboard.settings';
import { AlunoStore } from '../../../../modules/aluno/state/aluno.store';

SwiperCore.use([Pagination, IonicSlides]);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit, OnDestroy {
  @ViewChild('container') container: ElementRef<HTMLDivElement> | undefined = undefined;
  @ViewChild('menu') menu: ElementRef<HTMLDivElement> | undefined = undefined;
  aulasHoje$: Observable<GradeHorariaAula[]> | undefined = undefined;
  cardapiosHoje$: Observable<Cardapio[]> | undefined = undefined;
  eventosHoje$: Observable<ExtendedEvento[]> | undefined = undefined;
  cursoAluno$: Observable<GetAlunoMatrizApiResponse | undefined> | undefined = undefined;
  showOnboarding$: Observable<boolean> | undefined = undefined;
  mostrarVeggies$: Observable<boolean> | undefined = undefined;
  cardapioConfirmados: GetAlunoApiResponse[] = [];
  cardapios: Cardapio[] = [];
  eventosHoje: ExtendedEvento[] = [];

  aluno: GetAlunoApiResponse | undefined = undefined;
  nomeAluno: string | undefined = undefined;
  matriculaAluno: string | undefined = undefined;
  diaHoje: string | undefined = undefined;
  firstAccessTime!: Date;

  nomeRestaurante: string | undefined = undefined;

  userHasReviewed: boolean = false;
  /**
   * Is set to true if user is logged in for more days than the number stored in loginPeriodForReview
   */
  reachedLoginPeriodForReview: boolean = false;
  shouldDisplayReviewCard: boolean = false;

  slideOptions: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
  };
  onboardingSlides = DashboardSettings.onboardingSlides;

  cardapiosSemana$: Observable<Cardapio[]> | undefined = undefined;

  headerOpen = false;

  hasRestaurantesFuncionalidade = false;

  private startScroll: number = 0;
  private pageTitle = 'Dashboard';
  private pagePath = 'app/dashboard';

  private alunoSubscription: Subscription | undefined | null = undefined;
  private cardapioSubscription: Subscription | undefined | null = undefined;
  private eventosHojeSubscription: Subscription | undefined | null = undefined;
  private cardapioSwiper: Swiper | undefined = undefined;

  /**
   * Number in days since firstAcessTime
   * that must have passed for an user to see the review cards
   */
  private loginPeriodForReview: number = environment.loginPeriodForReview;

  constructor(
    private dialogService: DialogService,
    private metadataService: MetadataService,
    private metadataQuery: MetadataQuery,
    private dashboardService: DashboardService,
    private logoutService: LogoutService,
    private dashboardQuery: DashboardQuery,
    private dashboardStore: DashboardStore,
    private alunoService: AlunoService,
    private alunoQuery: AlunoQuery,
    private cardapioQuery: CardapioQuery,
    private cardapioService: CardapioService,
    private router: Router,
    private eventoService: EventoService,
    private analyticsService: AnalyticsService,
    private appRate: AppRatingService,
    private modalController: ModalController,
    private statusbarService: StatusBarService,
    private ref: ChangeDetectorRef,
    private alunoStore: AlunoStore
  ) {}

  ngOnInit() {
    this.openOnboardingModal();

    this.alunoSubscription = this.alunoQuery.aluno$.subscribe((aluno) => {
      if (!!aluno) {
        this.aluno = aluno;
        this.nomeAluno = aluno.nome?.split(' ')[0] || '';
        this.matriculaAluno = aluno.matricula;
      }
    });

    this.cardapioSubscription = this.dashboardQuery.cardapiosDisponiveisHoje$.subscribe((cardapios) => {
      this.cardapios = cardapios;
    });

    this.eventosHojeSubscription = this.dashboardQuery.eventosHoje().subscribe((eventos) => {
      this.eventosHoje = eventos;
      return this.eventosHoje;
    });

    this.cursoAluno$ = this.alunoQuery.cursoAtual$;
    this.mostrarVeggies$ = this.cardapioQuery.mostrarVeggie$;

    this.diaHoje = DateUtilities.getDiaSemana();

    this.aulasHoje$ = this.dashboardQuery.gradeHoje();
    this.cardapiosHoje$ = this.dashboardQuery.cardapiosDisponiveisHoje$;
    this.cardapiosSemana$ = this.dashboardQuery.cardapiosSemana();

    const currentDay = startOfDay(new Date());
    this.firstAccessTime = new Date(this.alunoQuery.firstAccessTime());

    this.setReachedLoginPeriodForReview(this.firstAccessTime, currentDay);

    this.userHasReviewed = this.alunoQuery.userHasReviewed();
    this.reachedLoginPeriodForReview = this.alunoQuery.checkReachedLoginPeriodForReview();
    this.shouldDisplayReviewCard = !this.userHasReviewed && this.reachedLoginPeriodForReview;
  }

  ngOnDestroy() {
    if (this.alunoSubscription) {
      this.alunoSubscription.unsubscribe();
      this.alunoSubscription = null;
    }

    if (this.cardapioSubscription) {
      this.cardapioSubscription.unsubscribe();
      this.alunoSubscription = null;
    }

    if (this.eventosHojeSubscription) {
      this.eventosHojeSubscription.unsubscribe();
      this.eventosHojeSubscription = null;
    }
  }

  ionViewWillEnter() {
    this.statusbarService.setPink();
    const campus = this.metadataQuery.campus();
    if (campus) {
      this.metadataService.carregarCampus(campus.campus_id);
    }

    this.alunoService.loadHistoricoAndGrade().subscribe();
    this.eventoService.carregarEventosCurso().subscribe();
    this.loadRestauranteInfo();
  }

  ionViewDidEnter() {
    this.analyticsService.trackPageView(this.pageTitle, this.pagePath);
  }

  ionViewWillLeave() {
    this.headerOpen = false;
  }

  setSwiperInstance(swiper: Swiper) {
    this.cardapioSwiper = swiper;
  }

  toggleHeader(ev?: Event) {
    this.analyticsService.trackEvent('toggle-header', 'dashboard-mask');
    if (ev) {
      ev.stopPropagation();
    }

    this.headerOpen = !this.headerOpen;
    if (this.headerOpen) {
      if (!this.container) return;
      this.container.nativeElement.scrollTo(0, 0);
      this.startScroll = 0;
    }
  }

  collapseHeader() {
    this.analyticsService.trackEvent('collapse-header', 'configuracoes-page');
    if (this.headerOpen) {
      this.headerOpen = false;
    }
  }

  abrirPerfil() {
    this.analyticsService.trackEvent('abrir-perfil', 'dashboard');
    this.router.navigate(['/app/perfil']);
  }

  abrirCarteirinha() {
    this.analyticsService.trackEvent('abrir-carteirinha', 'dashboard');
    // carteirinha is out of ion-tabs
    this.headerOpen = false;
    this.router.navigate(['/carteirinha']);
  }

  abrirConfiguracoes() {
    this.analyticsService.trackEvent('abrir-settings', 'dashboard');
    this.router.navigate(['/app/configuracoes']);
  }

  logout() {
    this.analyticsService.trackEvent('logout', 'dashboard');
    this.logoutService.logout();
  }

  abrirCardapio(cardapio: Cardapio) {
    this.analyticsService.trackEvent('dashboard', 'cardapios', cardapio.tipo);
    this.router.navigate(['/app/cardapios'], {
      queryParams: { cardapio_id: cardapio.cardapio_id },
    });
  }

  abrirEvento(evento: ExtendedEvento) {
    this.analyticsService.trackEvent('abrir-evento', 'dashboard');
    this.router.navigate(['/app/eventos/info', evento.evento_id]);
  }

  abrirDisciplina(historico: GradeHorariaAula) {
    this.analyticsService.trackEvent('abrir-disciplina', 'dashboard');
    this.router.navigate(['/app/disciplinas', historico.historico_id]);
  }

  abrirAvaliacaoLoja() {
    this.alunoStore.update((data) => ({
      ...data,
      userActivity: {
        ...data.userActivity,
        userHasReviewed: true,
      },
    }));
    this.appRate.pedirAvaliacao();
  }

  fecharOnboarding() {
    this.dashboardStore.update({ showOnboarding: false });
  }

  changeCardapio() {
    this.analyticsService.trackEvent('trocar-de-slide-de-cardapio', 'dashboard');
  }

  mouseUp(ev: TouchEvent) {
    if (!this.headerOpen) {
      return;
    }

    const currentScroll = ev.changedTouches[0].clientY;
    const scrollDiff = this.startScroll - currentScroll;

    if (this.headerOpen && scrollDiff > 100) {
      this.headerOpen = false;
    }
  }

  mouseDown(ev: TouchEvent) {
    this.startScroll = ev.touches[0].clientY;
  }

  private async openOnboardingModal() {
    const show = this.dashboardQuery.showOnboarding();
    if (!show) {
      return;
    }

    this.dashboardService.setMostrarOnboarding(false);
    const modal = await this.modalController.create({
      component: OnboardingSlidesComponent,
      cssClass: 'c-ion-modal--full',
      componentProps: {
        slides: this.onboardingSlides,
        style: 'page',
      },
    });

    this.statusbarService.setWhite();
    modal.onDidDismiss().then(() => this.statusbarService.setPink());
    return modal.present();
  }

  /**
   * Load information about Restaurantes and Cardapios,
   * but only the user's Campu has this feature
   */
  private loadRestauranteInfo() {
    this.hasRestaurantesFuncionalidade = this.metadataQuery.isFuncionalidadeActive('restaurantes');
    if (!this.hasRestaurantesFuncionalidade) {
      return;
    }

    this.cardapioService
      .carregarRestaurantes()
      .pipe(switchMap(() => this.cardapioService.carregarCardapios()))
      .subscribe();

    this.cardapioQuery.restauranteSelecionado$.pipe(filter((restaurante) => !!restaurante)).subscribe({
      next: (restaurante) => {
        this.nomeRestaurante = restaurante!.nome;
      },
    });
  }

  /**
   * Checks if difference between currentDay and firstAccessTime is greater than loginPeriodForReview
   */
  setReachedLoginPeriodForReview(firstAccessTime: Date, currentDay: Date) {
    if (this.alunoQuery.checkReachedLoginPeriodForReview()) return;
    const daysSinceFirstAccess = differenceInDays(startOfDay(currentDay), startOfDay(firstAccessTime));
    this.alunoStore.update((data) => ({
      ...data,
      userActivity: {
        ...data.userActivity,
        reachedLoginPeriodForReview: daysSinceFirstAccess >= this.loginPeriodForReview,
      },
    }));
  }
}
