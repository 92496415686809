<ion-app>
  <!-- min width to ensure sidebar will always be hidden on mobile devices -->
  <ion-split-pane contentId="main" class="c-sidemenu" when="(min-width: 9999px)">
    <ion-menu contentId="main" type="reveal" (ionWillOpen)="toggleMenuClass()" (ionWillClose)="toggleMenuClass()">
      <ion-content>
        <ion-row class="c-sidemenu__section">
          <div class="u-display--flex u-flex-direction--column u-margin-top--lg u-margin-left">
            <ion-menu-toggle auto-hide="false">
              <div [routerLink]="['/app/perfil']" [routerDirection]="'root'">
                <div class="c-avatar c-avatar--large u-margin-bottom">
                  <img [src]="aluno | avatar : 'medium'" />
                </div>
                <h2 class="c-sidemenu__username u-font-size--xl">{{ aluno?.nome }}</h2>
              </div>
            </ion-menu-toggle>

            <div class="u-margin-top--xxs u-margin-bottom--xs">
              <app-basic-select
                class="c-sidemenu__select"
                *ngIf="opcoesCursos.length > 1"
                [options]="opcoesCursos"
                (change)="selecionaCurso($event)"
                [selectedValue]="(idMatrizSelecionada$ | async)!"
                styled="clear">
              </app-basic-select>
            </div>
          </div>
        </ion-row>
        <ion-list class="c-sidemenu__section">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-row
              class="c-sidemenu__row u-display--flex u-flex-align--center"
              [routerDirection]="'root'"
              [routerLink]="[p.url]"
              routerLinkActive="c-sidemenu__row--active">
              <ion-icon
                class="c-sidemenu__icon u-margin-right--md"
                [name]="p.icon"
                [attr.src]="!p.ionicon ? p.icon : null"></ion-icon>
              <div>{{ p.title }}</div>
            </ion-row>
          </ion-menu-toggle>
        </ion-list>
        <ion-list class="c-sidemenu__section">
          <ion-menu-toggle auto-hide="false">
            <ion-row
              class="c-sidemenu__row u-display--flex u-flex-align--center"
              [routerLink]="['/app/configuracoes']"
              routerLinkActive="c-sidemenu__row--active">
              <ion-icon class="c-sidemenu__icon u-margin-right--md" name="cog"></ion-icon>
              <div>Configurações</div>
            </ion-row>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
