<div class="c-radial-progress" [class.c-radial-progress--rotate]="progress <= 1">
    <svg
    *ngIf="progress <= 1; else warningIcon"
    [attr.width]="radius * 2"
    [attr.height]="radius * 2">
      <circle
        [attr.cx]="radius"
        [attr.cy]="radius"
        [attr.r]="radius * 0.75"
        [attr.stroke-width]="radius * 0.25"
        class="c-radial-progress__bg"
        fill="none" />
      <circle
        [attr.cx]="radius"
        [attr.cy]="radius"
        [attr.r]="radius * 0.75"
        [attr.stroke-width]="radius * 0.3"
        [attr.stroke-dasharray]="(radius * 0.75) * 2 * 3.1415"
        [attr.stroke-dashoffset]="(radius * 0.75) * 2 * 3.1415 * (1 - progress)"
        class="c-radial-progress__main"
        fill="none" />
    </svg>

    <ng-template #warningIcon>
      <ion-icon name="warning" class="c-icon c-icon--large u-red"></ion-icon>
    </ng-template>
</div>