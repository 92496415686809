import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthQuery } from '../../../modules/auth/state/auth.query';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authQuery: AuthQuery, private router: Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(this.router.url);
  }

  /**
   * Checks if the user is Authenticated and redirect to login if it is not
   *
   * @param {string} url
   * @returns {Promise<boolean>}
   * @memberof AuthGuard
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkLogin(url: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.authQuery.getValue().auth) {
        resolve(true);
      } else {
        // TODO: Save url on Auth Service to redirect after login
        this.router.navigate(['/login']);
        reject(false);
      }
    });
  }
}
