import { Component, Input, OnInit } from '@angular/core';
import { AppRatingService } from '../../../core/services/app-rating/app-rating.service';
import { AlunoStore } from '../../../modules/aluno/state/aluno.store';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [style({ transform: 'translateY(100%)', opacity: 0 }), animate('150ms ease-in')]),
      transition('* => void', [animate('150ms ease-out', style({ transform: 'translateY(100%)', opacity: 0 }))]),
    ]),
  ],
})
export class ReviewCardComponent implements OnInit {
  @Input() variation: 'evento' | 'dashboard' = 'dashboard';

  visible: boolean = true;
  description!: string;
  title!: string;

  constructor(private alunoStore: AlunoStore, private appRate: AppRatingService) {}

  ngOnInit() {
    switch (this.variation) {
      case 'dashboard':
        this.title = 'Curtindo o app?';
        this.description = 'Bora avaliar a sua experiência com o app? É rapidinho!';
        break;
      case 'evento':
        this.title = 'Gostou?';
        this.description = 'Se você está curtindo usar o app que tal avaliar na loja?';
        break;
    }
  }

  public hideReviewCard() {
    this.visible = false;
  }

  abrirAvaliacaoLoja() {
    if (this.variation === 'evento') {
      this.hideReviewCard();
    }
    this.alunoStore.update((data) => ({
      ...data,
      userActivity: {
        ...data.userActivity,
        userHasReviewed: true,
      },
    }));
    this.appRate.pedirAvaliacao();
  }
}
