import { Store, StoreConfig } from '@datorama/akita';
import { GradeHorariaTurno } from '../../../core/http/aluno/aluno-api.interface';
import { Injectable } from '@angular/core';

export interface GradeGridState {
  gradeHoraria: GradeHorariaTurno[];
  showTooltip: boolean;
}

export function createInitialState(): GradeGridState {
  return {
    gradeHoraria: [],
    showTooltip: true,
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'gradeHorariaGrid', resettable: true })
export class GradeGridStore extends Store<GradeGridState> {
  constructor() {
    super(createInitialState());
  }
}
