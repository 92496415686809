<div class="c-modal c-modal--short c-modal--center u-flex-justify--between e-termos-page">
  <section class="u-display--flex u-flex-align--center u-flex-direction--column e-termos-page__content">
    <ion-icon class="c-modal__close" name="close" (click)="dismiss()"></ion-icon>
    <ion-icon class="c-modal__icon" name="checkmark-done"></ion-icon>
    <h2 class="c-modal__title">Termos de Uso</h2>
    <div class="u-margin-top--xs">
      <h3 class="u-font-size--xl u-margin-top">Funcionamento</h3>
      <p class="u-font-size--md">
        O Uniapp é um aplicativo utilizado para acessar as informações acadêmicas de instituições previamente
        cadastradas. Por não ser um aplicativo oficial da instituição, o Uniapp não garante o funcionamento
        integral ou parcial de suas funcionalidades.
      </p>

      <p class="u-font-size--md">
        Para obter as informações exibidas dentro do aplicativo, o aluno deve fornecer ao Uniapp, de forma
        voluntária, as suas credenciais acadêmicas. Estas credenciais são as mesmas utilizadas para se autenticar
        no sistema acadêmico ou portal do aluno da instituição. Estas credenciais são utilizadas pelo sistema do
        Uniapp para acessar, extrair, organizar e armazenar as informações acadêmicas do aluno presentes no sistema
        acadêmico de sua instituição.
      </p>
      <h3 class="u-font-size--xl u-margin-top">Informações Coletadas</h3>
      <p class="u-font-size--md">
        O Uniapp coleta e salva as informações presentes no portal do aluno, ou similar, de sua instituição. As
        informações coletadas são para poder fornecer de forma ágil e padronizada as funcionalidades básicas do
        aplicativo.
      </p>
      <p class="u-font-size--md">
        O Uniapp não salva a senha do aluno em seus sistemas, exceto em casos de erro ao importar os dados, onde
        ela é salva de forma criptografada em um log de erros a parte.
      </p>
      <h3 class="u-font-size--xl u-margin-top">Utilização das informações coletadas</h3>
      <p class="u-font-size--md">
        As informações coletadas pelo Uniapp são utilizadas exclusivamente para oferecer as funcionalidades do
        aplicativo aos alunos. Não exportamos, vendemos ou compartilhamos quaisquer dados do aluno com terceiros ou
        empresas.
      </p>

      <h3 class="u-font-size--xl u-margin-top">Dados de uso do aplicativo</h3>
      <p class="u-font-size--md">
        O Uniapp utiliza o Google Analytics para coletar dados de uso do aplicativo. Estes dados são utilizados
        para analisar e melhorar o próprio aplicativo. Não é compartilhado nenhum dado pessoal com o Google
        Analytics.
      </p>

      <h3 class="u-font-size--xl u-margin-top">Contato</h3>
      <p class="u-font-size--md">
        As perguntas sobre os Termos de serviço devem ser enviadas para nós através do
        <a href="mailto:contato@carbonaut.io">contato@carbonaut.io</a>
      </p>
    </div>
  </section>
</div>
