import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth/auth.guard';

import { DashboardPage } from '../dashboard/page/dashboard/dashboard.page';
import { TabsPage } from './pages/tabs.page';

const routes: Routes = [
  {
    path: 'app',
    component: TabsPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'perfil',
        children: [
          {
            path: '',
            loadChildren: () => import('../aluno/aluno.module').then((m) => m.AlunoModule),
          },
        ],
      },
      {
        path: 'biblioteca',
        redirectTo: 'dashboard',
        // children: [
        //   {
        //     path: '',
        //     loadChildren: () => import('../biblioteca/biblioteca.module').then((m) => m.BibliotecaModule),
        //   },
        // ],
      },
      {
        path: 'cardapios',
        children: [
          {
            path: '',
            loadChildren: () => import('../cardapio/cardapio.module').then((m) => m.CardapioModule),
          },
        ],
      },
      {
        path: 'dashboard',
        component: DashboardPage,
      },
      {
        path: 'disciplinas',
        children: [
          {
            path: '',
            loadChildren: () => import('../historicos/disciplinas.module').then((m) => m.DisciplinasModule),
          },
        ],
      },
      {
        path: 'historicos',
        children: [
          {
            path: '',
            loadChildren: () => import('../historicos/disciplinas.module').then((m) => m.DisciplinasModule),
          },
        ],
      },
      {
        path: 'eventos',
        children: [
          {
            path: '',
            loadChildren: () => import('../eventos/eventos.module').then((m) => m.EventosModule),
          },
        ],
      },
      {
        path: 'configuracoes',
        children: [
          {
            path: '',
            loadChildren: () => import('../settings/settings.module').then((m) => m.SettingsModule),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class TabsRoutingModule {}
