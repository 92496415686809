import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { format } from 'date-fns';
import { Subscription, filter } from 'rxjs';
import { AvatarPipe } from '../../../../../../src/app/shared/pipes/avatar.pipe';
import { GetAlunoApiResponse, GetAlunoMatrizApiResponse } from '../../../../core/http/aluno/aluno-api.interface';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { StatusBarService } from '../../../../core/services/status-bar/status-bar.service';
import { AlunoQuery } from '../../../aluno/state/aluno.query';
import { ValidadePage } from '../validade/validade.page';

@Component({
  selector: 'app-carteirinha',
  templateUrl: './carteirinha.page.html',
  styleUrls: ['./carteirinha.page.scss'],
})
export class CarteirinhaPage implements OnDestroy, OnInit {
  public aluno: GetAlunoApiResponse | undefined = undefined;
  public curso: GetAlunoMatrizApiResponse | undefined = undefined;
  public validade: string | undefined = undefined;
  public expedidoEm: string | undefined = undefined;
  public fotoUrl: string | undefined = undefined;

  private pageTitle = 'Carteirinha';
  private pagePath = '/carteirinha';
  private alunoSubscription: Subscription | undefined | null = undefined;
  private cursoSubscription: Subscription | undefined | null = undefined;

  constructor(
    private alunoQuery: AlunoQuery,
    private analyticsService: AnalyticsService,
    private modalController: ModalController,
    private statusbarService: StatusBarService,
    private avatarPipe: AvatarPipe
  ) {
    this.alunoSubscription = this.alunoQuery.aluno$.pipe(filter((aluno) => !!aluno)).subscribe((aluno) => {
      this.aluno = aluno!;
      this.fotoUrl = this.getFotoUrl();
      this.validade = format(new Date(aluno!.cracha_validade), 'dd/MM/yyyy');
      this.expedidoEm = aluno!.rg_expedido_em ? format(new Date(aluno!.rg_expedido_em), 'dd/MM/yyyy') : undefined;
    });

    this.cursoSubscription = this.alunoQuery.cursoAtual$.pipe(filter((curso) => !!curso)).subscribe((curso) => {
      this.curso = curso!;
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.alunoSubscription) {
      this.alunoSubscription.unsubscribe();
      this.alunoSubscription = null;
    }

    if (this.cursoSubscription) {
      this.cursoSubscription.unsubscribe();
      this.cursoSubscription = null;
    }
  }

  ionViewWillEnter() {
    this.statusbarService.setWhite();
  }

  ionViewDidEnter() {
    this.analyticsService.trackPageView(this.pageTitle, this.pagePath);
  }

  public async abrirValidade() {
    const modal = await this.modalController.create({
      component: ValidadePage,
      cssClass: 'c-ion-modal--short',
    });

    return modal.present().then(() => {
      this.analyticsService.trackEvent('abrir-modal-de-validacao', 'carteirinha');
    });
  }

  ionViewDidLeave() {
    this.statusbarService.setPink();
  }

  private getFotoUrl(): string | undefined {
    if (this.aluno?.cracha_foto_url) {
      return this.aluno.cracha_foto_url;
    }
    if (!this.aluno) return;
    return this.avatarPipe.transform(this.aluno, 'medium');
  }
}
