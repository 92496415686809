import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Cardapio, Restaurante } from '../../../core/http/cardapio/cardapio-api.interface';
import { CardapioApiService } from '../../../core/http/cardapio/cardapio-api.service';
import { AlunoQuery } from '../../aluno/state/aluno.query';
import { CardapioQuery } from './cardapio.query';
import { CardapiosStore } from './cardapio.store';

@Injectable({
  providedIn: 'root',
})
export class CardapioService {
  constructor(
    private alunoQuery: AlunoQuery,
    private cardapioApi: CardapioApiService,
    private cardapioQuery: CardapioQuery,
    private cardapioStoreAkita: CardapiosStore
  ) {}

  carregarCardapios() {
    const id = this.cardapioQuery.getValue().idRestauranteSelecionado;
    if (id === null) {
      return of<Cardapio[]>([]);
    }

    this.cardapioStoreAkita.setLoading(true);

    return this.cardapioApi.getCardapios(id).pipe(
      tap((cardapios) => {
        this.cardapioStoreAkita.update({ cardapios });
      }),
      finalize(() => {
        this.cardapioStoreAkita.setLoading(false);
      })
    );
  }

  carregarRestaurantes() {
    const campusId = this.alunoQuery.getValue().aluno?.campus.campus_id;
    if (!campusId) {
      return of<Restaurante[]>([]);
    }

    return this.cardapioApi.getRestaurantes(campusId).pipe(
      tap((restaurantes) => {
        let idRestauranteSelecionado = this.cardapioQuery.getValue().idRestauranteSelecionado;
        if (idRestauranteSelecionado === null && restaurantes && restaurantes.length > 0) {
          idRestauranteSelecionado = restaurantes[0].restaurante_id;
        }

        if (idRestauranteSelecionado) {
          this.cardapioStoreAkita.update({ restaurantes, idRestauranteSelecionado });
        }
      })
    );
  }

  carregarConfirmados(cardapio_id: Cardapio['cardapio_id']) {
    return this.cardapioApi.getConfirmados(cardapio_id);
  }

  confirmarPresenca(cardapio_id: Cardapio['cardapio_id']) {
    return this.cardapioApi.putConfirma(cardapio_id);
  }

  desconfirmarPresenca(cardapio_id: Cardapio['cardapio_id']) {
    return this.cardapioApi.deleteDesconfirma(cardapio_id);
  }

  updateRestauranteSelecionado(idRestauranteSelecionado: Restaurante['restaurante_id']) {
    this.cardapioStoreAkita.update({ idRestauranteSelecionado });
  }

  updateMostrarVeggie(mostrarVeggie: boolean) {
    this.cardapioStoreAkita.update({ mostrarVeggie });
  }

  setOnBoarding(mostrarOnboarding: boolean) {
    this.cardapioStoreAkita.update({ mostrarOnboarding });
  }
}
