import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabsService {
  showTabs$: Observable<boolean>;

  private showSubject = new Subject<boolean>();
  private showTabs = true;

  constructor() {
    this.showTabs$ = this.showSubject.asObservable();
  }

  setShowTabs(show: boolean) {
    this.showTabs = show;
    this.showSubject.next(this.showTabs);
  }
}
