import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { SendCardapioModalComponent } from '../../../cardapio/pages/send-cardapio-modal/send-cardapio-modal.component';

@Component({
  selector: 'app-card-cardapio-empty-dash',
  templateUrl: './card-cardapio-empty-dash.component.html',
  styleUrls: ['./card-cardapio-empty-dash.component.scss'],
})
export class CardCardapioEmptyDashComponent implements OnInit {
  @Input() weekEmpty: boolean = false;
  public title = 'Vish! Esqueceram o cardápio ';
  public borderArray = [].constructor(16);

  constructor(private modalController: ModalController, private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.title += this.weekEmpty ? 'da semana!' : 'de hoje!';
  }

  async openSendCardapioModal() {
    const modal = await this.modalController.create({
      component: SendCardapioModalComponent,
      cssClass: 'c-ion-modal--auto',
    });

    await modal.present().then(() => {
      this.analyticsService.trackEvent('enviar-cardapio-modal', 'dashboard');
    });
  }
}
