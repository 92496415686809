import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { OpenedPush } from './push-api.interface';

@Injectable({
  providedIn: 'root',
})
export class PushApiService {
  constructor(private http: HttpClient) {}

  putPush(openedPush: OpenedPush, pushId: number) {
    return this.http.put(`${environment.api}/push/${pushId}`, openedPush);
  }
}
