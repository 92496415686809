import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { InstagramVoteState } from '../../../modules/aluno/pages/instagram-voto/instagram-voto.page';

export type FotoSource = 'camera' | 'instagram';

export interface SettingsState extends EntityState<{}> {
  notificacoesAulas: boolean;
  notificacoesEventos: boolean;
  interesseDocumentos: boolean;
  interessePreRequisitos: boolean;
  interesseInstagram: InstagramVoteState;
  fotoSrc: FotoSource;
}
export function createInitialState() {
  return {
    notificacoesAulas: true,
    notificacoesEventos: true,
    interesseDocumentos: false,
    interessePreRequisitos: false,
    interesseInstagram: 'indeterminate' as InstagramVoteState,
    fotoSrc: 'camera' as FotoSource,
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'settings', resettable: true })
export class SettingsStore extends EntityStore<SettingsState, {}> {
  constructor() {
    super(createInitialState());
  }
}
