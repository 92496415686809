import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EventoReportCategoria } from '../evento/evento-api.interface';
import {
  Campus,
  CampusDetailed,
  EventoCategoria,
  Instituicao,
  InstituicaoDetailed,
} from './metadata-api.interface';

@Injectable({
  providedIn: 'root',
})
export class MetadataApiService {
  constructor(private http: HttpClient) {}

  getInstituicoes() {
    return this.http.get<Instituicao[]>(`${environment.api}/resources/instituicoes`);
  }

  getInstituicao(instituicao_id: Instituicao['instituicao_id']) {
    return this.http.get<InstituicaoDetailed>(`${environment.api}/resources/instituicoes/${instituicao_id}`);
  }

  getInstituicaoCampi(instituicao_id: Instituicao['instituicao_id']) {
    return this.http.get<Campus[]>(`${environment.api}/resources/instituicoes/${instituicao_id}/campi`);
  }

  getCampus(campus_id: Campus['campus_id']) {
    return this.http.get<CampusDetailed>(`${environment.api}/resources/campi/${campus_id}`);
  }

  getEventosCategorias() {
    return this.http.get<EventoCategoria[]>(`${environment.api}/resources/eventos/categorias`);
  }

  getReportCategorias() {
    return this.http.get<EventoReportCategoria[]>(`${environment.api}/resources/eventos/reports/categorias`);
  }
}
