import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-background-shapes',
  templateUrl: './background-shapes.component.html',
  styleUrls: ['./background-shapes.component.scss'],
})
export class BackgroundShapesComponent implements OnInit {
  ngOnInit() {}
}
