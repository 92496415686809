import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { EventosStore } from '../../../modules/eventos/state/eventos.store';
import { Campus, EventoCategoria, Instituicao } from '../../http/metadata/metadata-api.interface';
import { MetadataApiService } from '../../http/metadata/metadata-api.service';
import { MetadataStore } from './metadata.store';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(
    private metadataApi: MetadataApiService,
    private eventoStore: EventosStore,
    private metadataStore: MetadataStore
  ) {}

  /**
   * Busca as instituições cadastradas no sistema.
   *
   * @returns Observable
   * @memberof MetadataService
   */
  carregarInstituicoes() {
    return this.metadataApi.getInstituicoes();
  }

  carregarInstituicao(idInstituicao: Instituicao['instituicao_id']) {
    return this.metadataApi.getInstituicao(idInstituicao);
  }

  carregarCampi(idInstituicao: Instituicao['instituicao_id']) {
    return this.metadataApi.getInstituicaoCampi(idInstituicao);
  }

  carregarCampus(idCampus: Campus['campus_id']) {
    return this.metadataApi.getCampus(idCampus).pipe(tap((campus) => this.metadataStore.update({ campus })));
  }

  carregarMetadataEventos() {
    return this.metadataApi.getEventosCategorias().pipe(
      map((categorias) => [...categorias, this.getFiltroIncorretos()]),
      tap((categorias) => this.salvarDadosEventos(categorias))
    );
  }

  carregarMetadataReports() {
    return this.metadataApi
      .getReportCategorias()
      .pipe(tap((reportCategorias) => this.eventoStore.update({ reportCategorias })));
  }

  /**
   * Front-end only EventoCategoria to mark "not confirmed" eventos
   * @returns
   */
  getFiltroIncorretos() {
    const filtroIncorretos: EventoCategoria = {
      categoria_id: '-1',
      publico: false,
      grupo: {
        grupo_id: '',
        nome: 'Desconfirmados 👎',
        icone: '',
      },

      tipo: {
        tipo_id: '',
        nome: '',
        icone: '',
      },
    };

    return filtroIncorretos;
  }

  private salvarDadosEventos(categorias: EventoCategoria[]) {
    this.eventoStore.update({ categorias });
  }
}
