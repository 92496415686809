import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PostAuthInstituicaoResponse } from '../../../core/http/auth/auth-api.interface';

export interface AuthState extends EntityState<{}> {
  auth: PostAuthInstituicaoResponse | null;
  usuarioLogado: boolean;
}
export function createInitialState(): AuthState {
  return { auth: null, usuarioLogado: false };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends EntityStore<AuthState, {}> {
  constructor() {
    super(createInitialState());
  }
}
