import { Component, Input, OnInit } from '@angular/core';
import { GetAlunoApiResponse } from '../../../core/http/aluno/aluno-api.interface';

@Component({
  selector: 'app-photo-list',
  templateUrl: './photo-list.component.html',
  styleUrls: ['./photo-list.component.scss'],
})
export class PhotoListComponent implements OnInit {
  @Input() alunos: GetAlunoApiResponse[] = [];
  @Input() length = 4;
  @Input() counter: number | undefined = undefined;
  @Input() type: 'normal' | 'large' = 'normal';
  @Input() loading = false;

  ngOnInit() {}
}
