import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { finalize, switchMap } from 'rxjs/operators';
import { ApiError } from '../../../../core/http/api.interface';
import { PostAuthInstituicaoBody } from '../../../../core/http/auth/auth-api.interface';
import { Campus, Instituicao } from '../../../../core/http/metadata/metadata-api.interface';
import { AlunoService } from '../../../../core/services/aluno/aluno.service';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';
import { DialogService } from '../../../../core/services/dialog/dialog.service';
import { MetadataService } from '../../../../core/services/metadata/metadata.service';
import { StatusBarService } from '../../../../core/services/status-bar/status-bar.service';
import { BasicInputComponent } from '../../../../shared/components/basic-input/basic-input.component';
import { BasicSelectComponent } from '../../../../shared/components/basic-select/basic-select.component';
import { BasicSelectOption } from '../../../../shared/components/basic-select/basic-select.interface';
import { AuthService } from '../../state/auth.service';
import { TermosPage } from '../termos/termos.page';
import { CheckboxState } from '../../../../shared/components/basic-checkbox/basic-checkbox.component';
import { StorageService } from '../../../../core/services/storage/storage.service';
import { LogoutService } from '../../../../core/services/logout/logout-service.service';

interface LoginFormModel extends PostAuthInstituicaoBody {
  terms: boolean;
}

interface LoginFormControls {
  codigo: FormControl;
  password: FormControl;
  campus_id: FormControl;
  terms: FormControl;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  @ViewChild('selectInstuticoes', { static: true })
  selectInstuticoes: BasicSelectComponent = new BasicSelectComponent();

  // Array com instituições cadastradas
  opcoesInstituicoes: BasicSelectOption[] = [];
  instituicaoSelecionada: Instituicao | undefined = undefined;

  // Array com campus da instituicao selecionada
  opcoesCampi: BasicSelectOption[] = [];

  // Form de login
  loginForm = new FormGroup({
    codigo: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    campus_id: new FormControl(null, [Validators.required]),
    terms: new FormControl(false, [Validators.requiredTrue]),
  });
  submetendoForm = false;

  // States
  buscandoInstituicao = false;
  buscandoInstituicoes = false;
  buscandoCampi = false;
  campoRequerido = false;

  private pageTitle = 'Login';
  private pagePath = '/login';

  constructor(
    private metadataService: MetadataService,
    private modalController: ModalController,
    private toastController: ToastController,
    private authService: AuthService,
    private alunoService: AlunoService,
    private router: Router,
    private dialogService: DialogService,
    private analyticsService: AnalyticsService,
    private statusbarService: StatusBarService,
    private storageService: StorageService,
    private logoutService: LogoutService
  ) {}

  get loginFormValue() {
    return this.loginForm.value as LoginFormModel;
  }

  get loginFormControls(): LoginFormControls {
    return (<any>this.loginForm.controls) as LoginFormControls;
  }

  ngOnInit() {
    this.buscaInstituicoes();
  }

  ionViewWillEnter() {
    this.statusbarService.setWhite();
  }

  ionViewDidEnter() {
    this.analyticsService.trackPageView(this.pageTitle, this.pagePath);
  }

  /**
   * Busca pelas instituicoes cadastadas no sistema e gera as opcoes do select
   */
  buscaInstituicoes() {
    this.buscandoInstituicoes = true;
    this.metadataService
      .carregarInstituicoes()
      .pipe(finalize(() => (this.buscandoInstituicoes = false)))
      .subscribe({
        next: (instituicoes) => {
          this.opcoesInstituicoes = instituicoes.map((instituicao) => {
            return { value: instituicao.instituicao_id, text: instituicao.nome };
          });
        },
        error: async () => {
          const toast = await this.mostrarToastRetry('Não foi possível carregar os campi da sua instituição!');
          await toast.onDidDismiss();
          this.buscaInstituicoes();
        },
      });
  }

  /**
   * Busca pela instituicao selecionada pelo usuário
   *
   * @param {BasicSelectOption} opInstituicao
   */
  buscaInstituicao(opInstituicao: BasicSelectOption) {
    this.buscandoInstituicao = true;
    // Reseta as opcoes do campus
    this.opcoesCampi = [];
    this.loginFormControls.campus_id.setValue(null);

    this.metadataService
      .carregarInstituicao(opInstituicao.value)
      .pipe(finalize(() => (this.buscandoInstituicao = false)))
      .subscribe({
        next: (instituicao) => {
          this.setInstituicao(instituicao);
          this.buscaCampi(instituicao.instituicao_id);
        },
        error: async () => {
          if (this.selectInstuticoes) {
            this.selectInstuticoes.unselectAll();
          }

          const toast = await this.mostrarToastRetry('Não foi possível carregar os campi da sua instituição!');
          await toast.onDidDismiss();
          this.buscaInstituicao(opInstituicao);
        },
      });
  }

  /**
   * Verifica a instituicao requer que o usuário selecione um campus
   *
   * @param {Instituicao['instituicao_id']} idInstituicao
   */
  buscaCampi(idInstituicao: Instituicao['instituicao_id']) {
    this.buscandoCampi = true;
    this.metadataService
      .carregarCampi(idInstituicao)
      .pipe(finalize(() => (this.buscandoCampi = false)))
      .subscribe({
        next: (campi) => {
          this.opcoesCampi = this.gerarSelectCampi(campi);
          this.campoRequerido = this.opcoesCampi && this.opcoesCampi.length > 1;

          if (this.opcoesCampi.length === 1) {
            this.setCampus(this.opcoesCampi[0]);
          }
        },
        error: async () => {
          if (this.selectInstuticoes) {
            this.selectInstuticoes.unselectAll();
          }

          const toast = await this.mostrarToastRetry('Não foi possível carregar os campi da sua instituição!');
          await toast.onDidDismiss();
          this.buscaCampi(idInstituicao);
        },
      });
  }

  /**
   * Make the login request and, upon success,
   * makes the request to retrieve the user's info.
   * Clear user's credentials if any of the requests fails.
   */
  fazerLogin(): void {
    this.analyticsService.trackEvent('fazer-login', 'login');
    if (this.loginForm.invalid || this.submetendoForm) {
      return;
    }

    this.logoutService.resetStores();
    this.storageService.clearAll();

    this.submetendoForm = true;

    const body: PostAuthInstituicaoBody = {
      codigo: this.loginFormValue.codigo,
      password: this.loginFormValue.password,
      campus_id: this.loginFormValue.campus_id,
    };

    this.authService
      .authenticate(body)
      .pipe(
        switchMap(() => this.alunoService.carregarDadosAluno()),
        finalize(() => (this.submetendoForm = false))
      )
      .subscribe({
        next: () => {
          this.authService.saveAuthentication();
          this.router.navigate(['/app/dashboard'], { replaceUrl: true });
        },
        error: (error: ApiError) => {
          this.dialogService.mostrarErro(error.mensagem);
          this.authService.removeAuthentication();
        },
      });
  }

  // Setters pro form de login
  setUsername(username: string) {
    if (typeof username === 'string') {
      this.loginFormControls.codigo.setValue(username);
    }
  }

  setPassword(pass: string) {
    if (typeof pass === 'string') {
      this.loginFormControls.password.setValue(pass);
    }
  }

  setTerms(event?: CheckboxState) {
    const value = event === 'active' ?? !this.loginFormControls.terms.value;
    this.loginFormControls.terms.setValue(value);
    this.analyticsService.trackEvent('checar-termos', 'login-page');
  }

  setCampus(option: BasicSelectOption) {
    option.selected = true;
    const campusId = option.value;
    this.loginFormControls.campus_id.setValue(campusId);

    this.buscandoCampi = true;

    this.metadataService
      .carregarCampus(campusId)
      .pipe(finalize(() => (this.buscandoCampi = false)))
      .subscribe();
  }

  setInstituicao(instituicao: Instituicao) {
    // Reseta as opcoes do campus
    this.opcoesCampi = [];
    this.loginFormControls.campus_id.setValue(null);

    // Seleciona a instituicao
    this.instituicaoSelecionada = instituicao;
  }

  async abrirTermos() {
    this.analyticsService.trackEvent('ver-termos-de-servico', 'login');
    const modal = await this.modalController.create({
      component: TermosPage,
    });

    modal.present();
  }

  focusOn(input: BasicInputComponent) {
    input?.setFocus();
  }

  // Helpers

  private gerarSelectCampi(campi: Campus[]): BasicSelectOption[] {
    const options = campi.map((campus) => {
      const option: BasicSelectOption = { value: campus.campus_id, text: campus.nome };
      return option;
    });
    return options;
  }

  private async mostrarToastRetry(
    message: string,
    button = true,
    label = 'Recarregar'
  ): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      message,
      duration: button ? undefined : 6000,
      position: 'bottom',
      buttons: [
        {
          text: label,
          role: 'cancel',
        },
      ],
    });

    toast.present();

    return toast;
  }
}
