import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-basic-input',
  templateUrl: './basic-input.component.html',
  styleUrls: ['./basic-input.component.scss'],
})
export class BasicInputComponent implements OnInit {
  @ViewChild('ionInput', { static: true }) ionInput: IonInput | undefined = undefined;

  @Input() label?: string;
  @Input() placeholder: string | undefined = undefined;
  @Input() type: 'text' | 'password' | 'number' = 'text';
  @Input() icon: string | undefined = undefined;
  @Input() control: FormControl | undefined = undefined;
  @Input() disabled = false;
  @Input() opcional = false;

  @Output() inputChange = new EventEmitter();
  @Output() submit = new EventEmitter();

  focused = false;
  inputValue: string | number | undefined = undefined;
  viewPassword = false;
  internalType!: 'text' | 'password' | 'number';

  constructor(private analyticsService: AnalyticsService, private elementRef: ElementRef) {}

  ngOnInit() {
    this.internalType = this.type;
  }

  emitInput(event: Event) {
    const customEvent = event as CustomEvent;
    if (customEvent && customEvent.detail && typeof customEvent.detail.value === 'string') {
      this.inputValue = customEvent.detail.value;
      this.inputChange.emit(this.inputValue);
    }
  }

  emitSubmit() {
    this.submit.emit(this.inputValue);
  }

  setFocus() {
    if (this.ionInput) {
      this.ionInput.setFocus();
    }
  }

  onFocus() {
    this.focused = true;

    this.elementRef.nativeElement.parentElement.scrollTop = 0;
  }

  onBlur() {
    this.focused = false;

    this.elementRef.nativeElement.parentElement.scrollTop = 0;
  }

  togglePassword() {
    this.analyticsService.trackEvent('toggle-password-view', 'login');
    this.internalType = this.internalType === 'password' ? 'text' : 'password';
  }

  setInputValue(value: string | number) {
    this.inputValue = String(value);
  }
}
