export default class SortUtilities {
  static sortByTime(a: string, b: string) {
    // tslint:disable-next-line:radix
    if (parseInt(a.split(':')[0]) - parseInt(b.split(':')[0]) === 0) {
      // tslint:disable-next-line:radix
      return parseInt(a.split(':')[1]) - parseInt(b.split(':')[1]);
    }

    // tslint:disable-next-line:radix
    return parseInt(a.split(':')[0]) - parseInt(b.split(':')[0]);
  }
}
