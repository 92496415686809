import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { PostAuthInstituicaoResponse } from '../../../core/http/auth/auth-api.interface';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends QueryEntity<AuthState, {}> {
  auth$ = this.select((state) => state);

  // Usado para cachear a autenticação para usar nas rotas logo após o login
  temporaryAuth: PostAuthInstituicaoResponse | undefined = undefined;

  constructor(protected store: AuthStore) {
    super(store);
  }

  setTemporaryAuth(auth: PostAuthInstituicaoResponse) {
    this.temporaryAuth = auth;
  }
}
