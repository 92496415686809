<div class="c-modal u-flex-justify--between">
  <section class="u-display--flex u-flex-align--center u-flex-direction--column u-margin-bottom--lg">
    <ion-icon name="close" class="c-modal__close" (click)="dismiss()"></ion-icon>
    <img src="assets/img/illustrations/celebration.svg" class="c-modal__img u-margin-bottom" />
    <h1 class="c-modal__title">Você é top demais!</h1>
    <p class="c-modal__description">
      Valeuzão pela força! Isso nos ajuda demais, agora é só encaminhar o cardápio pra gente por e-mail, pode ser
      uma lista, um print, uma foto, do jeito que você conseguir!
    </p>
  </section>

  <section class="u-display--flex u-flex-justify--center u-flex-direction--row u-margin-bottom--lg">
    <ion-icon
      [class.animate]="emailCopied"
      [name]="emailCopied ? 'checkmark-outline' : 'copy'"
      class="c-icon c-icon--large u-margin-right--xs"></ion-icon>
    <span
      class="u-margin-left--xxs u-font-size--md u-pink u-font-weight--normal u-text-decoration--underline"
      (click)="copyEmail()">
      {{ cardapioEmail }}
    </span>
  </section>

  <section class="u-display--flex u-flex-align--center u-flex-direction--column u-width--100">
    <app-button [main]="true" label="BORA!" type="reverse" (click)="enviarEmail()"></app-button>
  </section>
</div>
