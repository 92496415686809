import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type CheckboxState = 'active' | 'inactive' | 'indeterminate';
@Component({
  selector: 'app-basic-checkbox',
  templateUrl: './basic-checkbox.component.html',
  styleUrls: ['./basic-checkbox.component.scss'],
})
export class BasicCheckboxComponent implements OnInit {
  @Input()
  label: string | undefined = undefined;
  @Input()
  icon: string | undefined = undefined;
  @Input()
  checked = false;
  @Input()
  indeterminate = false;
  @Input()
  disabled = false;
  @Input()
  reverse = false;
  @Input()
  spaced = false;
  @Input()
  border = false;
  @Input()
  styled: 'normal' | 'reverse' | 'light' = 'normal';

  @Output()
  change = new EventEmitter<CheckboxState>();

  ngOnInit() {}

  emitInput(event: Event): void {
    if (!(event as CustomEvent).detail.checked) {
      this.change.emit('inactive');
      return;
    }

    if (this.indeterminate) {
      this.change.emit('indeterminate');
      return;
    }

    this.change.emit('active');
  }
}
