import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { HistoricoItem } from '../../../core/http/aluno/aluno-api.interface';
import { GradeHorariaAula, GradeHorariaDia } from '../../../core/services/aluno/aluno.interface';
import { GradeState, GradeStore } from './grade.store';

// tslint:disable-next-line:import-name
import SortUtilities from '../../../shared/utilities/sort.utilities';

@Injectable({ providedIn: 'root' })
export class GradeQuery extends QueryEntity<GradeState, HistoricoItem> {
  historicoAtual$ = this.selectAll();

  gradeHoje$ = this.selectAll().pipe(map((historicos) => this.getAulasHoje(historicos)));

  gradeSemana$ = this.selectAll().pipe(map((historicos) => this.separarDisciplinasPorDia(historicos)));

  constructor(protected store: GradeStore) {
    super(store);
  }

  gradeSemana() {
    const entities = this.getAll();

    return this.separarDisciplinasPorDia(entities);
  }

  turmaHistorico(historico_id: number) {
    return this.selectEntity(historico_id);
  }

  separarDisciplinasPorDia(historico: HistoricoItem[]) {
    let gradeHoraria = this.getGradeHorariaVazia();

    for (const itemHistorico of historico) {
      const horariosDisciplina = itemHistorico.turma.horarios;
      if (!horariosDisciplina) {
        continue;
      }

      // Itera entre todos os horarios da disciplina e popula o objeto da grade
      // dependendo do dia da semana
      for (const horario of horariosDisciplina) {
        const diaDaGrade = gradeHoraria[horario.dia_da_semana];
        const horarioHistorico = { ...itemHistorico, horario };
        const ultimaAulaAdicionada = diaDaGrade.aulas.slice(-1)[0];
        if (
          ultimaAulaAdicionada?.turma.turma_id === itemHistorico.turma.turma_id &&
          ultimaAulaAdicionada?.horario.dia_da_semana === horario.dia_da_semana &&
          ultimaAulaAdicionada?.horario.hora_termino === horario.hora_inicio &&
          ultimaAulaAdicionada?.horario.sala === horario.sala
        ) {
          // Merge de mesmas aulas que terminam e começam no mesmo horario
          const ultimoHorario = Object.assign({}, ultimaAulaAdicionada.horario);
          ultimoHorario.hora_termino = horario.hora_termino;
          ultimaAulaAdicionada.horario = ultimoHorario;
        } else {
          diaDaGrade.aulas = this.addHistoricoHorarioAoDia(horarioHistorico, diaDaGrade);
        }
      }
    }

    // Remove domingo da grade horaria
    gradeHoraria = gradeHoraria.filter((dia) => dia.label !== 'Domingo');

    // Ordena as aulas dependendo do horario de inicio
    for (const dia of gradeHoraria) {
      dia.aulas.sort((a, b) => SortUtilities.sortByTime(a.horario.hora_inicio, b.horario.hora_inicio));
    }

    return gradeHoraria;
  }

  gradeHojeCardapios() {
    return this.gradeHoje$;
  }

  private getAulasHoje(historicos: HistoricoItem[]) {
    const disciplinasPorDia = this.separarDisciplinasPorDia(historicos);

    const diaDaSemana = new Date().getDay();
    const disciplinasHoje = disciplinasPorDia.find((historico) => {
      return historico.dia_da_semana === diaDaSemana;
    });

    if (disciplinasHoje) {
      return disciplinasHoje.aulas;
    }

    return [];
  }

  private getGradeHorariaVazia() {
    const diasDaSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

    const gradeHoraria: GradeHorariaDia[] = diasDaSemana.map((dia, index) => {
      return {
        label: dia,
        dia_da_semana: index,
        aulas: [],
      };
    });

    return gradeHoraria;
  }

  private addHistoricoHorarioAoDia(historico: GradeHorariaAula, grade: GradeHorariaDia) {
    const historicos = [...grade.aulas, historico];
    return historicos;
  }
}
