import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { GradeGridState, GradeGridStore } from './grade-grid.store';
import { MetadataQuery } from '../../../core/services/metadata/metadata.query';
import { combineLatest, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GradeGridQuery extends Query<GradeGridState> {
  isGradeTurno$ = this.select((state) => {
    return state.gradeHoraria?.length > 0;
  });
  isGradeHoraria$ = combineLatest([
    this.isGradeTurno$,
    this.metadataQuery.isFuncionalidadeActive$('grade-horaria'),
  ]).pipe(map(([isGradeTurno, isActive]) => isGradeTurno && isActive));

  constructor(protected store: GradeGridStore, private metadataQuery: MetadataQuery) {
    super(store);
  }

  getGradeGrid() {
    return this.getValue().gradeHoraria;
  }

  showTooltip() {
    return this.getValue().showTooltip;
  }

  /**
   * Checks the metadata store to see if feature is active on the User's campus
   * @returns the feature status
   */
  isFuncionalidadeActive(): boolean {
    return this.metadataQuery.isFuncionalidadeActive('grade-horaria');
  }

  isGradeTurno(): boolean {
    const gradeHoraria = this.getGradeGrid();
    return gradeHoraria?.length > 0;
  }
}
