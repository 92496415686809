import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { format } from 'date-fns';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-validade',
  templateUrl: './validade.page.html',
  styleUrls: ['./validade.page.scss'],
})
export class ValidadePage implements OnInit {
  public date: string | undefined = undefined;
  public hour: string | undefined = undefined;

  constructor(private modalController: ModalController, private analyticsService: AnalyticsService) {
    const now = new Date();
    this.date = format(now, 'dd/MM/y');
    this.hour = format(now, 'HH:mm');
  }

  ngOnInit() {}

  public async dismiss() {
    return this.modalController.dismiss();
  }
}
