import { Injectable } from '@angular/core';
import { QueryConfig, QueryEntity } from '@datorama/akita';
import {
  addMonths,
  compareAsc,
  eachMonthOfInterval,
  format,
  isSameMonth,
  isSameWeek,
  isToday,
  parse,
  startOfMonth,
} from 'date-fns';
import { getMonth, isAfter, isBefore, parseISO, startOfDay } from 'date-fns/esm';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasicEvento, ExtendedEvento } from '../../../core/http/evento/evento-api.interface';
import { EventoCategoria } from '../../../core/http/metadata/metadata-api.interface';
import DateUtilities from '../../../shared/utilities/date.utilities';
import { AlunoQuery } from '../../aluno/state/aluno.query';
import { CategoriasPorGrupo, EventoData, EventoGrupo } from './evento.interface';
import { EventosState, EventosStore } from './eventos.store';

// sort eventos pela data de inicio
const sortBy = (a: ExtendedEvento, b: ExtendedEvento) => compareAsc(parseISO(a.inicio), parseISO(b.inicio));

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy })
export class EventosQuery extends QueryEntity<EventosState, ExtendedEvento> {
  eventos$ = this.selectAll();

  categorias$ = this.select((state) => state.categorias || []);
  reportCategorias$ = this.select((state) => state.reportCategorias || []);
  eventosPorDia$ = this.selectAll().pipe(map((eventos) => this.separarPorDia(eventos)));
  badge$ = this.select((state) => state.idCategoriasExcluidas.length > 0);

  eventosHoje$ = this.selectAll().pipe(
    map((eventos) => {
      const eventosHoje = eventos.filter(
        (ev) => isToday(parseISO(ev.inicio)) && !this.eventoDesconfirmado(ev) && !ev.categoria.publico
      );
      return eventosHoje;
    })
  );

  constructor(protected store: EventosStore, private alunoQuery: AlunoQuery) {
    super(store);
  }

  /**
   * Returns all Categorias that have publico set to true.
   * In other words: categorias that users can create events to.
   * @returns categorias
   */
  publicCategorias() {
    return this.select((state) => state.categorias).pipe(
      map((categorias) => categorias.filter((categoria) => categoria.publico))
    );
  }

  eventos(): ExtendedEvento[] {
    return this.getAll();
  }

  eventosNotificaveis(): ExtendedEvento[] {
    return this.getAll({
      filterBy: (ev) => {
        const interacaoUser = ev.interacoes.find((interacao) => interacao.acao === 'ratificar');
        const reportado = interacaoUser && !interacaoUser.confirmado;

        const notificavel = !this.eventoNaoNotificavel(ev.evento_id);

        return !reportado && notificavel;
      },
    });
  }

  eventoPorId(eventoId: BasicEvento['evento_id']): Observable<ExtendedEvento | undefined> {
    return this.selectEntity(eventoId);
  }

  eventoNaoNotificavel(eventoId: BasicEvento['evento_id']): boolean {
    if (this.getValue().idEventosNaoNotificaveis) {
      return this.getValue().idEventosNaoNotificaveis.includes(eventoId);
    }

    return false;
  }

  eventosNaoNotificaveis(): BasicEvento['evento_id'][] {
    return this.getValue().idEventosNaoNotificaveis || [];
  }

  categoriasExcluidas(): BasicEvento['evento_id'][] {
    return this.getValue().idCategoriasExcluidas;
  }

  gruposCategorias(): Observable<CategoriasPorGrupo[]> {
    return this.select((state) => {
      const categorias = state.categorias;
      const gruposCategoria: CategoriasPorGrupo[] = categorias
        .filter((c) => c.categoria_id !== '-1')
        .reduce((arr: CategoriasPorGrupo[], categoria: EventoCategoria) => {
          const mappedGroup = arr.find(
            (categoriaGrupo: CategoriasPorGrupo) => categoriaGrupo.grupo.grupo_id === categoria.grupo.grupo_id
          );

          const mostrarCategoria = !this.categoriasExcluidas().includes(categoria.categoria_id);
          const newCategoria = { categoria, indeterminate: false, mostrarCategoria };
          if (mappedGroup) {
            mappedGroup.categorias.push(newCategoria);
            return arr;
          }

          return [
            ...arr,
            {
              grupo: categoria.grupo,
              indeterminate: false,
              mostrarGrupo: true,
              categorias: [newCategoria],
            },
          ];
        }, []);

      gruposCategoria.forEach((grupo) => {
        const showCategoria = grupo.categorias.filter((c) => c.mostrarCategoria).length;
        if (showCategoria === 0) {
          grupo.mostrarGrupo = false;
          grupo.indeterminate = false;
        } else if (showCategoria === grupo.categorias.length) {
          grupo.mostrarGrupo = true;
          grupo.indeterminate = false;
        } else {
          grupo.indeterminate = true;
        }
      });

      return gruposCategoria;
    });
  }

  eventosPorCategoria(): Observable<EventoGrupo[]> {
    return this.eventoFeed().pipe(
      map((eventos) => {
        const start = new Date();
        const curriculares: EventoGrupo = { label: 'Curriculares', eventos: [] };
        const culturais: EventoGrupo = { label: 'Culturais', eventos: [] };
        const calendarioOficial: EventoGrupo = { label: 'Calendário oficial', eventos: [] };
        const externos: EventoGrupo = { label: 'Eventos externos', eventos: [] };
        const restantes: EventoGrupo[] = [];
        const eventosIniciados: EventoGrupo = { label: 'Eventos iniciados', eventos: [] };

        for (const evento of eventos) {
          const eventoGrupo: string = evento.categoria.grupo.nome;

          const eventoInicio = new Date(evento.inicio);
          if (isBefore(eventoInicio, start)) {
            eventosIniciados.eventos.unshift(evento);
          } else {
            switch (eventoGrupo) {
              case 'Curriculares':
                curriculares.eventos.push(evento);
                break;
              case 'Culturais':
                culturais.eventos.push(evento);
                break;
              case 'Calendário oficial':
                calendarioOficial.eventos.push(evento);
                break;
              case 'Eventos externos':
                externos.eventos.push(evento);
                break;
              default:
                this.addEventoOrCreateCategoria(evento, restantes);
                break;
            }
          }
        }

        return [curriculares, culturais, calendarioOficial, externos, ...restantes, eventosIniciados];
      })
    );
  }

  /**
   * Given a list of categorias for eventos, adds the evento to the correct categoria or creates a new categoria.
   *
   *  @param evento ExtendedEvento
   *  @param categorias EventoGrupo[]
   *
   *  @returns void
   */
  private addEventoOrCreateCategoria(evento: ExtendedEvento, categorias: EventoGrupo[]): void {
    const categoria = categorias.find((ev) => {
      return ev.label === evento.categoria.grupo.nome;
    });

    if (categoria) {
      categoria.eventos.push(evento);
    } else {
      categorias.push({
        grupo_id: evento.categoria.grupo.grupo_id,
        label: evento.categoria.grupo.nome,
        tipo: evento.categoria.tipo,
        eventos: [evento],
      });
    }
  }

  eventosPorIntervalo(): Observable<EventoGrupo[]> {
    return this.eventoFeed().pipe(
      map((eventos) => {
        const start = new Date();
        const hoje: EventoGrupo = { label: 'Hoje', eventos: [] };
        const estaSemana: EventoGrupo = { label: 'Nesta semana', eventos: [] };
        const esteMes: EventoGrupo = { label: 'Este mês', eventos: [] };
        const mesesRestantes: EventoGrupo[] = [];
        const eventosIniciados: EventoGrupo = { label: 'Eventos iniciados', eventos: [] };

        const end = addMonths(start, 3);
        // TODO: Reabilitar quando tivermos semestre
        // const aluno = this.alunoQuery.getValue().aluno;
        // const end =
        //   aluno && aluno.campus && aluno.campus.semestres.length
        //     ? new Date(aluno.campus.semestres[0].termino)
        //     : start;

        const meses = eachMonthOfInterval({
          start,
          end,
        }).filter((month) => !isSameMonth(month, startOfMonth(start)));
        for (const mes of meses) {
          mesesRestantes.push({
            inicio: startOfMonth(mes),
            label: DateUtilities.mesesCompletos[getMonth(mes)],
            eventos: [],
          });
        }

        for (const evento of eventos) {
          const eventoInicio = new Date(evento.inicio);
          if (isBefore(eventoInicio, start)) {
            eventosIniciados.eventos.unshift(evento);
          } else if (isToday(eventoInicio)) {
            hoje.eventos.push(evento);
          } else if (isSameWeek(eventoInicio, start)) {
            estaSemana.eventos.push(evento);
          } else if (isSameMonth(eventoInicio, start)) {
            esteMes.eventos.push(evento);
          } else if (isBefore(eventoInicio, end)) {
            mesesRestantes.find((m) => !!m.inicio && isSameMonth(m.inicio, eventoInicio))?.eventos.push(evento);
          }
        }

        return [hoje, estaSemana, esteMes, ...mesesRestantes, eventosIniciados];
      })
    );
  }

  eventosPorConfirmacao(): Observable<EventoGrupo[]> {
    return this.eventoFeed().pipe(
      map((eventos) => {
        const start = new Date();
        const eventosConfirmados: EventoGrupo = {
          eventos: [],
          label: 'Confirmados',
        };
        const eventosPendentes: EventoGrupo = {
          eventos: [],
          label: 'Pendentes',
        };
        const eventosDesconfirmados: EventoGrupo = {
          eventos: [],
          label: 'Desconfirmados',
        };
        const eventosIniciados: EventoGrupo = {
          eventos: [],
          label: 'Eventos iniciados',
        };

        eventos.forEach((evento) => {
          if (this.eventoDesconfirmado(evento)) {
            eventosDesconfirmados.eventos.push(evento);
            return;
          }

          const eventoInicio = new Date(evento.inicio);
          if (isBefore(eventoInicio, start)) {
            eventosIniciados.eventos.unshift(evento);
            return;
          }

          if (this.eventoConfirmado(evento)) {
            eventosConfirmados.eventos.push(evento);
            return;
          }

          eventosPendentes.eventos.push(evento);
        });

        return [eventosConfirmados, eventosPendentes, eventosDesconfirmados, eventosIniciados];
      })
    );
  }

  mostrarDesconfirmados(): boolean {
    return this.getValue().mostrarDesconfirmados;
  }

  /**
   * Return true if the evento follows one of the following conditions:
   *  - The evento allows interactions and:
   *    - The evento allows ratification and the user has negatively ratified the evento
   *    - The evento does not accept more subscriptions and the user is not subscribed
   *    - The evento has no more vacancies and the user is not subscribed
   *
   * @param evento ExtendedEvento
   * @returns boolean
   */
  eventoDesconfirmado(evento: ExtendedEvento): boolean {
    if (!evento) {
      return false;
    }

    if (evento.permite_interacao === false) {
      return false;
    }

    if (evento.permite_ratificacao === true) {
      const ratificacaoUser = evento.interacoes.find(
        (interacao) => interacao.acao === 'ratificar' && interacao.aluno_id === this.alunoQuery.aluno()?.aluno_id
      );
      if (!!ratificacaoUser && !ratificacaoUser.confirmado) {
        return true;
      }
    }

    const inscricaoUser = evento.interacoes.find(
      (interacao) => interacao.acao === 'inscrever' && interacao.aluno_id === this.alunoQuery.aluno()?.aluno_id
    );
    if (
      evento.vagas_restantes === 0 ||
      (evento.inscricao_termino && isAfter(new Date(), new Date(evento.inscricao_termino)))
    ) {
      return !inscricaoUser;
    }

    return false;
  }

  /**
   * Return true if the evento follows one of the following conditions:
   *  - The evento does not allow interaction
   *  - The evento allows ratification and the user has positively ratified the evento
   *  - The user has subscribed to the event and their subscription is confirmed
   *
   * @param evento ExtendedEvento
   * @returns boolean
   */
  eventoConfirmado(evento: ExtendedEvento): boolean {
    if (!evento) {
      return false;
    }

    if (evento.permite_interacao === false) {
      return true;
    }

    const aluno = this.alunoQuery.aluno();

    if (evento.permite_ratificacao === true) {
      const ratificacaoUser = evento?.interacoes.find(
        (interacao) => interacao.acao === 'ratificar' && interacao.aluno_id === aluno?.aluno_id
      );
      return !!ratificacaoUser?.confirmado;
    }

    const inscricaoUser = evento?.interacoes.find(
      (interacao) => interacao.acao === 'inscrever' && interacao.aluno_id === aluno?.aluno_id
    );
    if (!!inscricaoUser?.confirmado) {
      return true;
    }

    return false;
  }

  mostrarOcultados(): boolean {
    return this.getValue().mostrarOcultados;
  }

  eventoOcultado(evento: ExtendedEvento): boolean {
    if (!evento) {
      return true;
    }

    const interacaoUser = evento.interacoes.find((interacao) => interacao.acao === 'ocultar');
    return !!interacaoUser;
  }

  /**
   * Given a Categoria ID, return its name
   * @param idCategoria
   * @returns
   */
  nomeCategoria(idCategoria: EventoCategoria['categoria_id']) {
    const categorias = this.getValue().categorias;

    if (categorias) {
      const categoriaSelecionada = categorias.find((categoria) => categoria.categoria_id === idCategoria);

      return categoriaSelecionada ? categoriaSelecionada.grupo.nome : '';
    }

    return '';
  }

  mostrarOnboarding(): boolean {
    return this.getValue().mostrarOnboarding;
  }

  /**
   * Return the base feed of eventos to be shown on Calendar
   * This includes:
   * - Eventos ending in the future
   * - That are not included in a categoriaExcluida
   * - Is not "desconfirmado" (or if the user has set "desconfirmados" to be shown)
   * - Is not set to be hidden by the user
   */
  private eventoFeed(): Observable<ExtendedEvento[]> {
    return this.eventoFuturo().pipe(
      map((eventos) =>
        eventos
          .filter((evento) => !this.categoriasExcluidas().includes(evento.categoria.categoria_id))
          .filter((evento) => this.getValue().mostrarOcultados || !this.eventoOcultado(evento))
          .filter((evento) => this.getValue().mostrarDesconfirmados || !this.eventoDesconfirmado(evento))
      )
    );
  }

  /**
   * Return all eventos that end in the future
   * @returns an array of eventos
   */
  private eventoFuturo(): Observable<ExtendedEvento[]> {
    const hoje = startOfDay(new Date());
    return this.selectAll({
      filterBy: (entity) => {
        const after = isAfter(new Date(entity.termino), hoje);
        return after;
      },
    });
  }

  private separarPorDia(eventos: ExtendedEvento[]): EventoData[] {
    const eventosPorDia: { [dia: string]: ExtendedEvento[] } = {};

    // Sort dos eventos por dia
    for (const evento of eventos) {
      const stringDia = format(parseISO(evento.inicio), 'yyyy-MM-dd');

      if (eventosPorDia[stringDia] === undefined) {
        eventosPorDia[stringDia] = [evento];
      } else {
        eventosPorDia[stringDia].push(evento);
      }
    }

    // Cria o objeto pra cada dia que tenha eventos
    const outputPorDia = [];
    for (const dateKey of Object.keys(eventosPorDia)) {
      if (eventosPorDia[dateKey]) {
        const dateEvento = parse(dateKey, 'yyyy-MM-dd', new Date(dateKey));
        const eventosDia = eventosPorDia[dateKey];

        const diaEvento: EventoData = {
          eventos: eventosDia,
          data: dateEvento.toISOString(),
          dataFormatada: format(dateEvento, 'dd/MM, dddd'),
        };

        outputPorDia.push(diaEvento);
      }
    }
    return outputPorDia;
  }
}
